/**
 *
 * Ripspot Modal
 *
 *
 */

import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { closeRipspotModal } from 'actions/modals';

import Modal from 'components/modals/index';
import RipspotCreation from 'containers/RipspotCreation';
import { steps, editSteps } from 'containers/RipspotCreation/steps';
import AnimatedTitle from 'components/AnimatedTitle';

import {
  REVIEW,
  PHOTO_GALLERY,
  DETAILS,
  // BIO,
  PAYMENT,
  SHARE,
} from 'utils/constants';

const RipspotModal = ({ isOpen, dispatch, metadata, ripspots }) => {
  // console.log('\n\n\n');
  // console.log('-----------------');
  // console.log({ steps });
  // console.log({ editSteps });
  // console.log('-----------------');
  // console.log('\n\n\n');
  const [shouldClearTempData, setShouldClearTempData] = useState(false);
  const [currentStep, setParentCurrentStep] = useState(
    (metadata && metadata.isInExistingPinEditMode) ||
      (ripspots && ripspots.isInExistingPinEditMode)
      ? 4
      : 1,
  );
  const [previousStep, setParentPreviousStep] = useState(1);
  const [stepsToUse, setParentStepsToUse] = useState(
    (metadata && metadata.isInNewRipspotMode) ||
      (ripspots && ripspots.isInNewRipspotMode)
      ? steps
      : editSteps,
  );

  const onRequestClose = () => {
    dispatch(closeRipspotModal());
    if (currentStep === 6) {
      // reset currentStep
      setParentCurrentStep(1);
      setShouldClearTempData(true);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      // clear this prop so next time we create a ripspot the tempData is not cleared
      setShouldClearTempData(false);
    }
  }, [isOpen]);

  // console.log('\n\n\n');
  // console.log('-----------------');
  // console.log('RipspotModal');
  // console.log({ isOpen });
  // console.log({ previousStep });
  // console.log({ currentStep });
  // console.log({ shouldClearTempData });
  // console.log('-----------------');
  // console.log('\n\n\n');

  const stepObjKey = stepsToUse[currentStep].key;
  const isReview = stepObjKey === REVIEW;
  const isPhotoGallery = stepObjKey === PHOTO_GALLERY;
  const isShare = stepObjKey === SHARE;
  const isPayment = stepObjKey === PAYMENT;

  return (
    <Modal
      noMinMax
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      // width="calc(100vw - 30px)"
      // height="calc(100vh - 40px)"
      width={
        isShare
          ? 'calc(95vw - 60px)'
          : isReview || isPhotoGallery
          ? 'calc(100vw - 30px)'
          : 'calc(70vw - 60px)'
      }
      height={
        isShare
          ? 'calc(95vh - 80px)'
          : isReview || isPhotoGallery
          ? 'calc(95vh - 40px)'
          : 'calc(70vh - 80px)'
      }
      renderTitle={() => (
        <AnimatedTitle
          // animateUpDown
          includeStep={false}
          step={currentStep}
          prevStep={previousStep}
          steps={stepsToUse}
          isReview={isReview}
          isPayment={isPayment}
        />
      )}
    >
      <Container>
        <RipspotCreation
          shouldClearTempData={shouldClearTempData}
          initCurrentStep={currentStep}
          metadata={metadata}
          setParentCurrentStep={setParentCurrentStep}
          setParentPreviousStep={setParentPreviousStep}
          setParentStepsToUse={setParentStepsToUse}
        />
      </Container>
    </Modal>
  );
};

RipspotModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  metadata: PropTypes.object,
  ripspots: PropTypes.object,
};

const mapStateToProps = ({ display, modals, location, ripspots }) => ({
  display,
  location,
  isOpen: modals.ripspotModal.isOpen,
  metadata: modals.ripspotModal.metadata,
  ripspots,
});

const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(RipspotModal);

const Container = styled.div`
  height: 100%;
`;
