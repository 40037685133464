/**
 *
 * Review Page
 *
 *
 */

import React, { memo } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import SEO from 'components/seo';
import ReviewRipspot from 'components/ReviewRipspot';

import theme from 'utils/theme';

const ReviewPage = ({ location }) => (
  <ThemeProvider theme={theme}>
    <Container>
      <SEO title="Explore Map" />
      <ReviewRipspot />
    </Container>
  </ThemeProvider>
);

ReviewPage.propTypes = {
  location: PropTypes.any,
};

export default memo(ReviewPage);

const Container = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
