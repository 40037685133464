/**
 *
 * Marker
 *
 *
 */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Layer, Feature, Popup } from 'react-mapbox-gl';
// import styled from 'styled-components';
import uuid from 'uuid/v4';

import styled from 'styled-components';

import CustomPopup from './CustomPopup';

export const CustomMarker = ({
  ripspot,
  pinName,
  coords,
  isTouchDevice,
  isHoverDevice,
  handleOnClick,
}) => {
  const [id] = useState(uuid());
  const [isOpen, setIsOpen] = useState(false);
  const [isHoverFromMarker, setIsHoverFromMarker] = useState(false);
  const toggle = () => {
    // eslint-disable-next-line no-console
    console.log('Popup Toggle Clicked!');
    setIsOpen(!isOpen);
  };

  const handleHoverOff = (event) => {
    console.log(event);
  };
  return (
    <>
      <Layer
        type="symbol"
        id={`position-marker_${id}`}
        // className="position-marker"
        // paint={{
        //   'icon-opacity': disabled ? 0.45 : 1,
        // }}

        layout={{
          'icon-image': pinName,
          'icon-size': 0.75,
          'icon-offset': [0, -45],
          // 'icon-offset': [0, -25], // original
        }}
        // layout={{ 'icon-image': 'ripspotpin-xs', 'icon-size': 0.75 }} // marker-15
      >
        <Feature
          coordinates={coords}
          onClick={isTouchDevice ? toggle : handleOnClick}
          onMouseEnter={
            isHoverDevice &&
            (mapObject => {
              // eslint-disable-next-line no-console
              // console.log('\n\nFeature onMouseEnter, setting isOpen = true');
              // console.log({ mapObject });
              setIsOpen(true);
            })
          }
          onMouseLeave={
            isHoverDevice &&
            (mapObject => {
              // eslint-disable-next-line no-console
              // console.log('Feature onMouseLeave, no-op!');
              setTimeout(function() {
                if (!isHoverFromMarker) {
                  //setIsOpen(false);
                }
              }, 500);
            })
          }
        />
      </Layer>
      {isOpen && (
        <>
          <Popup
            coordinates={coords}
            offset={{
              'bottom-left': [12, -38],
              bottom: [0, -38],
              'bottom-right': [-12, -38],
            }}
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log('\n\nPopup onClick Custom Marker');
            }}
            onMouseEnter={
              isHoverDevice &&
              (() => {
                // eslint-disable-next-line no-console
                // console.log('\n\nCustomPopup Container onMouseOver');
                setIsHoverFromMarker(true);
              })
            }
            onMouseLeave={
              isHoverDevice &&
              (() => {
                // eslint-disable-next-line no-console
                // console.log('CustomPopup Container onMouseOut');
                setIsOpen(false);
                setIsHoverFromMarker(false);
              })
            }
          >
            <CustomPopup
              onCloseClick={toggle}
              ripspot={ripspot}
              isOpen={isOpen}
              handleCtaClick={handleOnClick}
            />
          </Popup>
        </>
      )}
    </>
  );
};

CustomMarker.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number).isRequired,
  pinName: PropTypes.string.isRequired,
  ripspot: PropTypes.any.isRequired,
  isTouchDevice: PropTypes.bool.isRequired,
  isHoverDevice: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func,
};

// const StyledPopup = styled(Popup)`
//   &:after {
//     content:"";
//     width: 100%;
//     height: 50px;
//     position: absolute;
//     bottom: -15px;
//     left: 0px;
//     z-index: 10;
//   }
// `;

export default memo(CustomMarker);
