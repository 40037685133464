/**
 *
 * useCopyValue
 *
 *
 */

import { useRef, useState, useEffect } from 'react';

//
// useCopyValue
//
export default () => {
  const [supportsCopy, setSupportsCopy] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const textareaRef = useRef(null);

  useEffect(() => {
    // check for compatibility
    if (document.queryCommandSupported('copy')) {
      setSupportsCopy(true);
    } else {
      // eslint-disable-next-line no-console
      console.log('sorry, Browser does not support copy');
    }
  }, []);

  function copyToClipboard(evt) {
    if (textareaRef.current) {
      // eslint-disable-next-line no-console
      console.log('onClick copyToClipboard');
      textareaRef.current.select();
      document.execCommand('copy');
      setCopySuccess(true);
    } else {
      // eslint-disable-next-line
      alert('naww');
    }
  }

  return {
    handleClick: copyToClipboard,
    textareaRef,
    copySuccess,
    supportsCopy,
  };
};
