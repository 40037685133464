/* eslint-disable react/prop-types */
/**
 *
 * CustomPopup
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CloseButton from 'components/CloseButton';
import Button from 'components/Elements/Button';
import { navigate } from '@reach/router';

const CustomPopup = ({
  ripspot,
  onCloseClick,
  isOpen,
  onMouseEnter,
  onMouseLeave,
  handleCtaClick
}) => {
  const handleViewRipspot = ripspotId => evt => {
    if (!isOpen) return;
    navigate(`/ripspot/${ripspotId}`);
  };

  const handleCloseClick = evt => {
    onCloseClick();
  };

  const birthDate =
    (ripspot && ripspot.birthDate && ripspot.birthDate.year) || 'N/A';
  const deathDate =
    (ripspot && ripspot.deathDate && ripspot.deathDate.year) || 'N/A';
  const name = (ripspot && ripspot.name) || 'NO RIPSPOT!!!';
  const defaultImage = ripspot && ripspot.defaultImage;

  // eslint-disable-next-line no-console
  console.log({ ripspot });

  // Make sure we dont cover any pins with blank ripspot pin
  if (!ripspot) return null;

  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <CloseWrapper>
        <CloseButton onClick={handleCloseClick} size="24" />
      </CloseWrapper>
      <DetailsWrapper>
        <p>{name}</p>
        {defaultImage && (
          <img src={defaultImage} alt="default img for profile" />
        )}
      </DetailsWrapper>
      <DateWrapper>
        <p>{birthDate}</p>-<p>{deathDate}</p>
      </DateWrapper>
      <Button
        onClick={handleCtaClick}
        primary
      >
        View Ripspot
      </Button>
    </Container>
  );
};

CustomPopup.propTypes = {
  ripspot: PropTypes.any,
  onCloseClick: PropTypes.func,
  isOpen: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

export default CustomPopup;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 140px;
  min-height: 60px;
  padding: 1.5em 2em 1em;

  /* border: 4px solid ${({ theme }) => theme.colors.Primary}; */

  > button {
    font-size: 1.2em;
    max-width: 140px;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    /* margin-bottom: 1em; */
  }
  img {
    width: 100%;
    height: auto;
    max-width: 40px;
    border-radius: 50%;
  }
`;

const CloseWrapper = styled.div`
  button {
    right: 0px;
  }
`;

const DateWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  justify-content: center;
  p {
    font-size: 1.4em;
    margin: 0 0.5em;
  }
`;
