/* eslint-disable import/no-named-as-default-member */
/**
 *
 * PhotoUploads to S3
 *
 *
 */

import React, { useState, useRef } from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SlickSlider from 'components/lib/Slick';
import Mobile from 'components/utils/Mobile';
import Desktop from 'components/utils/Desktop';

import PhotoCard from './PhotoCard';

// const arr = Array(10).fill(1);
const arr = Array(1).fill(1);

const PhotoUploads = ({
  setTempData,
  getTempData,
  getTempEditData,
  setTempEditData,
}) => {
  const tempData = getTempData();
  const slider = useRef();

  const [photoData, setPhotoData] = useState(tempData.photoGallery);

  const renderPhotoCard = (_item, index) => {
    const initialPhotoData =
      photoData && `_${index}` in photoData && photoData[`_${index}`].data;
    const initialPhotoText =
      photoData && `_${index}` in photoData && photoData[`_${index}`].photoText;
    const imageId =
      photoData && `_${index}` in photoData && photoData[`_${index}`].imageId;

    return (
      <PhotoCard
        key={index}
        indexKey={`_${index}`}
        initialPhotoText={initialPhotoText}
        initialPhotoData={initialPhotoData}
        onDelete={indexKey => {
          const _tempData = getTempData();
          const { [`_${index}`]: removed, ...rest } = _tempData.photoGallery;
          const photoArray = Object.values(rest); // Array.from(Object.values(rest));
          const newPhotoArrayIndexes = photoArray.map((item, updatedIndex) => ({
            ...item,
            index: updatedIndex,
          }));

          const newPhotoGalleryData = {};
          newPhotoArrayIndexes.forEach((item, newIndex) => {
            newPhotoGalleryData[`_${newIndex}`] = item;
          });

          console.log({ hotoGalleryData: _tempData.photoGallery });
          console.log({ removed });
          console.log({ newPhotoGalleryData });

          setTempData('photoGallery', newPhotoGalleryData);
          setPhotoData(newPhotoGalleryData);

          // EDIT mode only!!! imageId === editMode (true)
          if (removed && removed.imageId) {
            //
            // keep track of deleted images
            //
            const _editTempData = getTempEditData();
            const { deleted, edited } = _editTempData.photoGallery;
            // This allows us to remove any image left in edit that we may delete
            let updatedEdited = { ...edited };
            if (removed.imageId in updatedEdited) {
              // remove this image item from edit object
              const {
                [removed.imageId]: removedEditImage, // this removes the image from the edit object since we no longer care about changes and just want to delete
                ...restEditImages
              } = updatedEdited;
              // set the remaining edit images back
              updatedEdited = restEditImages;
            }
            const editPhotoGalleryData = {
              deleted: [...deleted, removed.imageId], // [imageId]
              edited: updatedEdited, // {  [imageId]: updateData }
            };
            // eslint-disable-next-line no-console
            console.log({ editPhotoGalleryData });
            setTempEditData('photoGallery', editPhotoGalleryData);
          }
        }}
        onSave={({ fileData, photoText, isProfile }) => {
          const _tempData = getTempData();

          console.log('\n\n\n');
          console.log('onSave');
          console.log({ imageId });
          console.log({ fileData, photoText, isProfile });

          if (!fileData && tempData.photoGallery) {
            const { [`_${index}`]: removed, ...rest } = tempData.photoGallery;
            setTempData('photoGallery', rest);
            console.log('!fileData && tempData.photoGallery');
            console.log({ removed });
            console.log({ rest });
            // setTempEditData('photoGallery', rest);
            setPhotoData(rest);
          } else {
            const newData = {
              [`_${index}`]: {
                data: fileData,
                index,
                photoText,
                isProfile,
              },
            };
            if (imageId) {
              newData[`_${index}`] = {
                ...newData[`_${index}`],
                imageId,
              };
            }
            console.log({ newData });
            const data = {
              ..._tempData.photoGallery,
              ...newData,
            };
            console.log({ data });
            setTempData('photoGallery', data);
            setPhotoData(data);

            if (imageId) {
              const _editTempData = getTempEditData();
              const { deleted, edited } = _editTempData.photoGallery;
              const editPhotoGalleryData = {
                deleted, // [imageId]
                edited: {
                  ...edited,
                  [imageId]: {
                    ...newData[`_${index}`],
                    imageId,
                  },
                }, // {  [imageId]: updateData }
              };
              console.log({ editPhotoGalleryData });
              setTempEditData('photoGallery', editPhotoGalleryData);
            }
          }
        }}
      />
    );
  };

  // {index}
  //   </PhotoCard>

  const photoKeys = photoData && Object.keys(photoData);
  const userHasImages = photoKeys && photoKeys.length > 0;

  const addIndex = (userHasImages && photoKeys && photoKeys.length) || 0;

  return (
    <Container>
      <Mobile>
        <SlickSlider
          setRef={slider}
          slides={arr}
          renderSlide={renderPhotoCard}
        />
      </Mobile>
      <Desktop>
        <List>
          {userHasImages &&
            photoKeys.map((_key, index) => {
              const item = photoData[_key];
              // // eslint-disable-next-line no-console
              // console.log({ item });
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`item-${index}`}>{renderPhotoCard(item, index)}</li>
              );
            })}
          <li key="Add-image-card">{renderPhotoCard(null, addIndex)}</li>
        </List>
      </Desktop>
    </Container>
  );
};

PhotoUploads.propTypes = {
  setTempData: PropTypes.func.isRequired,
  getTempData: PropTypes.func.isRequired,
  setTempEditData: PropTypes.func.isRequired,
  getTempEditData: PropTypes.func.isRequired,
};

export default PhotoUploads;

const Container = styled.div`
  height: 100%;
  ${({ theme }) => theme.media.max.mobile`
    overflow: hidden;
  `}
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;

  li {
    width: 33.3%;
    min-height: 240px;
  }
`;
