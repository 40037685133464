/**
 *
 * Payment
 *
 *
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import Stripe from 'components/lib/Stripe';
// import StripeV2 from 'components/lib/Stripe/V2';
import StripeV3 from 'components/lib/Stripe/V3';
import Spinner from 'components/Spinner';
import PriceBlock from 'components/PriceBlock';
import ToggleButton from 'components/ToggleButton';
import usePaymentTypeLogic from 'components/ReviewRipspot/usePaymentTypeLogic';

import { setIsProcessing, setTempPaymentInfo } from 'actions/checkout';
import { setStripeInstance } from 'actions/stripe';
import { submitToggleRipspotCreation } from 'actions/ripspots';

/**
 * Payment step of create ripspot flow - first payment step
 *
 * @param {*} { setTempData }
 * @param {*} { getTempData }
 * @param {*} { setDisableContinue }
 * @param {*} { completePurchase }
 * @param {*} { setCompletePurchase }
 * @param {*} { setCurrentStep }
 * @param {*} { checkout }
 * @param {*} { dispatch }
 * @param {*} { stripe }
 *
 * @returns
 */
const Payment = ({
  setTempData,
  getTempData,
  setDisableContinue,
  completePurchase,
  setCompletePurchase,
  setCurrentStep,
  checkout,
  dispatch,
  stripe,
  ripspotIsLoading,
}) => {
  const tempData = getTempData();
  const { plan } = tempData.payment;

  const { isAnnual, setIsAnnual, total } = usePaymentTypeLogic({
    setTempData,
    plan,
  });

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('\n\n\n\n');
    // eslint-disable-next-line no-console
    console.log('setDisableContinue(false);');
    // eslint-disable-next-line no-console
    console.log('\n\n\n\n');
    setDisableContinue(false);
  }, []);

  // eslint-disable-next-line no-console
  console.log('\n\n');
  // eslint-disable-next-line no-console
  console.log('Payment');
  // eslint-disable-next-line no-console
  console.log({ ripspotIsLoading });

  return (
    <Container>
      <Spinner loading={checkout.isPaying || ripspotIsLoading} />
      <PriceWrapper>
        <p>
          We believe celebrating a loved one should easy and affordable. A
          Ripspot is a lasting celebration of a loved one, but you’ll be able to
          edit its content at any time.
        </p>
        <StyledPriceBlock noMargin fontSizeOr="0.7rem" isAnnual={isAnnual} />
        <BottomPriceWrapper>
          <ToggleButton
            initLeftToggle={isAnnual}
            onToggle={toggleProps => {
              switch (toggleProps) {
                case 'annual':
                  setIsAnnual(true);
                  // setDisableContinue(true); // disable continue since user changed price tier
                  break;
                case 'one-time':
                  setIsAnnual(false);
                  // setDisableContinue(true); // disable continue since user changed price tier
                  break;
                default:
              }
            }}
          />
          <Total>
            <span>Total:</span> ${total}
          </Total>
          <p>
            <strong>Please enter your payment info below:</strong>
          </p>
        </BottomPriceWrapper>
      </PriceWrapper>
      <StripeV3
        setDisableContinue={setDisableContinue}
        completePurchase={completePurchase}
        onHandlePayment={(paymentMethod, token) => {
          // eslint-disable-next-line no-console
          console.log({ paymentMethod });
          // eslint-disable-next-line no-console
          console.log({ token });
          //
          //
          // TODO: Make call to API to create the payment or subscription
          //
          //
          // eslint-disable-next-line no-console
          console.log('Payment.handleOnPaymentInfoReceived');
          // eslint-disable-next-line no-console
          console.log({ total });
          // eslint-disable-next-line no-console
          console.log({ paymentMethod });

          const tokenDataToUse = isAnnual ? paymentMethod : token;
          // eslint-disable-next-line no-console
          console.log({ tokenDataToUse });

          // save processed payment for charging in next step
          dispatch(
            setTempPaymentInfo({
              amount: total, // TODO: might not need this here, bcuz will need to update redux inside Review step too if user changes plan
              tokenId: tokenDataToUse.id,
              tokenInfo: tokenDataToUse,
            }),
          );
          // save stripe instance for later repopulating of form fields
          dispatch(setStripeInstance(tokenDataToUse));
          // eslint-disable-next-line no-console
          console.log(
            'calling the new ripspot action to toggle review code to pay and create rippot!',
          );
          dispatch(submitToggleRipspotCreation()); // this var used to be local when review was last step, UX change so payment is now last, just call the code still in review as is for now using redux to toggle the onChangeWatcher...maybe refactor later
        }}
        onHandleError={error => {
          // eslint-disable-next-line no-console
          console.log({ error });
          // TODO: Need to handle credit card opayment errors here
        }}
      />
    </Container>
  );
};

Payment.propTypes = {
  setTempData: PropTypes.func.isRequired,
  getTempData: PropTypes.func.isRequired,
  completePurchase: PropTypes.bool.isRequired,
  setCompletePurchase: PropTypes.func.isRequired,
  setDisableContinue: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  checkout: PropTypes.any.isRequired,
  ripspotIsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ checkout, stripe, ripspots }) => ({
  checkout,
  stripe,
  ripspotIsLoading: ripspots.ripspotIsLoading,
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(Payment);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 125px;
  ${({ theme }) => theme.media.min.tablet`
    padding-top: 225px;
  `}
`;

const PriceWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 10px;
  padding-top: 140px;
  > p {
    margin: 1.25em 0;
  }

  ${({ theme }) => theme.media.max.tablet`
    padding-top: 70px;
  `}
`;

const StyledPriceBlock = styled(PriceBlock)`
  .price-box {
    padding: 20px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
    border: 2px solid transparent;
    &.active {
      border-color: ${({ theme }) => theme.colors.Primary};
      ${({ theme }) => theme.styles.boxShadows[1]};
    }
  }
`;

const BottomPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 35px;
  p {
    margin-top: 1em;
    strong {
      font-weight: 700;
      color: black;
      font-size: 1.1rem;
    }
  }
`;

const Total = styled.div`
  margin-top: 15px;
  font-size: 1.9rem;

  span {
    font-weight: bolder;
  }
`;
