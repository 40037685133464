import { createUser as createUserHTTP } from 'utils/api/users';

export const CREATE_USER = 'CREATE_USER';
export const createUser = data => dispatch => {
  createUserHTTP(data)
    .then(res => {
      // eslint-disable-next-line no-console
      // console.log({ res });
      // dispatch(setUser())
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      // console.log({ err });
    });
};

export const SET_USER = 'SET_USER';
export const setUser = user => ({
  type: SET_USER,
  user,
});

export const UPDATE_USER_RIPSPOT = 'UPDATE_USER_RIPSPOT';
export const updateUserRispot = (ripspotId, ripspot) => ({
  type: UPDATE_USER_RIPSPOT,
  ripspotId,
  ripspot,
});
