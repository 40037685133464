/**
 *
 * PhotoCard Utilities
 *
 *
 */

import {
  getBase64,
  resizeImageFile,
  dataURLtoFile,
} from 'utils/helperFunctions/images';

const MAX_IMAGE_FILE_SIZE = 5000000;

/**
 * proccessFile
 *
 * @param {*} file
 * @returns
 */
export const proccessFile = file =>
  new Promise(async (resolve, reject) => {
    // eslint-disable-next-line no-console
    console.log({ size: file.size });
    const shouldResizeImage = file.size >= MAX_IMAGE_FILE_SIZE; // 5MB

    let uri;
    let resizedFile = file; // default to file itself, only resize if big

    if (shouldResizeImage) {
      try {
        const res = await resizeImageFile(file, {});
        // eslint-disable-next-line prefer-destructuring
        uri = res.uri;
        // eslint-disable-next-line no-console
        console.log({ uri });
      } catch (resizeErr) {
        // eslint-disable-next-line no-console
        console.error(resizeErr);
        return reject(resizeErr);
      }

      try {
        resizedFile = dataURLtoFile(uri, file.name);
        // eslint-disable-next-line no-console
        console.log({ resizedFile });
      } catch (URIToFileCreationErr) {
        // eslint-disable-next-line no-console
        console.error(URIToFileCreationErr);
        return reject(URIToFileCreationErr);
      }
    }

    try {
      const src = await getBase64(resizedFile);
      // console.log({ file, imageSrc: src });
      return resolve({ file: resizedFile, imageSrc: src });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      reject(err);
    }
  });
