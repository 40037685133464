/**
 *
 * S3 Upload methods
 *
 *
 */

import { API_URL } from 'utils/api/urls';
import { getToken } from 'utils/api';

export const signRequest = ({ fileName, fileType }) =>
  new Promise((resolve, reject) =>
    fetch(`${API_URL}/api/sign_s3`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fileName,
        fileType,
      }),
    })
      .then(res => res.json())
      .then(({ signedRequest, url }) => {
        // eslint-disable-next-line no-console
        console.log(`Recieved a signed request ${signedRequest}  from API`);
        resolve({ signedRequest, url });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        reject(error);
      }),
  );

export const uploadSignedRequest = ({
  fileType,
  file,
  url,
  signedRequest,
  isProfile,
  photoText,
}) =>
  new Promise((resolve, reject) => {
    // Put the fileType in the headers for the upload
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': fileType,
      },
      body: file,
    };

    fetch(signedRequest, options)
      .then(res => {
        // eslint-disable-next-line no-console
        console.log('Response from s3');
        return resolve({ response: res, url, isProfile, photoText }); // pass through custom isProfile && photoText
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        reject(error);
      });
  });

export const uploadImage = ({
  file,
  fileName,
  fileType,
  isProfile,
  photoText,
}) =>
  signRequest({ fileName, fileType }).then(({ signedRequest, url }) =>
    uploadSignedRequest({
      fileType,
      file,
      url,
      signedRequest,
      isProfile,
      photoText,
    }),
  );
