/**
 *
 * Ripspot Modal
 *
 *
 */

import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { closeRipspotMemorialModal } from 'actions/modals';

import Modal from 'components/modals/index';
import RipspotMemorial from 'components/RipspotMemorial';
// import AnimatedTitle from 'components/AnimatedTitle';

import { getPublicRipspotById } from 'utils/api/ripspots';

/**
 * RipspotMemorialModal
 *
 * @param {*} { isOpen, dispatch, metadata, ripspotId }
 * @returns
 */
const RipspotMemorialModal = ({ isOpen, dispatch, metadata, ripspotId }) => {
  const [ripspot, setRipspot] = useState({});

  useEffect(() => {
    if (isOpen && ripspotId) {
      // OPEN!
      //
      // fetch data for ripspotId
      //
      (async () => {
        // eslint-disable-next-line no-console
        console.log('fetching Ripspot by Id');
        //
        const ripspotRes = await getPublicRipspotById(ripspotId);
        // eslint-disable-next-line no-console
        console.log({ ripspotRes });
        setRipspot(ripspotRes);
      })();
    } else {
      // not open
      // eslint-disable-next-line no-console
      console.log('not open!');
    }
  }, [isOpen]);

  const onRequestClose = () => {
    dispatch(closeRipspotMemorialModal());
  };

  return (
    <Modal
      noMinMax
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      width="calc(100vw - 60px)"
      height="calc(100vh - 80px)"
    >
      <Container>
        <RipspotMemorial isModal ripspot={ripspot} />
      </Container>
    </Modal>
  );
};

RipspotMemorialModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  metadata: PropTypes.object,
  ripspotId: PropTypes.string,
};

const mapStateToProps = ({ modals, location }) => ({
  location,
  isOpen: modals.ripspotMemorialModal.isOpen,
  metadata: modals.ripspotMemorialModal.metadata,
  ripspotId: modals.ripspotMemorialModal.ripspotId,
});

const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(RipspotMemorialModal);

const Container = styled.div`
  height: 100%;
`;
