/**
 *
 * Toastify Utils
 *
 *
 */

import { toast } from 'react-toastify';

export const toastSuccess = (msg, opts = {}) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    ...opts,
  });

export const toastError = (msg, opts = {}) =>
  toast.error(msg, {
    position: toast.POSITION.TOP_LEFT,
    autoClose: 3000,
    ...opts,
  });

export const toastWarn = (msg, opts = {}) =>
  toast.warn(msg, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    ...opts,
  });

export const toastInfo = (msg, opts = {}) =>
  toast.info(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 3000,
    ...opts,
  });

// UPDATING toasts
export const toastUpdate = (toastId, msg, opts = {}) =>
  toast.update(toastId, {
    render: msg,
    ...opts,
  });
export const toastUpdateSuccess = (toastId, msg, opts = {}) =>
  toast.update(toastId, {
    render: msg,
    type: toast.TYPE.SUCCESS,
    ...opts,
  });
export const toastUpdateError = (toastId, msg, opts = {}) =>
  toast.update(toastId, {
    render: msg,
    type: toast.TYPE.ERROR,
    ...opts,
  });
export const toastUpdateInfo = (toastId, msg, opts = {}) =>
  toast.update(toastId, {
    render: msg,
    type: toast.TYPE.INFO,
    ...opts,
  });
export const toastUpdateWarn = (toastId, msg, opts = {}) =>
  toast.update(toastId, {
    render: msg,
    type: toast.TYPE.WARN,
    ...opts,
  });
