/**
 *
 * useClickCountState
 *
 *
 */
import { useState, useEffect } from 'react';

const initialState = {
  clickCount: 0,
};

/**
 * Completely controlled like puppet by parent component
 *
 * @returns
 */
export const useClickCountState = () => {
  const [state, setState] = useState({
    clickCount: 0,
  });

  // add click to state
  const addClick = () => {
    const clickCount = state.clickCount + 1;
    setState({ clickCount });
  };

  const reset = () => {
    setState(initialState);
  };

  //
  // useEffect(() => {
  //   if (state.clickCount) {
  //     // eslint-disable-next-line no-console
  //     console.log({ clickCount: state.clickCount });
  //   }
  // }, [state.clickCount]);

  //
  return {
    state,
    addClick,
    reset,
  };
};

export default useClickCountState;
