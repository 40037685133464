/**
 *
 * Theme object
 *
 * injected into all styled-components
 */

import { css } from 'styled-components';
import { hex2rgb } from 'utils/helperFunctions/colors';

const boxShadowLevelLighter = css`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
`;
const boxShadowLevelLight = css`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.2);
`;
const boxShadowLevel0 = css`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
`;
const boxShadowLevel1 = css`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;
const boxShadowLevel2 = css`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;
const boxShadowLevel3 = css`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
`;
const boxShadowLevel4 = css`
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`;
const boxShadowLevel5 = css`
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
`;

/**
 * Create mediaQueries based on static screen sizes
 * and expose them on theme object
 *
 * e.g. ${({theme}) => theme.media.min` css goes here  `}
 *
 */

// BEAKPOINTS
const sizes = {
  desktopXL: 1380,
  desktop: 1040,
  tablet: 768,
  mobile: 576,
};

const MAX_WIDTH = 1366;

// Iterate through the sizes and create a media template

// media Min
// Inclusive, includes breakpoint value in query
const min = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

// media Max
// Exclusive, Subtract 1 from breakpoint for max, excludes breakpoint
const max = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] - 1}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

/**
 * Shows/Hide animated styles
 *
 * @param {boolean} isOpen - is visible
 * @param {number} [duration=0.3] -  duration of transitions
 * @param {string} [transitions=''] - This accepts additional transitions or overrides
 */
const showhideStyles = (isOpen, duration = 0.3, transitions = '') => css`
  max-height: 0px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: max-height ${duration}s, opacity ${duration}s,
    visibility ${duration}s ease ${duration}s
      ${transitions && `, ${transitions}`};
  ${isOpen &&
    css`
      max-height: 999px;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transition: max-height ${duration}s, opacity ${duration}s,
        visibility ${duration}s ease 0s ${transitions && `, ${transitions}`};
    `}
`;

export default {
  colors: {
    alphaMod: (val, opacity) => hex2rgb(val, opacity),
    // TODO: replace with actual branding colors
    Primary: '#006838',
    Secondary: '#E4E2DD',
    Compliment: 'rebeccapurple',
    midGreen: '#00B561',
    lightGreen: '#99cc33',
    disabled: '#ebebe4',
    text: '#000',
    // TODO: go over offwhite options
    offWhite: '#fefefe',
    // TODO: go over /grey scales
    lightGrey: '#E4E2DD',
    midGrey: '#C4C0BB',
    darkGrey: '#707070',
    Blue: '#4fc4d0',
    Red: '#f14848',
    brands: {
      Facebook: '#3b5998',
      Twitter: '#1da1f2',
      Instagram: '#c13584', // '#405de6'
    },
    button: {
      primary: '#000',
      hover: '#000',
      active: '#000',
      border: '#000',
    },
    system: {
      ERROR_DARK: '#f14848',
      ERROR: '#f7d7da',
      SUCCESS: '#d5edda',
      WARNING: '#fff3cd',
      GENERAL: '#cae6fe',
    },
  },
  media: { min, max, MAX_WIDTH },
  breakpoints: sizes,
  styles: {
    showhideStyles,
    middle: css`
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    ellipsisMask: css`
      display: inline-block;
      font-size: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `,
    grow: css`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    `,
    boxShadows: {
      light: boxShadowLevelLight,
      lighter: boxShadowLevelLighter,
      0: boxShadowLevel0,
      1: boxShadowLevel1,
      2: boxShadowLevel2,
      3: boxShadowLevel3,
      4: boxShadowLevel4,
      5: boxShadowLevel5,
    },
  },
};
