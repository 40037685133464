/**
 *
 * Touch Device Ripspot Pin
 *
 * Fullpage, fixed pin in center of map
 *
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import icons from 'components/lib/Mapbox/utils/icons';
import PinPopup from 'components/lib/Mapbox/Marker/PinPopup';
// import RipspotPin from 'images/ripspot-pin/ripspot-pin-400px.png';

const iconsObj = icons.reduce(
  (acc, item) => ({
    ...acc,
    [item.name]: item.image,
  }),
  {},
);

const TouchDevicePin = ({
  newMarker,
  showPopup,
  draggable,
  currentPinName,
  onSaveClick,
  onEditPinClick,
  editInProgress,
  onEditRipspotClick,
  onViewRipspotClick,
}) => {
  const [isOpen, setIsOpen] = useState(showPopup);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Container>
      <Content>
        <ImgWrapper onClick={toggle}>
          <StyledImg src={iconsObj[currentPinName]} alt="ripspot pin" />
        </ImgWrapper>
        {isOpen && (
          <PopupWrapper>
            <PinPopup
              isTouch
              onRequestClose={toggle}
              draggable={draggable}
              newMarker={newMarker}
              // ripspot={ripspot}
              // pin={pin}
              // pinIndex={pinIndex}
              onEditPinClick={onEditPinClick}
              editInProgress={editInProgress}
              onSaveClick={onSaveClick}
              // onCancelClick={onCancelClick}
              onEditRipspotClick={onEditRipspotClick}
              onViewRipspotClick={onViewRipspotClick}
            />
          </PopupWrapper>
        )}
      </Content>
    </Container>
  );
};

TouchDevicePin.propTypes = {
  newMarker: PropTypes.bool,
  showPopup: PropTypes.bool,
  draggable: PropTypes.bool,
  currentPinName: PropTypes.string,
  onSaveClick: PropTypes.func,
  onEditPinClick: PropTypes.func,
  editInProgress: PropTypes.bool,
  onEditRipspotClick: PropTypes.func,
  onViewRipspotClick: PropTypes.func,
};

export default TouchDevicePin;

const Container = styled.div`
  ${({ theme }) => theme.styles.middle}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  /*  use this to test center of map */

  /* &::after {
    content: '';
    background-color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    transform: translate(-50%, -50%);
  } */
`;

const Content = styled.div`
  position: relative;
  pointer-events: all;
`;

const PopupWrapper = styled.div`
  background-color: #fff;
  position: absolute;
  top: -100%;
  left: -100%;
  z-index: 999;
  pointer-events: all;
  transform: translate(-50%, -50%);
`;

const ImgWrapper = styled.div`
  ${({ theme }) => theme.styles.middle}
  width: 47px;
  height: 47px;
  pointer-events: all;
  transform: translate(0, -50%);
`;

const StyledImg = styled.img`
  height: 100%;
  width: auto;
`;
