/**
 *
 * FAQ Page
 *
 *
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import SEO from 'components/seo';
import FAQPage from 'containers/FAQPage';
import theme from 'utils/theme';

const FAQ = ({ location }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <SEO title="Frequently Asked Questions" />
        <FAQPage location={location} limit={false}/>
      </Container>
    </ThemeProvider>
  );
};

FAQ.propTypes = {
  location: PropTypes.any,
};

export default FAQ;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;
