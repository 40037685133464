/**
 *
 * Spinner - better-react-spinkit
 *
 *
 */

import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Spinkit from 'react-spinkit';

const Spinner = ({
  loading,
  size = 35,
  color = 'cornflowerblue',
  cover = false,
  fullBleed = false,
  bgColor = false,
}) => (
  <Container
    cover={cover}
    fullBleed={fullBleed}
    bgColor={bgColor}
    // odd bug where boolean cant be passed to DOM node
    loading={loading ? 'true' : ''}
  >
    <Spinkit name="ball-scale-ripple" size={size} color={color} />
  </Container>
);

Spinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  fullBleed: PropTypes.bool, // covers relative to view
  cover: PropTypes.bool, // covers relative to parent & centers spinner
};

export default memo(Spinner);

const Container = styled.div`
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s ease 0.3s;
  z-index: 999;

  ${({ loading }) =>
    loading &&
    css`
      transition: opacity 0.3s, visibility 0.3s;
      visibility: visible;
      opacity: 1;
    `}

  ${({ theme }) => theme.styles.middle}
  ${({ cover, fullBleed }) =>
    (cover || fullBleed) &&
    css`
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `};
  ${({ cover }) =>
    cover &&
    css`
      position: absolute;
    `};

  ${({ fullBleed }) =>
    fullBleed &&
    css`
      position: fixed;
    `};
  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: rgba(0, 0, 0, 0.5);
    `};
`;
