/* eslint-disable react/jsx-no-target-blank */
/**
 *
 * Footer
 *
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, navigate } from '@reach/router';

import LogoIcon from 'components/icons/Logo';
import WhiteGreenPin from 'components/icons/Ripspot/whiteGreenPin';
import Button from 'components/Elements/Button';
import SocialMediaIcons from 'components/SocialMediaIcons';
import Anvil from 'components/icons/anvil';

import Mobile from 'components/utils/Mobile';
import Desktop from 'components/utils/Desktop';

import { openSignupModal } from 'actions/modals';
import { handleNewRipspots } from 'actions/ripspots';

import Policies from './Policies';
import Address from './Address';

export const FOOTER_HEIGHT = 300;

const linksLeftColumn = [
  {
    name: 'Search',
    href: '/search',
  },
  {
    name: 'Sign In',
    href: '/login',
  },
  {
    name: 'Account',
    href: '/account',
  },
  {
    name: 'Create Account',
    href: '/register',
  },
];
const linksRightColumn = [
  {
    name: 'Contact Us',
    href: 'mailto:support@ripspot.com',
  },
  {
    name: 'FAQ',
    href: '/faq',
  },
  {
    name: 'Privacy Policy',
    href: '/privacy',
  },
  {
    name: 'Terms & Conditions',
    href: '/terms-and-conditions',
  },
];

const Footer = ({ dispatch, user, ripspots }) => {
  const handleCreateRipspot = () => {
    console.log('handleCreateRipspot');
    console.log({ user });
    if (user) {
      dispatch(handleNewRipspots(ripspots));
    } else {
      dispatch(openSignupModal());
    }
  };

  return (
    <StyledFooter>
      <Content>
        <StyledMobile>
          {/* TOP */}
          <Top>
            <WhiteGreenPin />
            <CreateRipspotBtn onClick={handleCreateRipspot} />
          </Top>
          {/* Address / Policies */}
          <Information>
            <Address />
            <Policies />
          </Information>
          {/* SOCIAL MEDIA / CRAFTED BY */}
          <Bottom>
            {/* <SocialMediaIcons twitter={false} /> */}
            <CoCraftedBy />
          </Bottom>
        </StyledMobile>

        <StyledDesktop>
          <Wrapper>
            <Top>
              <WhiteGreenPin />
              <Address />
            </Top>
            <Menu>
              <List>
                {linksLeftColumn.map(link => (
                  <li key={link.href}>
                    <Link to={link.href}>{link.name}</Link>
                  </li>
                ))}
              </List>
              <List>
                {linksRightColumn.map(link => (
                  <li key={link.href}>
                    {link.href.includes('mailto') ? (
                      <a href={link.href} target="_blank">
                        {link.name}
                      </a>
                    ) : (
                      <Link to={link.href}>{link.name}</Link>
                    )}
                  </li>
                ))}
              </List>
            </Menu>
            <Bottom>
              <CreateRipspotBtn
                onClick={evt => {
                  console.log('scrolllllllll');
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                  setTimeout(() => {
                    handleCreateRipspot(evt);
                  }, 500);
                }}
              />
              {/* <SocialMediaIcons twitter={false} /> */}
            </Bottom>
          </Wrapper>
          <CoCraftedBy />
        </StyledDesktop>
      </Content>
    </StyledFooter>
  );
};
Footer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

Footer.defaultProps = {};

const mapStateToProps = ({ user, ripspots }) => ({ user, ripspots });
const withConnect = connect(mapStateToProps);
export default compose(withConnect, memo)(Footer);

const CoCraftedBy = () => (
  <CraftedBy>
    <a href="https://thesmithy.io/" target="_blank">
      Crafted by The Smithy <Anvil width="25px" />
    </a>
  </CraftedBy>
);

const CreateRipspotBtn = props => (
  <StyledButton light {...props}>
    Create A Ripspot
  </StyledButton>
);

const StyledFooter = styled.footer`
  height: ${FOOTER_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.Primary};

  a {
    color: #fff;
    font-size: 1rem;
    text-decoration: underline;
    transition: color 0.3s;
    &:hover {
      color: #ddd;
    }
  }

  p {
    color: #fff;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 15px;
`;

const StyledMobile = styled(Mobile)`
  height: 100%;
`;
const StyledDesktop = styled(Desktop)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Menu = styled.div`
  display: flex;
  flex: 1;
`;

const List = styled.ul`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  flex: 50%;
  margin: 0;
  padding: 0;
  ${({ theme }) => theme.media.min.mobile`
    padding-left: 5%;
  `}
  ${({ theme }) => theme.media.min.tablet`
    padding-left: 20%;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  ${({ theme }) => theme.media.min.tablet`
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  `}
  svg {
    width: 180px;
    height: auto;
  }
`;

const Information = styled.div`
  display: flex;
  padding: 15px 0px;
`;

const Bottom = styled.div`
  display: flex;
  padding: 15px 0px;
  ${({ theme }) => theme.media.min.tablet`
    flex-direction: column;
    padding: 0px;
    justify-content: space-between;
  `}
`;

const CraftedBy = styled.div`
  width: 100%;
  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
    ${({ theme }) => theme.media.min.tablet`
      justify-content: center;
      width: 100%;
      padding-top: 25px;
    `}
  }

  svg {
    fill: ${({ theme }) => theme.colors.midGreen};
    width: auto;
    height: 30px;
    margin-left: 15px;
  }
`;

const StyledButton = styled(Button)`
  padding: 10px;
  font-size: 0.8rem;

  ${({ theme }) => theme.media.min.tablet`
    font-size: 1rem;
  `}
`;
