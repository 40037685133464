/**
 *
 * ForgotPassword
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { toastSuccess, toastError } from 'utils/toastify';
import { forgotPassword } from 'utils/api/users';
import ForgotPasswordFormik from './ForgotPasswordFormik';

const ForgotPassword = ({ navigate, isModal = false }) => {
  return (
    <Container>
      {!isModal && <Title>Forgot Password</Title>}
      <ForgotPasswordFormik
        initialValues={{
          email: '',
        }}
        // eslint-disable-next-line
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          // eslint-disable-next-line no-console
          console.log({ values });
          try {
            const res = await forgotPassword({ email: values.email });
            // eslint-disable-next-line no-console
            console.log({ res });
            setSubmitting(false);
            navigate('/login');
            toastSuccess('Plase check your email (:');
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            setSubmitting(false);
            toastError(err.reason || 'Sorry, something went wrong.');
          }
        }}
      />
    </Container>
  );
};

ForgotPassword.propTypes = {
  navigate: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
};

export default ForgotPassword;

const Container = styled.div`
  color: #000;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 50px;
`;

const Title = styled.h2`
  margin-left: 15px;
`;
