/**
 *
 * Landing Page
 *
 *
 */

import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
// import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { navigate } from '@reach/router';
import { Textfit } from 'react-textfit';

import { connect } from 'react-redux';
import { compose } from 'redux';

import PropTypes from 'prop-types';

import Card from 'components/Card';
import MapBox from 'components/lib/Mapbox/Loadable';
import Vimeo from 'components/lib/Vimeo';
import Button from 'components/Elements/Button';
import BlockQuote from 'components/Elements/BlockQuote';
import PriceBlock from 'components/PriceBlock';
import Memorial from 'components/icons/Memorial';
import Chevron from 'components/icons/Chevron';
import Share from 'components/icons/Share';
import Desktop from 'components/utils/Desktop';
import Mobile from 'components/utils/Mobile';
import WhiteGreenPin from 'components/icons/Ripspot/whiteGreenPin';
import Location from 'components/icons/Location';
import Camera from 'components/icons/Camera';
import locateImage from 'images/content/locate_blank.jpg';
import createImage from 'images/content/create_blank.jpg';
import shareImage from 'images/content/share_blank.jpg';
import TileOneSpacer from 'images/content/pin_ack.jpg';
import TileTwoSpacer from 'images/content/pin_lighthouse.jpg';
import TileThreeSpacer from 'images/content/pin_mountains.jpg';
import videoBg from 'images/content/site-bg.jpg';
import FAQPage from 'containers/FAQPage';
import { EXPLORE_ROUTE, FAQ_ROUTE } from 'utils/routes';

import { openSignupModal } from 'actions/modals';

const Landing = ({ props, dispatch, user }) => {
  const intro = useRef();
  const container = useRef();

  const [windowWidth, setWindowWidth] = useState(0);
  useEffect(() => {
    const width = window.innerWidth;
    setWindowWidth(width);
  }, []);

  const handleCreateRipspot = () => {
    if (user) {
      navigate('/search', { state: { createNewRipspot: true } });
    } else {
      dispatch(openSignupModal());
    }
  };

  const animatedProps = useSpring({
    opacity: 1,
    transform: 'translate3d(0,0,0)',
    zIndex: 2,
    from: { opacity: 0, transform: 'translate3d(0,15px,0)' },
  });

  return (
    <Container ref={container}>
      <Header style={{ backgroundImage: `url(${videoBg})` }}>
        {windowWidth >= 1024 && (
          <Vimeo
            vimeoUrl="https://player.vimeo.com/video/366739966?background=1"
            background
          />
        )}
        {/* <animated.div style={animatedProps}> */}
        <div style={{ zIndex: 2, width: '100%', marginTop: '-20px' }}>
          <SVGWrapper style={{ zIndex: 2 }}>
            <WhiteGreenPin />
          </SVGWrapper>
          <Desktop>
            <HeaderTitle>Rest in peace wherever you'd like.</HeaderTitle>
          </Desktop>
          <Mobile>
            <Textfit mode="single">
              <span
                style={{
                  color: '#fff',
                  fontFamily: 'Libre Baskerville, serif',
                }}
              >
                Rest in peace wherever you'd like.
              </span>
            </Textfit>
          </Mobile>
        </div>
        <Chevron
          width="3em"
          height="3em"
          fill="#fff"
          className="scroll-arrow"
          onClick={() => {
            intro.current &&
              intro.current.scrollIntoView({ behavior: 'smooth' });
          }}
        />
      </Header>

      {/* <Spacing /> */}

      <Section ref={intro} style={{ marginTop: '30px' }}>
        <TextBlock>
          Welcome to Ripspot®, a more personal, eco-friendly way to celebrate a loved one or pet who has passed away. Whether their favorite place was a beach, mountaintop, city park, fishing hole, or ski slope, now it can be the place they rest in peace.
          <br />
          <br />
          A Ripspot is ideal for marking the spot cremated ashes have been scattered or noting a place as meaningful to your pet or loved one.. Simply locate, create, and share. A Ripspot can also serve as an obituary, making it easy to connect with others and celebrate a wonderful life.
        </TextBlock>
      </Section>

      <Section style={{ maxWidth: 'none' }} className="full-bleed">
        <Title style={{ maxWidth: '680px', margin: '0px auto 40px' }}>
          How It Works
        </Title>
        <CardsWrapper>
          <Card
            title="Locate"
            textItems={[
              'Choose a meaningful spot',
            ]}
            SvgComp={Location}
            bgImage={locateImage}
            className="has-bg"
          />
          <Card
            title="Create"
            textItems={['Write their story and build a photo gallery.']}
            SvgComp={Memorial}
            bgImage={createImage}
            className="has-bg"
          />
          <Card
            title="Share"
            textItems={['Let others pay tribute to their wonderful life.']}
            SvgComp={Share}
            bgImage={shareImage}
            className="has-bg"
          />
        </CardsWrapper>
      </Section>

      <Section className="pricing-block">
        <div>
          <Title style={{ maxWidth: '720px', margin: '0px auto 40px' }}>
            Getting Started
          </Title>
          <TextBlock>
            We will guide you through the three easy steps to help celebrate a loved one who has passed. After you’ve chosen a location and created their biography and photo gallery, you’ll be ready to share with friends and family. At checkout, you’ll be able to choose a payment that works best for you, either a one-time payment or billed annually. We believe the process of celebrating a loved one should be simple and affordable.
          </TextBlock>
          <CardsWrapper>
            <Card
              textItems={['Unlimited photo uploads']}
              SvgComp={Camera}
              className="two-up"
            />
            <Card
              textItems={['Unlimited pins on map']}
              SvgComp={Location}
              className="two-up"
            />
          </CardsWrapper>
          <Title style={{ marginTop: '50px' }}>Pay How You Want</Title>
          <PriceBlock />
          <Button light onClick={handleCreateRipspot}>
            Create Ripspot
          </Button>
        </div>
      </Section>

      <Section style={{ maxWidth: 'none' }}>
        <BlockQuote text="Where would you like to rest in peace?" />
      </Section>

      <Section className="video-block">
        <Vimeo vimeoUrl="https://player.vimeo.com/video/393930591" background />
      </Section>

      <Section>
        <Title style={{ maxWidth: '680px', margin: '0px auto 40px' }}>
          Mission
        </Title>
        <TextBlock>
          Losing a loved one or pet will never be easy, but the process of
          celebrating their life doesn’t need to be complicated. At Ripspot, we
          are committed to using technology to make the process more relevant,
          affordable, and eco-conscious.
        </TextBlock>
      </Section>

      {/* IMG SPACER SECTION */}
      <Section className="full-bleed" style={{ maxWidth: 'none' }}>
        <CardsWrapper>
          <Card bgImage={TileOneSpacer} className="img-only" />
          <Card bgImage={TileTwoSpacer} className="img-only" />
          <Card bgImage={TileThreeSpacer} className="img-only" />
        </CardsWrapper>
      </Section>
      <Section className="has-cta">
        <FAQPage limit={3} />
        <Button light onClick={() => navigate(FAQ_ROUTE)}>
          View All
        </Button>
        {/* </ExploreMoreBtn> */}
      </Section>

      <Section className="explore-container">
        {MapBox && <MapBox demo height="55vh" />}
        <ExploreMoreBtn>
          <Button primary onClick={() => navigate(EXPLORE_ROUTE)}>
            Explore More
          </Button>
        </ExploreMoreBtn>
      </Section>
    </Container>
  );
};

Landing.propTypes = {
  dispatch: PropTypes.func.isRequired,
};
const mapStateToProps = ({ user }) => ({ user });
const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Landing);

const Container = styled.div`
  position: relative;
  color: #000;

  .my-span {
    font-family: 'Libre Baskerville', serif;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 55px);
  padding: 5px;
  background-image: url('images/splash-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
  svg {
    margin-top: -30px;
  }
  .scroll-arrow {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    cursor: pointer;
  }
`;

const Section = styled.section`
  padding: 15px;
  margin: 0 auto;
  margin-bottom: 45px;
  max-width: 680px;

  &.explore-container {
    width: 100%;
    padding: 0px;
    max-width: none;
    margin-bottom: 0px;
  }

  &.full-bleed {
    padding: 0px;
    width: 100%;
  }

  &.has-cta {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    button {
    }
  }

  &.video-block {
    max-width: 1024px;
    position: relative;
    padding: 0px;
    &:after {
      content: '';
      display: block;
      padding-bottom: 56.25%;
    }
    iframe {
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
    }
  }

  &.pricing-block {
    max-width: none;
    background-image: url('images/pin-texture.png');
    background-size: cover;
    background-position: center center;
    > div {
      max-width: 680px;
      margin: 0 auto;
      display: block;
    }
    button {
      display: block;
      margin: 0 auto;
      margin-top: 3em;
    }
    div.two-up {
      padding: 1em 3em;
      border: 2px solid ${({ theme }) => theme.colors.Primary};
      border-radius: 3px;
      ul {
        padding: 0;
      }
      p {
        color: black;
        font-size: 1.8em;
      }
    }
  }
`;

const SVGWrapper = styled.div`
  width: 80%;
  max-width: 500px;
  margin: 0 auto 25px;
`;

const CardsWrapper = styled.div`
  ${({ theme }) => theme.media.min.mobile`
    display: flex;
    margin-top: 50px;
  `}
`;

const titleStyles = css`
  font-family: 'Libre Baskerville', serif;
  color: white;
  padding: 0 15px;
  z-index: 2;
`;

const Span = styled.span`
  color: white;
  font-family: 'Libre Baskerville', serif;
`;

const FitTitle = styled.div`
  ${titleStyles}
`;

const HeaderTitle = styled.h2`
  ${titleStyles}
  font-size: 1.3rem;
  ${({ theme }) => theme.media.min.mobile`
    font-size: 1.8rem;
  `}
`;

const Title = styled.h3`
  font-weight: lighter;
  font-family: 'Libre Baskerville', serif;
  margin-top: 20px;
  margin-bottom: 35px;
  padding-bottom: 10px;
  text-align: center;
  ${({ theme }) => theme.media.min.tablet`
    font-size: 2.5rem;
  `}
`;

const TextBlock = styled.p`
  padding: 5px;
  margin-bottom: 15px;
  font-size: 2em;
`;

const LearnMore = styled.div`
  text-align: center;
  z-index: 2;
  margin-top: 35px;
  ${({ theme }) => theme.media.min.tablet`
    margin-bottom: 55px;
  `}
`;

const ExploreMoreBtn = styled.div`
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
`;
