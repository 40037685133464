/**
 *
 * ChangePassword
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { navigate } from '@reach/router';

import Link from 'components/Elements/Link';
import { ACCOUNT_ROUTE, CHANGE_EMAIL_ROUTE } from 'utils/routes';
import { updateMe } from 'utils/api/users';
import { toastSuccess, toastError } from 'utils/toastify';
import ChangePasswordFormik from './ChangePasswordFormik';

const ChangePassword = props => {
  return (
    <Container>
      <Title>Change Password</Title>
      <ChangePasswordFormik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          newRePassword: '',
        }}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            const data = {
              password: values.currentPassword,
              newPassword: values.newPassword,
            };
            const res = await updateMe(data);
            if (res) {
              setTimeout(() => {
                toastSuccess('Successfully changed password!');
                setSubmitting(false);
                navigate('/account');
              }, 400);
            } else {
              toastError('Sorry, something went wrong.');
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log({ err });
            toastError(err.message || 'Sorry, something went wrong.');
            if (err.code && (err.code === 422 || err.code === 401)) {
              if (err.location === 'password') {
                setFieldError('currentPassword', err.message);
              } else {
                setFieldError(err.location, err.message);
              }
            }
            setSubmitting(false);
          }
        }}
      />
      <Link style={{ marginBottom: '15px' }} primary to={ACCOUNT_ROUTE}>
        My Account
      </Link>
      <Link primary to={CHANGE_EMAIL_ROUTE}>
        Change Email
      </Link>
    </Container>
  );
};

ChangePassword.propTypes = {};

export default ChangePassword;

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  background-color: #f3f2f1;
  background-color: #f9f9f8;
  color: ${({ theme }) => theme.colors.Primary};
  max-width: 500px;
  margin: 100px auto 0;
  padding: 15px;
  border-radius: 3px;
`;

const Title = styled.h2``;
