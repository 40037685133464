/**
 *
 * Explore Page
 *
 *
 */

import React, { memo } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import SEO from 'components/seo';
import ExploreMap from 'containers/ExploreMap';

import theme from 'utils/theme';
import { SEO_TITLE } from 'utils/constants';

const ExplorePage = ({ location }) => (
  <ThemeProvider theme={theme}>
    <Container>
      <SEO title={SEO_TITLE} />
      <ExploreMap location={location} />
    </Container>
  </ThemeProvider>
);

ExplorePage.propTypes = {
  location: PropTypes.any,
};

export default memo(ExplorePage);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
