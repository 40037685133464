/**
 *
 * Bio Rich Text Editor
 *
 *
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Draft from 'components/lib/DraftJs';

const Bio = ({ setTempData, getTempData, setDisableContinue }) => {
  const _tempData = getTempData();
  useEffect(() => {
    if (!_tempData.bio.text) {
      setDisableContinue(true);
    } else {
      setDisableContinue(false);
    }
  }, []);
  return (
    <Container>
      <Draft
        initialEditState={_tempData.bio}
        onEditChange={richState => {
          setTempData('bio', {
            richState,
          });
        }}
      />
    </Container>
  );
};

Bio.propTypes = {
  setTempData: PropTypes.func.isRequired,
  getTempData: PropTypes.func.isRequired,
  setDisableContinue: PropTypes.func.isRequired,
};

export default Bio;

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  height: 100%;
  width: 100%;
  padding-top: 40px;
  padding: 15px;
`;
