/**
 *
 * Details
 *
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DetailsFormik from './DetailsFormik';
import validate from './DetailsFormik/validators';

// const initialValues = {
//   name: '',
//   birthDate: '',
//   deathDate: '',
//   branch: '',
//   isArmedServices: false,
// };

const Details = ({
  setTempData,
  getTempData,
  dispatch,
  setDisableContinue,
}) => {
  const _tempData = getTempData();

  // // eslint-disable-next-line no-console
  // console.log({ _tempData });

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      'validate tempData init details data and setDiscontonue if needed',
    );
    validate(_tempData.details, setDisableContinue);
  }, []);

  return (
    <Container>
      <DetailsFormik
        setTempData={setTempData}
        getTempData={getTempData}
        dispatch={dispatch}
        setDisableContinue={setDisableContinue}
        initialValues={{
          name: (_tempData && _tempData.details.name) || '',
          birthDate: (_tempData && _tempData.details.birthDate) || '',
          deathDate: (_tempData && _tempData.details.deathDate) || '',
          branch: (_tempData && _tempData.details.branch) || '',
          isArmedServices:
            (_tempData && _tempData.details.isArmedServices) || false,
        }}
        validate={values => validate(values, setDisableContinue)}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          // eslint-disable-next-line no-console
          console.log({ values });
          setSubmitting(false);
        }}
      />
    </Container>
  );
};

Details.propTypes = {
  setTempData: PropTypes.func.isRequired,
  getTempData: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  setDisableContinue: PropTypes.func.isRequired,
};

export default Details;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
`;
