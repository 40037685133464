/**
 *
 *  LoginFormik
 *
 *
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';

import Spinner from 'components/Spinner';
import Checkbox from 'components/Elements/Checkbox';
import Link from 'components/Elements/Link';
import Button from 'components/Elements/Button';
import { FormWrapper, StyledField, ExtraAlt } from 'components/lib/Formik';

import { SIGNUP_ROUTE, FORGOT_PASSWORD_ROUTE } from 'utils/routes';

import validate from './validators';

const LoginFormik = ({
  initialValues,
  onSubmit,
  handleRedirect,
  serverError,
  handleResendConfirmCode,
}) => {
  const handleChange = useCallback((field, setFieldValue) => evt => {
    const { value } = evt.target;
    setFieldValue(field, value);
  });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        // eslint-disable-next-line
        ...rest
      }) => {
        return (
          <Form style={{ position: 'relative' }}>
            <FormWrapper>
              <Spinner loading={isSubmitting} cover />
              <FormFields>
                <StyledField
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  touched={touched}
                  errors={errors}
                  value={values.email}
                  onBlur={() => setFieldTouched('email', true)}
                  onChange={handleChange('email', setFieldValue)}
                />
                <StyledField
                  wrapperStyle={{ marginBottom: 8 }}
                  type="password"
                  name="password"
                  placeholder="Password"
                  touched={touched}
                  errors={errors}
                  value={values.password}
                  onBlur={() => setFieldTouched('password', true)}
                  onChange={handleChange('password', setFieldValue)}
                />
                <Link
                  secondary
                  to={FORGOT_PASSWORD_ROUTE}
                  onClick={
                    handleRedirect && handleRedirect(FORGOT_PASSWORD_ROUTE)
                  }
                  style={{
                    display: 'inline-block',
                    fontSize: '0.8rem',
                    marginBottom: '15px',
                    marginLeft: '3px',
                  }}
                >
                  Recover Password
                </Link>
              </FormFields>

              <Field
                style={{ marginTop: '5px', marginBottom: '10px' }}
                name="rememberMe"
                fill="#bbb"
                component={Checkbox}
                iconWidth={24}
                initialValue={!!values.rememberMe}
                checked={!!values.rememberMe}
                renderLabel={() => <Span>Remember Me</Span>}
                onChange={checked => {
                  setFieldValue('rememberMe', checked);
                  localStorage.setItem(
                    'rememberMe',
                    checked ? values.email : '',
                  );
                }}
              />

              {serverError && (
                <ServerError>
                  <Error>{serverError}</Error>
                  <DidNotReceive>
                    Didn&#39;t receive an email?
                    <Link
                      to="/resendConfirm"
                      onClick={evt => {
                        evt.preventDefault();
                        handleResendConfirmCode(values.email);
                      }}
                    >
                      Click here
                    </Link>
                  </DidNotReceive>
                </ServerError>
              )}

              <Controls>
                <Button light type="submit" disabled={isSubmitting}>
                  Login
                </Button>
              </Controls>
              <ExtraAlt>
                <div>
                  <span>Don&#39;t Have an account?</span>
                  <Link
                    secondary
                    to="/register"
                    onClick={handleRedirect && handleRedirect(SIGNUP_ROUTE)}
                  >
                    Register
                  </Link>
                </div>
              </ExtraAlt>
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

LoginFormik.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleRedirect: PropTypes.func,
  handleResendConfirmCode: PropTypes.func.isRequired,
  serverError: PropTypes.any,
};

export default LoginFormik;

const FormFields = styled.div`
  > div {
    margin-bottom: 30px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  padding-bottom: 25px;

  ${({ theme }) => theme.media.max.mobile`
    button {
      padding: 10px 15px;
      &:first-of-type {
        margin-right: 5px;
      }
    }
`}
`;

const Span = styled.span`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1rem;
`;

const ServerError = styled.div`
  color: ${({ theme }) => theme.colors.Red};
`;

const Error = styled.p`
  color: ${({ theme }) => theme.colors.Red};
`;

const DidNotReceive = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  margin-top: 20px;
  font-size: 0.8rem;

  a {
    color: ${({ theme }) => theme.colors.darkGrey};
    text-decoration: underline;
    margin-left: 10px;
    &:hover {
      color: ${({ theme }) => theme.colors.button.hover};
    }
  }
`;
