/**
 *
 * Share Ripspot
 *
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from 'components/Elements/Button';
import Input from 'components/Elements/Input';

import Desktop from 'components/utils/Desktop';
import Mobile from 'components/utils/Mobile';

import Mail from 'components/icons/Mail';
import {
  Facebook as FacebookLogo,
  Twitter as TwitterLogo,
  // Instagram,
} from 'components/icons/brands/index';

import useCopyValue from 'utils/hooks/useCopyValue';

import ShareGallery from './ShareGallery';

const Share = ({ ripspotID, shareViewData, viewMapClick }) => {
  const [value, setValue] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [deathYear, setDeathYear] = useState('');
  const {
    handleClick,
    textareaRef,
    copySuccess,
    supportsCopy,
  } = useCopyValue();

  const { details } = shareViewData;
  const { name, birthDate, deathDate } = details;

  useEffect(() => {
    //
    // form the url that will be used as the ripspots url link for sharing
    //
    const MEMORIAL_BASE = 'https://ripspot.com/ripspot';
    const newURL = `${MEMORIAL_BASE}/${ripspotID}`; // TODO: once we finish memorial page issue, fix this, we use id for the generated pages, need to look into when the page does not exist at runtime and serve it a different way locally via fetching data
    setValue(newURL);

    //
    // form Birth and Death years
    //
    setBirthYear(birthDate.year);
    setDeathYear(deathDate.year);
  }, []);

  return (
    <Container className="SHARE-container">
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h3>{name || 'N/A'}</h3>
        <h2 style={{ marginTop: '10px', marginBottom: '15px' }}>
          {birthYear}-{deathYear}
        </h2>
        <ShareGallery shareViewData={shareViewData} />
      </div>
      <Desktop>
        <Button light onClick={viewMapClick}>
          View Map
        </Button>
      </Desktop>
      <div>
        <SocialShareWrapper>
          <ShareTitle>Share Now</ShareTitle>
          <CircleWrapper>
            <Circle>
              <FacebookLogo width="15px" height="15px" />
            </Circle>
            <Circle>
              <TwitterLogo width="15px" height="15px" />
            </Circle>
            <Circle>
              <span>SMS</span>
            </Circle>
            <Circle>
              <Mail width="15px" height="15px" />
            </Circle>
          </CircleWrapper>
        </SocialShareWrapper>
        <InputWrapper>
          <textarea ref={textareaRef} value={value} />
          <Input style={{ flex: 1 }} placeholder={value} value={value} />
          {supportsCopy && (
            <button type="button" onClick={handleClick}>
              Copy
            </button>
          )}
        </InputWrapper>
      </div>
      <Mobile>
        <Button light onClick={viewMapClick}>
          View Map
        </Button>
      </Mobile>
    </Container>
  );
};

Share.propTypes = {
  viewMapClick: PropTypes.func.isRequired,
  shareViewData: PropTypes.any.isRequired,
};

export default Share;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 15px;
  padding-left: 30px; /** this aligns left side with animated modal title */
  padding-right: 30px; /** this aligns left side with animated modal title */
`;

const CircleWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const SocialShareWrapper = styled.div`
  text-align: center;
`;

const ShareTitle = styled.h3`
  font-family: 'Montserrat', sans-serif;
`;

const Circle = styled.a`
  ${({ theme }) => theme.styles.middle}
  color: ${({ theme }) => theme.colors.midGreen};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #000;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s, border-color 0.3s;

  &:hover {
    opacity: 0.45;
    border-color: #555;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #000;

  textarea {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  input {
    outline: none;
    border: none;
    border-right: 1px solid #000;
  }

  button {
    color: ${({ theme }) => theme.colors.midGreen};
    font-size: 18px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    transition: box-shadow 0.3s, background-color 0.3s;
  }
`;
