/**
 *
 * useModalLogic
 *
 */

import { useState, useEffect, useRef } from 'react';

import useRouter from 'utils/hooks/useRouter';
import { MAP_BOX } from 'utils/constants';

/**
 *
 * useModalLogic
 *
 * e.g. const { showModal, modalIsOpen } =  useModalLogic({ isOpen })
 *
 */
export default ({ isOpen }) => {
  // if modal is mounted
  const [showModal, setShowModal] = useState(false);
  // if modal is visible (animates it in after mount)
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { location } = useRouter();
  const bodyOverflowY = useRef();

  useEffect(() => {
    /**
     * Handles mounting component before animating it in and
     * animating component out before unmounting it
     *
     */
    let timeoutA;
    let timeoutB;
    (async () => {
      if (isOpen) {
        await setShowModal(true);

        timeoutA = setTimeout(() => {
          const body = window.getComputedStyle(document.body);
          bodyOverflowY.current = body.getPropertyValue('overflow-y');

          if (location.pathname.includes(MAP_BOX)) {
            document.body.style.overflowY = 'hidden'; // targets html element
          } else {
            // this needs to be delayed to avoid race condition when toggling between login/signup modals
            document.documentElement.style.overflowY = 'hidden'; // targets html element
          }
          // wait for mount then show immediately
          setModalIsOpen(true);
          // super small delay to allow node to mount
        }, 20);
      } else {
        // bug with map versus landing, both style targets are needed
        if (location.pathname.includes(MAP_BOX)) {
          // this needs to be reset right away
          document.body.style.overflowY = bodyOverflowY.current || 'scroll'; // targets html element
        } else {
          // this needs to be reset right away
          document.documentElement.style.overflowY = 'auto'; // targets html element
        }
        await setModalIsOpen(false);
        timeoutB = setTimeout(() => {
          // animate component out, and then unmount it
          setShowModal(false);
        }, 300);
      }
    })();

    () => {
      clearTimeout(timeoutA);
      clearTimeout(timeoutB);
    };
  }, [isOpen]);

  return {
    showModal,
    modalIsOpen,
  };
};
