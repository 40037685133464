/* eslint-disable no-use-before-define */
/**
 *
 * Review Order
 *
 *
 *  A TON of code in here no longer is needed but hard to determine what, so dev caerefully!
 *
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import ReviewRipspot from 'components/ReviewRipspot';

const initialState = {
  details: {
    birthdate: {
      day: null,
      month: null,
      year: null,
    },
    deathDate: {
      day: null,
      month: null,
      year: null,
    },
  },
  bio: {},
  payment: {},
  photoGallery: {},
  review: { visibility: 'private' },
  share: {},
};

const Review = ({ getTempData }) => {
  const [data, setData] = useState(initialState);
  // TODO: Ripspot password
  // const [protectedPassword, setProtectedPassword] = useState('');

  useEffect(() => {
    const tempData = getTempData();
    setData(tempData);
  }, []);

  return data ? (
    // Existing Ripspot
    <ReviewRipspot ripspot={data} />
  ) : null;
};

Review.propTypes = {
  getTempData: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user, checkout, ripspots }) => ({
  user,
  tempPaymentInfo: checkout.tempPaymentInfo,
  submitToggle: ripspots.submitToggle,
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(Review);
//
