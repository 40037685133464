/**
 *
 * Challenge Modals Reducer
 *
 *
 */

import PropTypes from 'prop-types';

import {
  OPEN_REGISTRATION_MODAL,
  CLOSE_REGISTRATION_MODAL,
  // Ripspot
  OPEN_RIPSPOT_MODAL,
  CLOSE_RIPSPOT_MODAL,
  // Ripspot Memorial
  OPEN_RIPSPOT_MEMORIAL_MODAL,
  CLOSE_RIPSPOT_MEMORIAL_MODAL,
  // Forgot Password
  OPEN_FORGOT_PASSWORD_MODAL,
  CLOSE_FORGOT_PASSWORD_MODAL,
  // Reset Password
  OPEN_RESET_PASSWORD_MODAL,
  CLOSE_RESET_PASSWORD_MODAL,
  // Borading View
  OPEN_ON_BOARDING_MODAL,
  CLOSE_ON_BOARDING_MODAL,
  // challenge prompt modal
  OPEN_CHALLENGE_MODAL,
  CLOSE_CHALLENGE_MODAL,
  // challlenge prompt modal
  OPEN_CHALLLENGE_MODAL,
  CLOSE_CHALLLENGE_MODAL,
  // RESET_CHALLENGE_MODAL // TODO: for future dynamic challlenge modals
} from 'actions/modals';

const initialState = {
  registrationModal: {
    isOpen: false,
    registrationType: '',
    metadata: null,
  },
  ripspotModal: {
    isOpen: false,
    metadata: null,
  },
  ripspotMemorialModal: {
    isOpen: false,
    metadata: null,
    ripspotId: '', // the id of the Ripspot we want to view, needed to fetch data from our API
  },
  forgotPasswordModal: {
    isOpen: false,
  },
  passwordResetModal: {
    isOpen: false,
  },
  onBoardingModal: {
    isOpen: false,
  },
  challengeModal: {
    isOpen: false,
    prompt: '',
    metadata: {},
  },
  challlengeModal: {
    isOpen: false,
    prompt: '',
    metadata: {},
    onConfirm: PropTypes.func.isRequired,
  },
};

export default function modalsReducer(state = initialState, action) {
  switch (action.type) {
    //
    // Registration Modal
    //
    case OPEN_REGISTRATION_MODAL:
      return {
        ...state,
        registrationModal: {
          isOpen: action.isOpen,
          registrationType: action.registrationType,
          metadata: action.metadata,
        },
      };
    case CLOSE_REGISTRATION_MODAL:
      return {
        ...state,
        registrationModal: initialState.registrationModal,
      };
    //
    // Ripspot Modal
    //
    case OPEN_RIPSPOT_MODAL:
      return {
        ...state,
        ripspotModal: {
          isOpen: true,
          metadata: action.metadata,
        },
      };
    case CLOSE_RIPSPOT_MODAL:
      return {
        ...state,
        ripspotModal: {
          ...state.ripspotModal,
          isOpen: false,
        },
      };
    //
    // Ripspot Memorial Modal
    //
    case OPEN_RIPSPOT_MEMORIAL_MODAL:
      return {
        ...state,
        ripspotMemorialModal: {
          isOpen: true,
          metadata: action.metadata,
          ripspotId: action.ripspotId,
        },
      };
    case CLOSE_RIPSPOT_MEMORIAL_MODAL:
      return {
        ...state,
        ripspotMemorialModal: {
          ...state.ripspotMemorialModal,
          isOpen: false,
          ripspotId: '', // resetId
        },
      };
    //
    // Forgot Password
    //
    case OPEN_FORGOT_PASSWORD_MODAL:
      return {
        ...state,
        forgotPasswordModal: {
          isOpen: true,
        },
      };
    case CLOSE_FORGOT_PASSWORD_MODAL:
      return {
        ...state,
        forgotPasswordModal: {
          isOpen: false,
        },
      };
    //
    // Reset Password
    //
    case OPEN_RESET_PASSWORD_MODAL:
      return {
        ...state,
        passwordResetModal: {
          isOpen: true,
        },
      };
    case CLOSE_RESET_PASSWORD_MODAL:
      return {
        ...state,
        passwordResetModal: {
          isOpen: false,
        },
      };
    //
    // On Boarding Modal
    //
    case OPEN_ON_BOARDING_MODAL:
      return {
        ...state,
        onBoardingModal: {
          isOpen: true,
        },
      };
    case CLOSE_ON_BOARDING_MODAL:
      return {
        ...state,
        onBoardingModal: {
          isOpen: false,
        },
      };
    //
    // Challenge Modal
    //
    case OPEN_CHALLENGE_MODAL:
      console.log({ action });
      return {
        ...state,
        challengeModal: {
          isOpen: true,
          // prompt: action.prompt, // TODO: use for fully custom challenegeModal
          metadata: action.metadata,
          onConfirm: action.onConfirm,
          onRequestClose: action.onRequestClose,
        },
      };
    case CLOSE_CHALLENGE_MODAL:
      return {
        ...state,
        challengeModal: {
          metadata: state.metadata,
          isOpen: false,
        },
      };
    /** ---------------------------------------------------------------------------------------------- */
    // case RESET_CHALLENGE_MODAL:
    case OPEN_CHALLLENGE_MODAL:
      console.log({ action });
      return {
        ...state,
        challlengeModal: {
          isOpen: true,
          // prompt: action.prompt, // TODO: use for fully custom challenegeModal
          metadata: action.metadata,
          onConfirm: action.onConfirm,
          onRequestClose: action.onRequestClose,
        },
      };
    case CLOSE_CHALLLENGE_MODAL:
      return {
        ...state,
        challlengeModal: {
          metadata: state.metadata,
          isOpen: false,
        },
      };
    /** ---------------------------------------------------------------------------------------------- */

    default:
      return state;
  }
}
