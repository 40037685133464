/* eslint-disable no-use-before-define */
/**
 *
 * Review Handler
 *
 *
 */

import { convertToRaw } from 'draft-js';

import {
  resetRipspotPins,
  resetTempRipspot,
  setRipspotLoading,
} from 'actions/ripspots';
import { setIsPaying, resetCheckout } from 'actions/checkout';
import { setUser } from 'actions/user';

import {
  toastSuccess,
  toastUpdateSuccess,
  // toastError,
  // toastInfo,
  toastUpdate,
} from 'utils/toastify';
import { formatPinsToArray } from 'utils/helperFunctions/ripspots';

import {
  payAndUploadNewRipspot,
  updateRipspot as updateRipspotAPI,
} from './upload';

/**
 * CREATE/UPDATE Success
 *
 * Handles both create and update ripspot success
 *
 * @param {*} newRipspotData
 */
const handleSuccess = (
  newRipspotData,
  { dispatch, toastId, isNewRipspot, user, resetTempData },
) => {
  // eslint-disable-next-line no-console
  console.log('handleSuccess');
  // eslint-disable-next-line no-console
  console.log({ newRipspotData });
  // eslint-disable-next-line no-console
  console.log({ dispatch, toastId, isNewRipspot, user, resetTempData });

  resetTempData(); // clears local tempData create/edit ripspot object
  dispatch(resetTempRipspot()); // clears tempData from redux
  dispatch(resetRipspotPins()); // clears pins from map
  dispatch(resetCheckout()); // clears checkout data
  dispatch(setIsPaying(false));
  dispatch(setRipspotLoading(false));
  toastUpdateSuccess(
    toastId,
    `Successfully ${isNewRipspot ? 'created' : 'edited'} Ripspot!`,
  );

  const newUser = { ...user };

  if (isNewRipspot) {
    // add new ripspot to users ripspot array
    newUser.ripspots.push(newRipspotData);
  } else {
    newUser.ripspots = newUser.ripspots.map(ripspot => {
      if (ripspot.id === newRipspotData.id) {
        // update existing ripspot
        return newRipspotData;
      }
      return ripspot;
    });
  }

  // eslint-disable-next-line no-console
  console.log('setUser -- updates users ripspots');
  // updates users ripspots
  dispatch(setUser(newUser));
  // eslint-disable-next-line no-console
  console.log('return new data');
  // return new data
  return newRipspotData;
};

/**
 * CREATE Ripspot
 *
 * Handles creating new ripspot or updating existing ripspot
 *
 * @param {*} { isNewRipspot = true }
 * @param {*} { dispatch }
 * @param {*} { resetTempData }
 * @param {*} { ripspotData }
 * @param {*} { user }
 * @param {*} { ripspots }
 * @param {*} { tempPaymentInfo }
 * @param {*} { toastId }
 */
export const createRipspotHandler = ({
  isNewRipspot = true,
  dispatch,
  resetTempData,
  ripspotData,
  user,
  ripspots,
  tempPaymentInfo,
  toastId,
}) =>
  new Promise(async (resolve, reject) => {
    //
    // NEW Ripspot! main method
    //
    try {
      toastUpdate(toastId, 'Paying for ripspot...');
      dispatch(setIsPaying(true));
      // charges the card and creates ripspot on api
      const ripspotRes = await payAndUploadNewRipspot(
        ripspots,
        ripspotData,
        tempPaymentInfo,
        toastId,
        user.stripeCustomerId,
      );
      // eslint-disable-next-line no-console
      console.log({ handleCreateRipspotRes: ripspotRes });
      const successData = handleSuccess(ripspotRes, {
        dispatch,
        toastId,
        isNewRipspot,
        user,
        resetTempData,
      });
      return resolve(successData);
    } catch (createError) {
      // eslint-disable-next-line no-console
      console.error(createError);
      reject(createError);
      dispatch(setIsPaying(false));
    }
  });

/**
 * UPDATE ripspot
 *
 * @param {*} {
 *   dispatch,
 *   resetTempData,
 *   ripspotData,
 *   user,
 *   ripspots,
 *   tempPaymentInfo,
 *   toastId,
 * }
 */
export const updateRipspotHandler = ({
  dispatch,
  resetTempData,
  ripspotData,
  user,
  ripspots,
  tempPaymentInfo,
  toastId,
}) =>
  new Promise(async (resolve, reject) => {
    // eslint-disable-next-line no-console
    console.log('\n\n\n');
    // eslint-disable-next-line no-console
    console.log('updateRipspot');
    // eslint-disable-next-line no-console
    console.log({ ripspotData });
    // eslint-disable-next-line no-console
    console.log({ user });
    // eslint-disable-next-line no-console
    console.log('\n\n\n');

    //
    try {
      const { details, bio, review, photoGallery } = ripspotData;
      const { name, branch, isArmedServices, deathDate, birthDate } = details;
      const { visibility } = review;

      const rawContentState = convertToRaw(bio.richState.getCurrentContent());
      const formattedPins = formatPinsToArray(ripspots.pins);

      // TODO: Right now, updating with all data, only send data that is changing, need to track changed data (editTempData)
      const updateData = {
        name,
        deathDate,
        birthDate,
        biography: JSON.stringify(rawContentState),
        // photoGallery, // TODO: need to figure out how to handle this!
        // : [], // upload images asynchronously
        pins: formattedPins,
        location: {
          name: '',
          latitude: ripspotData.latitude,
          longitude: ripspotData.longitude,
        },
        isPublic: visibility && visibility === 'public',
        isActive: true,
      };

      if (isArmedServices) {
        // set military branch if applicable
        updateData.isArmedServices = true;
        updateData.branch = branch;
      } else {
        updateData.isArmedServices = false;
      }
      if (!updateData.isPublic) {
        // setRipspotPassword
        updateData.isPasswordProtected = true;
      }
      toastUpdate(toastId, 'Uploading ripspot...');

      console.log('\n\n\n');
      console.log('photoGallery');
      console.log({ photoGallery });
      console.log({ updateData });
      console.log('\n\n\n');

      const updateRes = await updateRipspotAPI(
        ripspots.currentRipspot.id,
        updateData,
      );

      // eslint-disable-next-line no-console
      console.log({ handleUpdateRipspotRes: updateRes });

      const successData = handleSuccess(updateRes, {
        dispatch,
        toastId,
        user,
        resetTempData,
        isNewRipspot: false,
      });
      return resolve(successData);
    } catch (updateError) {
      // eslint-disable-next-line no-console
      console.error(updateError);
      reject(updateError);
      dispatch(setIsPaying(false));
    }
  });

export default createRipspotHandler;
