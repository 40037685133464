/**
 *
 * Footer
 *
 *
 */

import React from 'react';
// import PropTypes from "prop-types"
import { Link } from '@reach/router';
// import PropTypes from "prop-types"
import styled from 'styled-components';

export const Policies = () => (
  <Container>
    <Link to="/faq">FAQ</Link>
    <Link to="#!">Privacy Policy</Link>
    <Link to="#!">Terms & Conditions</Link>
  </Container>
);

export default Policies;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 50%;

  ${({ theme }) => theme.media.min.mobile`
    width: 100%;
  `}

  a {
    color: #fff;
    font-size: 0.75rem;
    text-decoration: underline;
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
`;
