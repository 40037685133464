/**
 *
 * Layout
 *
 *
 */

import React, { memo, useLayoutEffect, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled, {
  ThemeProvider,
  css,
  createGlobalStyle,
} from 'styled-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Header, { HEADER_HEIGHT } from 'components/Header';
import Footer from 'components/Footer';
import AsideNav from 'containers/AsideSlider';
import LoginModal from 'components/modals/Registration/LoginModal';
import SignupModal from 'components/modals/Registration/SignupModal';
import RipspotModal from 'components/modals/RipspotModal';
import ForgotPasswordModal from 'components/modals/ForgotPasswordModal';
import ResetPasswordModal from 'components/modals/ResetPasswordModal';
// import ChallengeModal from 'components/modals/ChallengeModal';

import {
  setDimensions,
  setScrollTop,
  setIsTouchDevice,
  setIsHoverDevice,
} from 'actions/display';
import { openSignupModal } from 'actions/modals';

import Theme from 'utils/theme';
import { listenForTouch, listenForHover } from 'utils/helperFunctions/device';
import { useEventListenerDocumentBody } from 'utils/hooks/useEventListener';
import useRouter from 'utils/hooks/useRouter';
// import useResize from 'utils/hooks/useResize';
import useScrollTop from 'utils/hooks/useScrollTop';
import {
  EXPLORE_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  ACCOUNT_ROUTE,
  TERMS_ROUTE,
  FAQ_ROUTE,
  CHANGE_EMAIL_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
} from 'utils/routes';
import { hasUserAlreadySignedUpOrSeenSignup } from 'utils/localStorage';

import useClickCountState from './useClickCountState';
// import useClickCountLogic from './useClickCountLogic';

import '../styles/screen.scss';

// *
// * Number of clicks per session until a user is asked to signup. Once seen,
// * they wont see it again until they close the tab/winsdow and open a new
// * session and click around 3 times....can be changed but requires medium refactor
// *
const CLICK_COUNT = 3;
// *
// *
// *
// *
//
// these dont allow the signup to show up from clicks
//
const noClickCounterRoutes = [
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  ACCOUNT_ROUTE,
  TERMS_ROUTE,
  FAQ_ROUTE,
  CHANGE_EMAIL_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
];
// *
// *
// *
// *
// *

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100vw;
    overflow-x: hidden;

    ${({ pathname }) =>
      pathname !== '/' &&
      css`
        height: 100%;
      `}
  }

  body {
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    /* overflow-x: hidden; */
  }

  div[role="group"][tabindex] {
    height: 100%;
  }

  ${({ isMobileMap }) =>
    isMobileMap &&
    css`
      #main-container {
        ${({ theme }) => theme.media.max.mobile`
          min-height: auto !important;
        `}
      }
    `}
`;

const Modals = () => (
  <>
    <LoginModal key="login-modal" />
    <SignupModal key="signup-modal" />
    <RipspotModal key="ripspot-modal" />
    <ForgotPasswordModal key="forgot-password-modal" />
    <ResetPasswordModal key="reset-password-modal" />
    {/* <ChallengeModal key="challenge-modal" /> */}
    {/* <CustomChallengeModal key="real-challlenge-modal" /> */}
  </>
);

const Layout = ({
  children,
  dispatch,
  title = 'This is the default title',
  user,
}) => {
  const [appReady, setAppReady] = useState(false);
  const { location, ...rest } = useRouter();

  const scrollHandler = evt => {
    const _scrollTop =
      document.body.scrollTop ||
      document.scrollingElement.scrollTop ||
      document.documentElement.scrollTop;
    dispatch(setScrollTop({ scrollTop: _scrollTop }));
  };

  useLayoutEffect(() => {
    const { innerWidth, innerHeight } = window;
    dispatch(setDimensions({ width: innerWidth, height: innerHeight }));
    scrollHandler({});
  }, []);

  const { pathname } = location;
  const isHome = pathname === '/';

  const lowerCasePathname = (pathname && pathname.toLowerCase()) || '';

  useEventListenerDocumentBody('scroll', scrollHandler);

  useEffect(() => {
    listenForTouch(() => {
      // eslint-disable-next-line no-console
      console.log('is touch device!!');
      dispatch(setIsTouchDevice(true));
    });
    listenForHover(() => {
      // eslint-disable-next-line no-console
      console.log('is hover device!!');
      dispatch(setIsHoverDevice(true));
    });

    // ensure window is defined before other comp's mount
    setAppReady(true);
  }, []);

  // issue with Landing page versus Explore map scroll events.
  // Landing page, window event fires
  // Explore page, document.body event fires
  const scrollTop = useScrollTop();
  useEffect(() => {
    dispatch(setScrollTop({ scrollTop }));
  }, [scrollTop]);

  const isExploreMap = location.pathname.toLowerCase().includes(EXPLORE_ROUTE);

  // const { clickCounter, noClicks } = useClickCountLogic({ lowerCasePathname });
  const clickCounter = useClickCountState();
  const { clickCount } = clickCounter.state;

  const noClicks =
    lowerCasePathname && noClickCounterRoutes.includes(lowerCasePathname);

  useEffect(() => {
    // need to check session and localStorage
    const alreadySignedUpOrSeenSignup = hasUserAlreadySignedUpOrSeenSignup();
    // console.log({ alreadySignedUpOrSeenSignup });

    if (
      !user &&
      !alreadySignedUpOrSeenSignup &&
      !noClicks &&
      clickCount === CLICK_COUNT
    ) {
      // only show once per session
      // and if not already signed up
      dispatch(openSignupModal());
      // reset count
      clickCounter.reset();
      // modal is shown, so set sessionStorage so it does not show again until user closes window or tab
      sessionStorage.setItem('hasSeenSignup', 'true');
    } else if (noClicks) {
      clickCounter.reset();
    }
  }, [clickCount, user]);

  return (
    <ThemeProvider theme={Theme}>
      <GoogleReCaptchaProvider reCaptchaKey="6Le8Tb0UAAAAAP0vlC_pEv5i5MuyWZ9VUm1dq4-S">
        <LayoutContainer
          onClick={() => {
            // this handles showing signup modal after user clicks 3 times per session
            // need to check session and localStorage
            const alreadySignedUpOrSeenSignup = hasUserAlreadySignedUpOrSeenSignup();
            const newUser = !alreadySignedUpOrSeenSignup; // true if user is new
            const shouldCountClicks = !noClicks; // true if clicks are counted

            const isAddingClick = newUser && shouldCountClicks;

            // if user has not signed up yet and current path allows it, add to click counter
            if (isAddingClick) {
              clickCounter.addClick();
            }
          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>Ripspot | 2019</title>
            <script src="https://js.stripe.com/v3/" />
          </Helmet>
          <Header />
          <Modals />
          <AsideNav />
          <Main
            id="main-container"
            role="main"
            className="DESKTOP"
            isExploreMap={isExploreMap}
          >
            {appReady && (
              <Container isExploreMap={isExploreMap}>{children}</Container>
            )}
          </Main>
          <Footer />
          <GlobalStyle
            pathname={location.pathname}
            isMobileMap={isExploreMap}
          />
        </LayoutContainer>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

const withConnect = connect(
  ({ modals, user }) => ({ ChallenegeModal: modals.ChallenegModal, user }),
  dispatch => ({ dispatch }),
);

export default compose(memo, withConnect)(Layout);

const LayoutContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

// const MainMobile = styled.main`
//   color: #fff;
//   display: flex;
//   flex-direction: column;
// `;

const Main = styled.main`
  display: flex;
  flex-direction: column;


  ${({ isExploreMap }) =>
    isExploreMap &&
    css`
      background-color: ${({ theme }) => theme.colors.Primary};
    `}

  ${({ isExploreMap }) =>
    !isExploreMap &&
    css`
      min-height: calc(100vh - ${HEADER_HEIGHT}px);
    `}
    

  ${({ theme, isExploreMap }) =>
    isExploreMap &&
    theme.media.min.mobile`
        min-height: calc(100vh - ${HEADER_HEIGHT}px);
  `}

  ${({ theme, isExploreMap }) =>
    isExploreMap &&
    theme.media.max.mobile`
      min-height: auto;
  `}

`;

const Container = styled.div`
  ${({ theme, isExploreMap }) =>
    isExploreMap
      ? css`
          background-color: ${theme.colors.Primary};
        `
      : css`
          background-color: ${theme.colors.offWhite};
        `}

  /** Trying this Primary for bg color out so when we select a pin there is no white space issue during onboarding */
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
