/**
 *
 * Ripspot Memorial
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { stateToHTML } from 'draft-js-export-html';

import PhotoGallery from 'components/PhotoGallery';
import { getBioMarkup } from 'utils/helperFunctions/ripspots';
// import { convertNumbersToDate } from 'utils/helperFunctions/dates';
// import { toastInfo } from 'utils/toastify';

const RipspotMemorial = ({ ripspot, isPreview = false, isModal = false }) => {
  const [bioMarkup, setBioMarkup] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [deathYear, setDeathYear] = useState('');

  // some may not exist due to isPreview
  const { name, birthDate, deathDate, biography, bio, photoGallery } = ripspot;

  // // eslint-disable-next-line no-console
  // console.log('\n\n\n');
  // // eslint-disable-next-line no-console
  // console.log({ name });
  // // eslint-disable-next-line no-console
  // console.log({ ripspot });
  // // eslint-disable-next-line no-console
  // console.log({ isPreview });
  // // eslint-disable-next-line no-console
  // console.log({ bio });
  // // eslint-disable-next-line no-console
  // console.log({ biography });
  // // eslint-disable-next-line no-console
  // console.log('\n\n\n');

  useEffect(() => {
    if (biography) {
      //
      // NORMAL RIPSPOT MEMORIAL DATA!
      //
      // can be page or modal though
      //
      // // eslint-disable-next-line no-console
      // console.log({ biography });
      const markup = getBioMarkup(biography);
      // // eslint-disable-next-line no-console
      // console.log({ markup });
      setBioMarkup(markup);
    } else if (isPreview && bio && bio.richState) {
      //
      // PREVIEW!
      //
      try {
        const markup2 = stateToHTML(bio.richState.getCurrentContent()); // , options // see bottom of file below for options example
        // // eslint-disable-next-line no-console
        // console.log({ markup2 });
        setBioMarkup(markup2);
      } catch (stateToHTMLError2) {
        // eslint-disable-next-line no-console
        console.error(stateToHTMLError2);
        // eslint-disable-next-line no-console
        console.log({ stateToHTMLError2 });
      }
    }
  }, [biography, bio]);

  useEffect(() => {
    if (birthDate) {
      // // eslint-disable-next-line no-console
      // console.log({ birthDate });
      birthDate.year && setBirthYear(birthDate.year);
    }
    if (deathDate) {
      // // eslint-disable-next-line no-console
      // console.log({ deathDate });
      deathDate.year && setDeathYear(deathDate.year);
    }
  }, [birthDate, deathDate]);

  // // eslint-disable-next-line no-console
  // console.log({ isPreview });

  return (
    <Container>
      <Content isModal={isModal}>
        <Header>
          <Title>{name || 'No Name'}</Title>
          <DatesWrapper>
            <Year>{birthYear || 'N/A'}</Year>
            <Dash />
            <Year>{deathYear || 'N/A'}</Year>
          </DatesWrapper>
        </Header>
        <PhotoGallery isPreview={isPreview} photoGallery={photoGallery} />
        <Biography dangerouslySetInnerHTML={{ __html: bioMarkup }} />
      </Content>
    </Container>
  );
};

RipspotMemorial.propTypes = {
  ripspot: PropTypes.any.isRequired,
  isPreview: PropTypes.bool, // lets UI know it is coming from template OR from create ripspot process
  isModal: PropTypes.bool, // lets UI know it is being rendered within a modal, the ripspot memorial modal, opens on public pin clcisk
};

export default RipspotMemorial;

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  color: ${({ theme }) => theme.colors.darkGrey};
  /* padding: 15px; */
  width: 100%;
  max-width: ${({ theme }) => theme.media.MAX_WIDTH}px;
  margin: 0 auto;
`;

const Content = styled.div`
  ${({ theme, isModal }) => !isModal && theme.styles.boxShadows['2']}
  width: 95%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  margin-bottom: 10px;
`;

const Year = styled.span`
  font-size: 2rem;
`;

const Dash = styled.div`
  background-color: ${({ theme }) => theme.colors.darkGrey};
  height: 4px;
  width: 15px;
  margin-left: 10px;
  margin-right: 10px;
`;

// const Biography = styled.section`
//   margin-top: 25px;

//   p {
//     color: ${({ theme }) => theme.colors.Primary};
//     font-size: 1rem;
//     line-height: 1.5;

//     /* &:not(:first-of-type) {
//       margin-top: 1rem;
//     } */
//   }
//   strong {
//     font-weight: 700;
//   }
//   em {
//     font-style: italic;
//   }
// `;

const Biography = styled.section`
  max-width: ${({ theme }) => theme.media.MAX_WIDTH}px;
  width: 90%;
  padding: 15px;
  p {
    font-size: 1.6rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.Primary};
    &:not(:first-of-type) {
      margin-top: 1em;
    }
    strong {
      font-weight: 700;
    }
    em {
      font-style: italic;
    }
  }
  a {
    text-decoration: underline;
  }
  li {
    font-family: 'Averia Serif Libre', serif;
    color: ${({ theme }) => theme.colors.Primary};
    font-size: 2em;
    margin: 0.4em 0;
    li {
      font-size: 1em;
    }
  }
  ul {
    list-style: initial;
    ul {
      list-style-type: circle;
    }
  }
  ol {
    list-style: decimal;
    ol {
      list-style-type: lower-roman;
    }
  }
  ol,
  ul {
    padding-left: 10%;
    margin: 1em 0;
    ul,
    ol {
      padding-left: 5%;
      margin: 0.5em 0;
    }
  }
`;

// try {
//   const markup = stateToHTML(bio.richState);
//   // eslint-disable-next-line no-console
//   console.log({ markup });
//   setBioMarkup(markup);
// } catch (stateToHTMLError) {
//   // eslint-disable-next-line no-console
//   console.error(stateToHTMLError);
//   // eslint-disable-next-line no-console
//   console.log({ stateToHTMLError });
// }

// let options = {
//   inlineStyles: {
//     // Override default element (`strong`).
//     // BOLD: { element: 'strong', style: { bold: 'bolder' } },
//     ITALIC: {
//       // Add custom attributes. You can also use React-style `className`.
//       attributes: { class: 'foo' },
//       // Use camel-case. Units (`px`) will be added where necessary.
//       style: { fontSize: 12 },
//     },
//     // Use a custom inline style. Default element is `span`.
//     RED: { style: { color: '#900' } },
//   },
// };
