/**
 *
 * Modal
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { Portal } from 'react-portal';

import CloseIcon from 'components/icons/Close';
import { HEADER_HEIGHT } from 'components/Header';

import ModalHeader, { StyledButtonIcon, getSize } from './ModalHeader';
import useModalLogic from './useModalLogic';

/**
 * Modal
 *
 * Base modal component utilizing react-spring and react-portal
 * to break out of z-index restricted flow
 *
 * @param {boolean} { isOpen }
 * @param {function} { onRequestClose }
 * @param {node|node[]} { children }
 * @param {any} { scrollTop }
 * @param {string} { title }
 * @param {function} { renderTitle }
 * @param {boolean} { noHeader }
 * @param {number|string} { width }
 * @param {number|string} { height }
 * @param {string} { spacing }
 * @param {string} { closeIconSize }
 * @param {string} { alignItems }
 * @param {boolean} { clickOutsideClose }
 * @param {boolean} { noMinMax }
 * @param {boolean} { noMaxHeight }
 * @param {boolean} { noOverlay }
 * @param {boolean} { cover }
 *
 * @returns
 */
const Modal = ({
  isOpen,
  onRequestClose,
  children,
  scrollTop,
  title = '',
  renderTitle,
  noHeader,
  width,
  height,
  maxWidth,
  spacing,
  closeIconSize = 'md',
  alignItems = 'center',
  clickOutsideClose = false,
  noMinMax = false,
  noMaxHeight = false,
  noOverlay = false,
  cover = false,
}) => {
  // showModal: if modal is mounted
  // modalIsOpen: if modal is visible (animates it in after mount)
  const { showModal, modalIsOpen } = useModalLogic({ isOpen });

  const animationProps = useSpring({
    opacity: modalIsOpen ? 1 : 0,
  });

  const handleOutsideClick = () => {
    clickOutsideClose && onRequestClose();
  };

  return showModal ? (
    <Portal>
      <Container
        isOpen={modalIsOpen}
        cover={cover}
        noMinMax={noMinMax}
        noMaxHeight={noMaxHeight}
        noOverlay={noOverlay}
        scrollTop={scrollTop}
        alignItems={alignItems}
        spacing={spacing}
        onClick={handleOutsideClick}
      >
        <AnimatedModal
          className="animated-div"
          width={width}
          height={height}
          maxwidth={maxWidth}
          style={animationProps}
          onClick={evt => evt.stopPropagation()}
        >
          {!noHeader && (
            <ModalHeader
              title={title}
              renderTitle={renderTitle}
              onRequestClose={onRequestClose}
              closeIconSize={closeIconSize}
            />
          )}
          {noHeader && (
            <BtnWrapper>
              <StyledButtonIcon
                width={closeIconSize ? getSize(closeIconSize) : 25}
                height={closeIconSize ? getSize(closeIconSize) : 25}
                fill="rgba(100,100,100,0.85)"
                hoverFill="rgba(100,100,100,0.45)"
                Icon={CloseIcon}
                onClick={onRequestClose}
              />
            </BtnWrapper>
          )}
          <ModalContent className="modal-content">{children}</ModalContent>
        </AnimatedModal>
      </Container>
    </Portal>
  ) : null;
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  scrollTop: PropTypes.number.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  renderTitle: PropTypes.func,
  alignItems: PropTypes.string,
  closeIconSize: PropTypes.string,
  spacing: PropTypes.number,
  noHeader: PropTypes.bool,
  noMinMax: PropTypes.bool,
  noMaxHeight: PropTypes.bool,
  noOverlay: PropTypes.bool,
  cover: PropTypes.bool,
  clickOutsideClose: PropTypes.bool,
};

const mapStateToProps = ({ display }) => ({ scrollTop: display.scrollTop });
const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(Modal);

const Container = styled.div`
  ${({ noOverlay }) =>
    !noOverlay &&
    css`
      background-color: rgba(0, 0, 0, 0.5);
      background-color: ${({ theme }) => theme.colors.BlueOverlayLight};
    `}
  position: fixed;
  ${({ scrollTop }) => css`
    top: ${scrollTop > HEADER_HEIGHT ? 0 : HEADER_HEIGHT - scrollTop}px;
  `}
  ${({ theme }) => theme.media.max.mobile`
    top: 0;
  `}
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: ${({ alignItems }) => alignItems};

  ${({ spacing }) =>
    spacing &&
    css`
      margin-top: ${spacing}px;
    `};

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s ease 0.3s;
  z-index: 4;
  z-index: 100; /* greater than 99 */


  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease 0.3s, visibility 0.3s;
    `}
  /* Animated  Div styles */
  ${({ noMinMax, noMaxHeight, theme }) =>
    !noMinMax &&
    css`
      .animated-div {
        max-width: calc(100vw - 15px);
        max-height: calc(100vh - 15px);
        ${theme.media.min.tablet`
          max-width: 600px;
          ${!noMaxHeight &&
            css`
              max-height: 600px;
            `}
        `}
      }
    `}
  ${({ cover, theme }) =>
    cover &&
    theme.media.max.mobile`
      .animated-div {
        max-width: auto;
        max-height: auto;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
      }
    `}
`;

const AnimatedModal = styled(animated.div)`
  ${({ theme }) => theme.styles.boxShadows[1]};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 5px;

  ${({ maxwidth }) =>
    maxwidth &&
    css`
      &.animated-div {
        max-width: ${maxwidth};
      }
    `}

  ${({ width, theme }) => css`
    width: ${width};
    ${theme.media.max.mobile`
      width: 90%;
    `}
  `};
  ${({ height, theme }) =>
    height &&
    css`
      height: ${height};
      ${theme.media.max.mobile`
        height: 90%;
      `}
    `};
`;

const ModalContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const BtnWrapper = styled.div`
  width: 100%;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 15px;
`;
