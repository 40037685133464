/**
 *
 * Forgot Password Modal
 *
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import { closeForgotPasswordModal } from 'actions/modals';

import Modal from 'components/modals/index';
import ForgotPassword from 'containers/Auth/ForgotPassword';

import useRouter from 'utils/hooks/useRouter';
// import usePrevious from 'utils/hooks/usePrevious';
import {
  FORGOT_PASSWORD_ROUTE,
  SIGNUP_ROUTE,
  EXPLORE_ROUTE,
} from 'utils/routes';

const ForgotPasswordModal = ({ isOpen, dispatch }) => {
  const { location } = useRouter();
  // const prevPathname = usePrevious(location.pathname);

  if (isOpen && location.pathname !== FORGOT_PASSWORD_ROUTE) {
    window.history.pushState(null, null, FORGOT_PASSWORD_ROUTE);
  }

  const onRequestClose = () => {
    if (
      location.pathname !== SIGNUP_ROUTE &&
      location.pathname !== EXPLORE_ROUTE
    ) {
      // prevents closing modal after opening from login modal, when signup is the real pathname
      window.history.back(); // this might not actually be needed...
    }
    dispatch(closeForgotPasswordModal());
  };

  return (
    <Modal
      noMinMax
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      // title="Forgot Password"
      renderTitle={() => <Title>Forgot Password</Title>}
    >
      <Container>
        <ForgotPassword navigate={navigate} isModal />
      </Container>
    </Modal>
  );
};

ForgotPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ display, modals, location }) => ({
  display,
  location,
  isOpen: modals.forgotPasswordModal.isOpen,
});

const withConnect = connect(mapStateToProps);
export default compose(
  memo,
  withConnect,
)(ForgotPasswordModal);

const Container = styled.div`
  height: 100%;

  ${({ theme }) => theme.media.min.mobile`
    padding: 10px;
  `}
  ${({ theme }) => theme.media.min.tablet`
    padding: 30px 40px;
  `}
`;

const Title = styled.h2`
  font-size: 1.2rem;
`;
