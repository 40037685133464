/**
 *
 * RipspotList
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RipspotCard from './RipspotCard';

const RipspotList = ({
  ripspots,
  onViewRipspotClick,
  onEditRipspotClick,
  onDeleteRipspotClick,
  onIsActiveClick,
}) => {
  // console.log({ onDeleteRipspotClick });
  return (
    <>
      <Title>Manage Ripspots</Title>
      <List>
        {ripspots.map(ripspot => {
          return (
            <RipspotItem key={ripspot.id}>
              <RipspotCard
                ripspot={ripspot}
                onViewRipspotClick={onViewRipspotClick}
                onEditRipspotClick={onEditRipspotClick}
                onDeleteRipspotClick={onDeleteRipspotClick}
                onIsActiveClick={onIsActiveClick}
              />
            </RipspotItem>
          );
        })}
      </List>
    </>
  );
};

RipspotList.propTypes = {
  ripspots: PropTypes.arrayOf(PropTypes.any).isRequired,
  onViewRipspotClick: PropTypes.func.isRequired,
  onEditRipspotClick: PropTypes.func.isRequired,
  onDeleteRipspotClick: PropTypes.func.isRequired,
  onIsActiveClick: PropTypes.func.isRequired,
};

export default RipspotList;

const List = styled.ul`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  ${({ theme }) => theme.media.min.tablet`
    display: flex;
    flex-wrap: wrap;
  `}
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 2.2rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const RipspotItem = styled.li`
  padding: 0;
  margin-bottom: 1.2rem;
  transition: transform 0.3s;
`;
