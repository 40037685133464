/**
 *
 * Details Formik validators
 *
 *
 */

export default (values, setDisableContinue) => {
  const errors = {};

  switch (true) {
    case !values.name:
      errors.name = 'Name is required.';
      break;
    // case !values.birthDate:
    //   errors.birthDate = 'Date of Birth is required.';
    //   break;
    // case !values.deathDate:
    //   errors.deathDate = 'Date of Death is required.';
    //   break;
    default:
      if (values.isArmedServices && !values.branch.value) {
        errors.branch = 'You must specify a branch.';
      }
  }

  if (Object.keys(errors).length) {
    setDisableContinue(true);
  } else {
    setDisableContinue(false);
  }

  return errors;
};
