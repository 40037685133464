/**
 *
 * TermsPage
 *
 *
 */

import React, { memo, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import Home from 'containers/Home';
import SEO from 'components/seo';
import theme from 'utils/theme';
import { ACCOUNT_ROUTE } from 'utils/routes';

const TermsPage = () => (
  <ThemeProvider theme={theme}>
    <Container>
      <SEO title="Terms and Conditions" />
      <h1>Terms and Conditions</h1>
      <p>
        Ripspot® is a digital memorial platform designed to honor the loved ones
        of its users. We work hard to ensure that Ripspot provides a lasting
        tribute to the departed, including friends, relatives, or pets of users.
        We want you to share the content you upload and feel free to express
        yourself, but not at the expense or safety of others. You therefore
        agree to the following terms.
      </p>
      <h2>Authenticity</h2>
      <p>
        We take ownership and intellectual property very seriously. All content
        you upload, create, or share is yours and your responsibility. You
        control how it is shared. You can choose to make your content private or
        public, but in either case, you acknowledge that digital social media
        platforms are designed to share content. When you share your content
        with others, they may in turn share your content with their own networks
        — without your knowledge. For this reason, you own the content you
        upload and create, and you accept responsibility for its accuracy and
        authenticity.
      </p>
      <h2>Sharing of Content</h2>
      <p>
        We have designed our platform with sharing in mind. We allow users to
        freely express themselves — in a respectful manner — paying tribute to
        their loved ones. As such, you adhere to the terms and conditions of not
        just our platform but the platforms you choose to share your Ripspot
        content with, namely Facebook, Twitter, Instagram, and all similar
        social media platforms.
      </p>
      <h2>Reporting a Violation</h2>
      <p>
        We ask that you respect others’ intellectual property and copyrights. We
        do not allow the posting of content that violates others’ rights. If you
        feel that your rights or the rights of others have been violated, please
        contact Ripspot and bring such instances to our attention.
        <br />
        <br />
        Likewise, if you find content that is inaccurate or misrepresentative of
        an individual, please bring this to our attention by contacting us. The
        same is true if you find content that is offensive or harmful.
        <br />
        <br />
        We value the freedom of expression but recognize that some content may
        be inappropriate, harmful, or even unlawful. We reserve the right to
        remove such content and report offensive, harmful, or unlawful content
        to the appropriate law enforcement authorities.
        <br />
        <br />
        We work hard to ensure that our users have a free and safe environment
        to pay tribute to their loved ones, and we will take all necessary
        measures to maintain this. Accordingly, we reserve the right to remove a
        user’s account in its entirety without reimbursement should they violate
        these terms and conditions. We do not monitor or moderate the content as
        it is uploaded. As a social media platform, we rely on our users to
        bring violations to our attention, and we make it easy for them to
        contact us.{' '}
      </p>
      <h2>Privacy</h2>
      <p>
        You do not need an account to view a Ripspot. All Ripspot pins,
        corresponding names, and profile pictures are viewable by the general
        public, but the content of a Ripspot can be made private. Ripspots made
        private are only viewable by those granted access by their creators.
        Ripspots made public are viewable by all.
        <br />
        <br />
        Creating a Ripspot requires the creation of a user account. When you
        create a Ripspot, we ask that your username be the name you use in
        everyday life. We believe this is important for accountability and to
        help ensure the content’s authenticity.
        <br />
        <br />
        We do not rely on advertising; therefore, we do not make your account
        information and data available to anyone. We work constantly to ensure
        that your user account and information is kept private. Your user
        profile and login credentials are yours alone, and up to you to
        safeguard. You may share your information with anyone you choose, but we
        also make it easy to transfer ownership of your account to a person of
        your choosing.
      </p>
      <h2>Future Terms and Conditions</h2>
      <p>
        Our services may evolve over time, especially as technologies improve or
        become available. Likewise, our terms and conditions may evolve. While
        we will make the effort to announce changes to our terms and conditions
        to our users, it is your responsibility to adhere to the most recent
        terms and conditions.
      </p>
      <h2>Limits on Liabilities</h2>
      <p>
        To the maximum extent of applicable law, Ripspot will not be liable for
        any lost profits, exemplary, punitive, incidental, special, direct, or
        indirect damages incurred as the result of content on its platform or
        your use of the platform. All content is the responsibility of the user
        who created it. By uploading and creating content on Ripspot, you grant
        us royalty-free, non-exclusive, worldwide usage of it, insofar as you
        agree to its authenticity and adhere to our terms and conditions. To the
        extent permitted by law, we also disclaim all warranties, whether
        express or implied, including the implied warranties of merchantability,
        fitness for a particular purpose, title and non-infringement.
      </p>
      <h2>Limits on Access</h2>
      <p>
        By becoming a member of Ripspot and setting a Ripspot pin, you
        understand, acknowledge, and agree that you are provided with virtual
        visual access to the location of the pin. You also understand,
        acknowledge, and agree that said virtual visual access does not grant
        you a permission, license, or easement to access the actual physical
        location of the pin. You understand, acknowledge, and agree that pin
        locations may be located on public and/or private land, and that there
        may be no access, limited access, or restricted access to the physical
        location. Should you try to access the physical location of the pin (and
        we direct you not to do so), you understand, acknowledge, and agree that
        you may be subject to local laws of civil and/or criminal trespass. In
        the event that a landowner, municipality, or government entity
        rightfully requests that a pin be removed from a location, we reserve
        the right to remove the pin and notify you of the same. You also
        understand, acknowledge, and agree that the location of a pin may not be
        solely private, as there could be other members of Ripspot that choose
        the same pin location or a pin location adjacent to your pin location.
      </p>
    </Container>
  </ThemeProvider>
);
TermsPage.propTypes = {};

// const mapStateToProps =  props => ({});
const withConnect = connect();
export default compose(memo, withConnect)(TermsPage);

const Container = styled.div`
  color: ${theme.colors.Primary};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ theme }) => theme.styles.middle}
  max-width: 680px;
  margin: 0 auto;
  padding: 2em 1em;
  padding-bottom: 10em;
  h1 {
    color: black;
    font-size: 4.2em;
    margin-top: 2em;
  }
  h2 {
    color: black;
    margin-top: 2em;
  }
  p {
    color: black;
    margin: 1em 0;
    &:first-of-type {
      margin-top: 2.5em;
    }
  }
`;
