/**
 *
 * Card used on Landing Page for showcasing
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Card = ({
  title,
  textItems,
  imageSrc,
  SvgComp,
  bgImage,
  className,
}) => {
  const showImageWrapper = imageSrc || SvgComp;
  // console.log({ showImageWrapper });
  // console.log({ SvgComp });
  return (
    <Container
      style={{ backgroundImage: `url(${bgImage})` }}
      className={className}
    >
      <>
        {showImageWrapper && (
          <ImageWrapper>
            {imageSrc && <Image src={imageSrc} alt="" />}
            {SvgComp && <SvgComp />}
          </ImageWrapper>
        )}

        {title && <Title>{title}</Title>}

        {textItems && (
          <List>
            {textItems.map(txt => (
              <li key={txt}>
                <TextBlock>{txt}</TextBlock>
              </li>
            ))}
          </List>
        )}
      </>
    </Container>
  );
};

Card.propTypes = {
  textItems: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  imageSrc: PropTypes.any,
  SvgComp: PropTypes.any,
  bgImage: PropTypes.any,
  // imgOnly: PropTypes.bool,
  className: PropTypes.any,
};

export default Card;

const Container = styled.div`
  position: relative;
  background-size: cover;
  background-position: center center;
  /* &:not(:last-of-type) {
    margin-bottom: 35px;
  } */
  &:not(.img-only) {
    padding: 10em 0;
  }

  &.img-only:after {
    content:"";
    display: block;
    padding-bottom: 100%;
  }

  &.has-bg:after {
    content:"";
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    top: 0px;
    left: 0px;
  }

  &.two-up {
    margin: 2em 0;
  }

  ${({ theme }) => theme.media.min.mobile`
    &:not(:last-of-type) {
      margin-bottom: 0px;
    }
    width: 33.333333%;
    &.two-up {
      width: 50%;
      margin: 0 1.5em;
    }
  `}
  /* &:hover {
    ${({ theme }) => theme.styles.boxShadows['2']}

    li, h4 {
      transition: color 0.3s;
      color: ${({ theme }) => theme.colors.Primary};
    }
  } */
`;

const ImageWrapper = styled.div`
  ${({ theme }) => theme.styles.middle}
  height: 120px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  z-index: 2;
  svg {
    fill: ${({ theme }) => theme.colors.Primary};
    width: 7em;
    height: 7em;
  }
`;

const Title = styled.h4`
  font-family: 'Libre Baskerville', serif;
  font-size: 2.5rem;
  font-weight: bolder;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #fff;
  position: relative;
  z-index: 2;
`;

const TextBlock = styled.p`
  margin-bottom: 15px;
  color: #fff;
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 2.4em;
  font-weight: 700;
`;

const Image = styled.img`
  width: auto;
  height: 100%;
`;

const List = styled.ul`
  padding: 20px;
  list-style: none;

  @media screen and (min-width: 576px) and (max-width: 768px) {
    width: 75%;
    margin: 0 auto;
  }
  ${({ theme }) => theme.media.min.tablet`
    padding: 20px 15px 0px;
  `}

  li {
    position: relative;
  }
`;
