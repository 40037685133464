/**
 *
 * SearchInput
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Elements/Button';
import StyledInput from './Base';

const SearchInput = ({
  type = 'text',
  onChange,
  value,
  searchText = '',
  onClick,
  ...props
}) => {
  return (
    <SearchInputWrapper>
      <StyledInput
        className="search-input"
        type={type}
        onChange={onChange}
        value={value}
        {...props}
      />
      <Button onClick={onClick}>{searchText}</Button>
    </SearchInputWrapper>
  );
};

SearchInput.propTypes = {
  type: PropTypes.string,
  searchText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default SearchInput;

const SearchInputWrapper = styled.div`
  display: flex;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
  transition: border-color 0.3s;
  &:active,
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.midGrey};
    button {
      border-left-color: ${({ theme }) => theme.colors.midGrey};
    }
  }
  input.search-input {
    flex: 4;
    min-height: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: none !important;
  }
  button {
    background-color: #f8f9f5;
    flex: 1;
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border: none;
    border-left: 1px solid ${({ theme }) => theme.colors.darkGrey};
    box-shadow: none;
    transition: background-color 0.3s, border-color 0.3s;
    &:hover {
      box-shadow: none;
      background-color: #f1f9ec;
    }
  }
`;
