/**
 *
 * 404 (Not Found) Page
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SEO from 'components/seo';
import RipspotWrapper from './RipspotWrapper';

// id = gatsby-focus-wrapper

const parentId = 'gatsby-focus-wrapper';

const NotFoundPage = props => {
  // eslint-disable-next-line no-console
  console.log('\n\n\n\n');
  console.log({ NotFoundPageProps: props });
  console.log('\n\n\n\n');
  if (props.location.pathname.includes('/ripspot/')) {
    return (
      <div>
        <RipspotWrapper title="Ripspot" />
      </div>
    );
  }
  return <Container>Sorry, Page does not exist:</Container>;
};

NotFoundPage.propTypes = {
  location: PropTypes.any,
};
export default NotFoundPage;

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: orange;
`;
