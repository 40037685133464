/**
 *
 * PinsListItem
 *
 */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import icons from 'components/lib/Mapbox/utils/icons';
import ButtonIcon from 'components/Elements/ButtonIcon';

import Theme from 'utils/theme';

const { colors } = Theme;

const PinsListItem = ({
  currentPin,
  name,
  displayName,
  image,
  setIsArmedServices,
  setCurrentPin,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Item key={name} active={currentPin === name}>
      <NamePopup onMouseOut={evt => setIsOpen(false)} isOpen={isOpen}>
        {displayName}
      </NamePopup>
      <StyledButtonIcon
        onMouseOver={evt => setIsOpen(true)}
        onMouseOut={evt => setIsOpen(false)}
        Icon={() => <Image src={image} alt={name} title={displayName} />}
        onClick={() => {
          if (name === 'USVertan') {
            setIsArmedServices(true);
          } else {
            setIsArmedServices(false);
          }
          setCurrentPin(name);
        }}
      />
    </Item>
  );
};

PinsListItem.propTypes = {
  currentPin: PropTypes.string.isRequired,
  setCurrentPin: PropTypes.func.isRequired,
  setIsArmedServices: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
};

export default memo(PinsListItem);

const Item = styled.li`
  ${({ theme }) => theme.styles.middle}
  position: relative;
  width: 12.5%;
  padding: 5px 0px;
  transition: background-color 0.3s, transform 0.3s, border-color 0.3s;
  border: 1px solid transparent;
  ${({ active }) =>
    active &&
    css`
      border-radius: 5px;
      border-color: ${colors.alphaMod(colors.Primary, 0.3)};
      transform: translate3d(0px, -3px, 0px);
    `}

  &:hover {
    transform: translate3d(0px, -3px, 0px);
  }
`;

const StyledButtonIcon = styled(ButtonIcon)`
  max-width: 45px; /* This controls icon size */
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const NamePopup = styled.div`
  background-color: #fff;
  font-family: 'Libre Baskerville', serif;
  position: absolute;
  top: -50%;
  left: 0;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
  pointer-events: none;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  ${({ theme }) => theme.styles.boxShadows['3']};
  ${({ isOpen, theme }) => theme.styles.showhideStyles(isOpen)}
`;
