/**
 *
 * Login
 *
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import useRouter from 'utils/hooks/useRouter';
import { sendConfirmAccountLink } from 'utils/api/auth';
import { toastSuccess, toastError } from 'utils/toastify';

import LoginFormik from './LoginFormik';
import { handleLoginLogic, ERROR_MSG } from './loginLogic';

const Login = ({ dispatch, handleRedirect, closeModal, isModal = false }) => {
  const [rememberMe, setRememerMe] = useState('');
  const [email, setEmail] = useState('');
  const [serverError, setServerError] = useState(null);
  const { location, navigate } = useRouter();

  useEffect(() => {
    const rememberMeLocal = localStorage.getItem('rememberMe');
    if (rememberMeLocal !== '') {
      setEmail(rememberMeLocal);
    }
    setRememerMe(rememberMeLocal);
  }, []);

  useEffect(() => {
    // grabs email from state after a user registers and is redirected to login
    // to new account with provided email
    if (location.state && 'email' in location.state) {
      setEmail(location.state.email);
    }
  }, [location.pathname]);

  /**
   *
   *
   * @param {string} userEmail
   */
  const handleResendConfirmCode = async userEmail => {
    // fire off another confirm email
    try {
      const emailRes = await sendConfirmAccountLink(userEmail);
      if (emailRes && 'id' in emailRes) {
        toastSuccess('Confirm account email re-sent!');
      }
    } catch (err) {
      toastError(ERROR_MSG);
    }
  };

  return (
    <Container isModal={isModal}>
      {!isModal && <Title>Login</Title>}
      <LoginFormik
        serverError={serverError}
        handleRedirect={handleRedirect}
        handleResendConfirmCode={handleResendConfirmCode}
        initialValues={{
          email, // rememberMe handles email only, we can ask them if they want to stay logged in later, but for now user is always logged in until token times out
          password: 'Passwordpass9292!', // this only comes from browser autofill / 3rd party plugins
          rememberMe, // actual checkboc for rememberMe --> comes from localStorage
        }}
        onSubmit={handleLoginLogic({
          setServerError,
          closeModal,
          navigate,
          dispatch,
        })}
      />
    </Container>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func,
  closeModal: PropTypes.func,
  isModal: PropTypes.bool,
};

// const mapStateToProps = state => ({});
const withConnect = connect(null, dispatch => ({ dispatch }));
export default compose(withConnect)(Login);

const Container = styled.div`
  width: 100%;

  ${({ isModal }) =>
    !isModal &&
    css`
      max-width: 500px;
      margin: 0 auto;
      margin-top: 25px;
    `}
`;

const Title = styled.h2`
  color: #000;
  margin: 0;
  margin-left: 15px;
  margin-top: 25px;
`;
