/**
 *
 * Store
 *
 *
 */

import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import authReducer from 'reducers/auth';
import displayReducer from 'reducers/display';
import userReducer from 'reducers/user';
import modalsReducer from 'reducers/modals';
import ripspotsReducer from 'reducers/ripspots';
import checkoutReducer from 'reducers/checkout';
import stripeReducer from 'reducers/stripe';

import { SET_DIMENSIONS, SET_SCROLL_TOP } from 'actions/display';

const isDev = process.env.GATSBY_LAMBDA !== 'production';

const logger = createLogger({
  predicate: (getState, action) =>
    action.type !== SET_DIMENSIONS && action.type !== SET_SCROLL_TOP,
});

// remove for builds

const rootReducer = {
  auth: authReducer,
  display: displayReducer,
  modals: modalsReducer,
  user: userReducer,
  ripspots: ripspotsReducer,
  checkout: checkoutReducer,
  stripe: stripeReducer,
};

const wrapper = () =>
  createStore(
    combineReducers(rootReducer),
    compose(applyMiddleware(thunk, logger)), // logger --> add 'logger' at end of list to add redux action logs to console
  );

// const wrapperWithReduxTools = () => {
//   const DEV_TOOLS =
//     isDev &&
//     typeof window !== 'undefined' &&
//     window.__REDUX_DEVTOOLS_EXTENSION__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION__();

//   return createStore(
//     combineReducers(rootReducer),
//     compose(applyMiddleware(thunk, logger), DEV_TOOLS),
//   );
// };

export default wrapper; //  isDev ? wrapperWithReduxTools : wrapper;
