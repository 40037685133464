/**
 *
 * SlickSliderV2
 *
 *
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { slickDots } from './styles';
import { NextArrow, PrevArrow } from './arrows';

const defaultSettings = {
  dots: false,
  arrows: true,
  // issue/bug with centerMode=true and infinite=false
  infinite: true, // this NEEDS to be TRUE until the lib fixes an issue with centeRMode and non-infinite slides --> requires a patch via patch-package ==> https://github.com/akiran/react-slick/issues/1716
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  // centerMode: true,
  rows: 1,
  initialSlide: 0,
  nextArrow: <NextArrow isInfinite />,
  prevArrow: <PrevArrow isInfinite />,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800, // 700 - 800 px
      settings: {
        slidesToShow: 1.5, // This needs to be 1.5!!!
        slidesToScroll: 1,
        centerPadding: 80, // this is needed to center active slide and only show part of the prev and next slides
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      },
    },
  ],
};

/**
 * SlickSliderV2
 *
 * @param {*} { slides, renderSlide, setRef, settings = {} }
 * @returns
 */
const SlickSliderV2 = ({
  slides,
  renderSlide,
  setRef,
  settings = {},
  minHeight,
}) => {
  const slider = useRef();

  if (setRef && slider.current) {
    // this allows us to grab slider instance ref from parent
    setRef.current = slider.current;
  }

  const finalSettings = {
    ...defaultSettings,
    ...settings,
  };

  return (
    <StyledSlider ref={slider} minHeight={minHeight} {...finalSettings}>
      {slides &&
        slides.map((slide, index) => {
          if (renderSlide) {
            return renderSlide(slide, index);
          }
          return (
            <div key={index}>
              <h3>{index}</h3>
            </div>
          );
        })}
    </StyledSlider>
  );
};

SlickSliderV2.propTypes = {
  settings: PropTypes.any,
  setRef: PropTypes.any,
  minHeight: PropTypes.number,
  renderSlide: PropTypes.func,
  slides: PropTypes.arrayOf(PropTypes.any),
};

export default SlickSliderV2;

// default slider height, sets slide and slider container minheights
const SLIDER_HEIGHT = 180;

const StyledSlider = styled(Slider)`
  height: ${SLIDER_HEIGHT}px;
  width: 100%;

  .slick-track {
    height: 100%;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  ${slickDots}

  .slick-slide {
    width: 150px;
    height: 150px;
    /* overflow: hidden; */
  }

  .slick-center {
    transform: scale(1.25);
    transition: transform 0.35s;
  }
`;

// responsive: [
//   {
//     breakpoint: 1100,
//     settings: {
//       slidesToShow: 3,
//       slidesToScroll: 1,
//     },
//   },
//   {
//     breakpoint: 900,
//     settings: {
//       slidesToShow: 2.2,
//       slidesToScroll: 1,
//     },
//   },
//   {
//     breakpoint: 600,
//     settings: {
//       slidesToShow: 1.2,
//       slidesToScroll: 1,
//     },
//   },
//   {
//     breakpoint: 480,
//     settings: {
//       slidesToShow: 1.1,
//       slidesToScroll: 1,
//       centerMode: true,
//     },
//   },
// ],
