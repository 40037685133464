/**
 *
 *
 * LocalStorage Helper functions
 *
 *
 *  */

export const hydrateFromLocalStorage = () => {
  const rememberMe = localStorsage.getItem('rememberMe');
  const ripspotAuthToken = localStorage.getItem('ripsot-auth-token');

  return {
    rememberMe,
    ripspotAuthToken,
  };
};

export const hasUserAlreadySignedUpOrSeenSignup = () => {
  const localHasSignedUp = localStorage.getItem('hasSignedUp');
  const sessionHasSeenSignup = sessionStorage.getItem('hasSeenSignup');

  const alreadySignedUpOrSeenSignup =
    localHasSignedUp === 'true' || sessionHasSeenSignup === 'true';

  return alreadySignedUpOrSeenSignup;
};
