// import qs from 'qs';

export const normalizeResponseErrors = res => {
  if (!res.ok) {
    if (
      res.headers.has('content-type') &&
      res.headers.get('content-type').startsWith('application/json')
    ) {
      // It's a nice JSON error returned by us, so decode it
      return res.json().then(err => Promise.reject(err));
    }
    // It's a less informative error returned by express
    const error = {
      code: res.status,
      message: res.statusText,
    };
    return Promise.reject(error);
  }
  return res;
};

// console.log({
//   stringify: qs.stringify({
//     name: 'mike',
//     startTime: 12345566554,
//     endTime: 12345566554,
//   }),
// });

/**
 * formQuery
 *
 * Takes object of query key/value pairs
 * and converts them to query string
 *
 * @param {*} opts
 * @returns
 */
export const formQuery = opts => {
  // const options = {
  //   limit: 30,
  //   page: 0,
  // };
  let query = '';
  Object.keys(opts).forEach(key => {
    query = `${query}${key}=${opts[key]}`;
  });

  return query;
};
