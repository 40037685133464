/**
 *
 * OnBoarding Modal
 *
 * */

import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Elements/Button';
import Modal from 'components/modals/index';

const OnBoardingModal = ({ isOpen, onRequestClose }) => {
  // const { location } = useRouter();
  const [showSecondStep, setShowSecondStep] = useState(false);

  useEffect(() => {
    // set(v => !v);
    if (!isOpen) {
      // eslint-disable-next-line no-console
      console.log('resetting modal step state');
      setShowSecondStep(false);
    }
  }, [isOpen]);

  return (
    <Modal
      cover
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      renderTitle={() =>
        !isOpen ? (
          <span />
        ) : (
          <ModalTitle>
            {showSecondStep
              ? 'First, find a location'
              : 'Getting started is easy.'}
          </ModalTitle>
        )
      }
      // title=''
      width="calc(100% - 30px)"
      // height={`${heightToUse}px`}
      minHeight="400px"
    >
      <Container>
        {(isOpen &&
          (showSecondStep ? (
            <Content>
              <Prompt>
                Zoom into and drag the map to find the location of a
                meaningful place. You can switch to satellite view to help find
                that special spot. Once located, click where you’d like to drop
                the Ripspot pin. You’ll then be able to create the biography and
                build a photo gallery for your loved one.
              </Prompt>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '15px',
                }}
              >
                <Button
                  primary
                  onClick={evt => {
                    onRequestClose();
                  }}
                >
                  Begin
                </Button>
              </div>
            </Content>
          ) : (
            <Content>
              <Prompt>
              We will guide you through a few simple steps to celebrate a loved one, beginning with choosing the location(s) of their Ripspot. You’ll then be able to write their biography and build a photo gallery. You’ll always be able to edit or add to a Ripspot, even after you’ve shared it with others.
              </Prompt>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '15px',
                }}
              >
                <Button
                  primary
                  onClick={evt => {
                    setShowSecondStep(true);
                  }}
                >
                  Continue
                </Button>
              </div>
            </Content>
          ))) || <span />}
      </Container>
    </Modal>
  );
};

OnBoardingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

const mapStateToProps = ({ display, modals }) => ({
  display,
  isOpen: modals.onBoardingModal.isOpen,
});

const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(OnBoardingModal);

const Container = styled.div`
  height: 100%;
  padding: 15px;
  padding-left: 25px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 5px;
`;

const Prompt = styled.p`
  margin: 0;
  padding: 5px;
  font-size: 1.1rem;
`;

const ModalTitle = styled.h2`
  font-size: 1.8rem;
  margin-left: 15px;
`;

// const transitions = useTransition(showSecondStep && isOpen, key => key, {
//   from: {
//     position: 'absolute',
//     opacity: 0,
//     transform: 'translate3d(20px, 0, 0)',
//   },
//   enter: { opacity: 1, transform: 'translate3d(-20px, 0, 0)' },
//   leave: item => ({
//     opacity: 0,
//     transform: `translate3d(${-20}px, 0, 0)`,
//   }),
// });
