/**
 * Stripe Payment HTTP method calls
 *
 *
 */

import { apiPOST } from '../index';

import { PAYMENTS, PAYMENT_INTENT, CREATE_SUBSCRIPTION } from '../urls';

const isDev = process.env.NODE_ENV !== 'production';

export const chargeCard = ({ tokenId, amount }) =>
  new Promise((resolve, reject) => {
    apiPOST({ url: PAYMENTS, data: { tokenId, amount } })
      .then(chargeCardRes => {
        // eslint-disable-next-line no-console
        console.log({ chargeCardRes });
        resolve(chargeCardRes);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
        reject(err);
      });
  });

// export const getPaymentIntent = ({ amount }) =>
//   new Promise((resolve, reject) => {
//     apiPOST({ url: PAYMENT_INTENT, data: { amount } })
//       .then(chargeCardRes => {
//         // eslint-disable-next-line no-console
//         console.log({ chargeCardRes });
//         resolve(chargeCardRes);
//       })
//       .catch(err => {
//         // eslint-disable-next-line no-console
//         console.error(err);
//         reject(err);
//       });
//   });

export const createPaymentIntent = ({ amount }) =>
  new Promise((resolve, reject) => {
    apiPOST({ url: PAYMENT_INTENT, data: { amount } })
      .then(chargeCardRes => {
        // eslint-disable-next-line no-console
        console.log({ chargeCardRes });
        resolve(chargeCardRes);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
        reject(err);
      });
  });

export const createSubscription = ({ customerId, paymentMethodId, priceId }) =>
  new Promise((resolve, reject) => {
    apiPOST({
      url: CREATE_SUBSCRIPTION,
      data: { customerId, paymentMethodId, priceId },
    })
      .then(createSubscriptionRes => {
        // eslint-disable-next-line no-console
        console.log({ createSubscriptionRes });

        if (createSubscriptionRes.error) {
          throw createSubscriptionRes;
        }

        return resolve(createSubscriptionRes);
      })
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('MADE IT TO SECOND THEN!!!!');
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
        reject(err);
      });
  });
