/**
 *
 * Ripspot Template Page
 *
 */

import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RipspotMemorial from 'components/RipspotMemorial';

import { getRipspotById } from 'utils/api/ripspots';
import {
  // toastSuccess,
  toastUpdateSuccess,
  // toastError,
  toastUpdateError,
  // toastWarn,
  toastInfo,
} from 'utils/toastify';

/**
 *  RipspotTemplate
 *
 * @param {*} { ripspotId }
 * @returns
 */
const RipspotTemplate = ({ ripspotId, ...rest }) => {
  const [ripspot, setRipspot] = useState({});

  // eslint-disable-next-line no-console
  console.log({ ripspotId });
  // eslint-disable-next-line no-console
  console.log({ ripspot });
  // eslint-disable-next-line no-console
  console.log({ rest });

  useEffect(() => {
    let toastId;
    // fetch ripspot data
    if (ripspotId) {
      (async () => {
        try {
          toastId = toastInfo('Loading ripspot...');
          const res = await getRipspotById(ripspotId);
          // eslint-disable-next-line no-console
          console.log({ res });
          toastUpdateSuccess(toastId, 'Success! ripspot data fetched');
          setRipspot(res);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log({ error });
          console.log('ditty');
          toastUpdateError(toastId, error.message || error.reason);
        }
      })();
    }
  }, [ripspotId]);

  const hasData = Object.keys(ripspot).length;

  // eslint-disable-next-line no-console
  console.log({ hasData });

  return (
    <Container isOpen={hasData}>
      <RipspotMemorial ripspot={ripspot} />
    </Container>
  );
};

RipspotTemplate.propTypes = {
  ripspotId: PropTypes.string.isRequired,
};

export default memo(RipspotTemplate);

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  color: ${({ theme }) => theme.colors.Primary};
`;
