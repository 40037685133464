/**
 *
 * CheckoutFormStripe
 *
 * * This is a working version!!! utlizes the new @stripe/ lib
 *
 *  */

import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import Button from 'components/Elements/Button';

// import CardSection from './CardSection';
import useResponsiveFontSize from './useResponsiveFontSize';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  );

  return options;
};

/**
 * CheckoutFormStripe
 *
 * @param {*} props
 * @returns
 */
export const CheckoutFormStripe = ({
  onHandlePayment,
  onHandleError,
  completePurchase,
  setDisableContinue,
}) => {
  const [localState, setLocalState] = useState({
    nameOnCard: '',
    zipcode: '',
    // stripe
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async evt => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    evt && evt.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      // eslint-disable-next-line no-console
      console.log('not loaded, returning');
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    let chargeToken;
    try {
      const { token } = await stripe.createToken(cardElement);
      chargeToken = token;
      console.log('\n\n\n\n');
      console.log('============================');
      console.log({ token });
      console.log('============================');
      console.log('\n\n\n\n');
    } catch (tokenError) {
      console.log('\n\n\n\n');
      console.log('============================');
      console.log({ tokenError });
      console.log('============================');
      console.log('\n\n\n\n');
    }

    // const { token } = await stripe.createToken({
    //   name: localState.nameOnCard,
    //   address_zip: localState.zipcode,
    // });

    // // eslint-disable-next-line no-console
    // console.log({ token });
    // eslint-disable-next-line no-console
    console.log({ error, paymentMethod, chargeToken, cardElement });
    if (error) {
      // eslint-disable-next-line no-console
      console.log('[createPaymentMethod error]', error);
      onHandleError(error);
    } else {
      // good to go!
      // eslint-disable-next-line no-console
      console.log('[PaymentMethod]', paymentMethod);
      onHandlePayment(paymentMethod, chargeToken);
    }
  };

  useEffect(() => {
    if (completePurchase) {
      // // eslint-disable-next-line
      // alert('Submitting form!!!');
      // eslint-disable-next-line no-console
      console.log('Submitting form!!!');
      handleSubmit(); // we dont need to pass an event object since we trigger this manually
    }
  }, [completePurchase]);

  //
  // grab values from state to determine if form is filled out or not
  //
  const { nameOnCard, zipcode, cardNumber, cardExpiry, cardCvc } = localState;

  const validateFields = () => {
    // eslint-disable-next-line no-console
    console.log('validateFields');
    if (nameOnCard && zipcode && cardNumber && cardExpiry && cardCvc) {
      setDisableContinue(false);
    } else {
      setDisableContinue(true);
    }
  };

  useEffect(() => {
    // validate form on all changes
    validateFields();
  }, [nameOnCard, zipcode, cardNumber, cardExpiry, cardCvc]);

  //
  //
  // TODO: feed initial values into these special components
  //
  //
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FieldWrapper>
          <label>
            <span>Name On Card</span>
            <Input
              value={localState.nameOnCard}
              onChange={evt => {
                setLocalState({
                  ...localState,
                  nameOnCard: evt.target.value,
                });
              }}
              name="nameOnCard"
              type="text"
              placeholder="Your Name Here..."
              className="StripeElement"
              required
            />
          </label>
        </FieldWrapper>
        <FieldWrapper>
          <label>
            <span>Card number</span>
            <CardNumberElement
              options={options}
              onReady={() => {
                // eslint-disable-next-line no-console
                // console.log('CardNumberElement [ready]');
              }}
              onChange={event => {
                // eslint-disable-next-line no-console
                // console.log('CardNumberElement [change]', event);
                if (event.complete) {
                  setLocalState({
                    ...localState,
                    cardNumber: true,
                  });
                }
              }}
              onBlur={() => {
                // eslint-disable-next-line no-console
                // console.log('CardNumberElement [blur]');
              }}
              onFocus={() => {
                // eslint-disable-next-line no-console
                // console.log('CardNumberElement [focus]');
              }}
            />
          </label>
        </FieldWrapper>
        <FieldWrapper>
          <label>
            <span>Expiration date</span>
            <CardExpiryElement
              options={options}
              onReady={() => {
                // eslint-disable-next-line no-console
                // console.log('CardExpiryElement [ready]');
              }}
              onChange={event => {
                // eslint-disable-next-line no-console
                // console.log('CardExpiryElement [change]', event);
                if (event.complete) {
                  setLocalState({
                    ...localState,
                    cardExpiry: true,
                  });
                }
              }}
              onBlur={() => {
                // eslint-disable-next-line no-console
                // console.log('CardExpiryElement [blur]');
              }}
              onFocus={() => {
                // eslint-disable-next-line no-console
                // console.log('CardExpiryElement [focus]');
              }}
            />
          </label>
        </FieldWrapper>
        <FieldWrapper>
          <label>
            <span>CVC</span>
            <CardCvcElement
              options={options}
              onReady={() => {
                // eslint-disable-next-line no-console
                // console.log('CardCvcElement [ready]');
              }}
              onChange={event => {
                // eslint-disable-next-line no-console
                // console.log('CardCvcElement [change]', event);
                if (event.complete) {
                  setLocalState({
                    ...localState,
                    cardCvc: true,
                  });
                }
              }}
              onBlur={() => {
                // eslint-disable-next-line no-console
                // console.log('CardCvcElement [blur]');
              }}
              onFocus={() => {
                // eslint-disable-next-line no-console
                // console.log('CardCvcElement [focus]');
              }}
            />
          </label>
        </FieldWrapper>
        <FieldWrapper>
          <label>
            <span>Zipcode</span>
            <Input
              value={localState.zipcode}
              onChange={evt => {
                setLocalState({
                  ...localState,
                  zipcode: evt.target.value,
                });
              }}
              name="zipcode"
              type="text"
              placeholder="Zipcode"
              className="StripeElement"
              required
            />
          </label>
        </FieldWrapper>
        {/* <Button type="submit" disabled={!stripe}>
          Pay
        </Button> */}
      </form>
    </div>
  );
};

CheckoutFormStripe.propTypes = {
  onHandlePayment: PropTypes.func.isRequired,
  onHandleError: PropTypes.func.isRequired,
  completePurchase: PropTypes.bool.isRequired,
  setDisableContinue: PropTypes.func.isRequired,
};

export default CheckoutFormStripe;

const FieldWrapper = styled.div`
  margin-bottom: 25px;
  padding: 10px 5px;
  label {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    span {
      display: inline-block;
      padding-bottom: 5px;
    }
  }
  .StripeElement {
    padding: 8px;
    line-height: 2.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const Input = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
`;

// const BottomFields = styled.div`
//   display: flex;
//   flex-wrap: wrap;

//   > div {
//     /* flex: 1; */
//     width: 50%;
//   }
// `;
