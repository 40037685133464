/**
 *
 * React Select Wrapper
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Theme from 'utils/theme';

const { colors } = Theme;

const defaultOptions = [
  { value: 'army', label: 'Chocolate' },
  { value: 'navy', label: 'Navy' },
  { value: 'airForce', label: 'Air Force' },
  { value: 'marines', label: 'Marines' },
];

const ReactSelect = ({
  value,
  handleChange,
  closeMenuOnScroll = false,
  portal = false,
  menuPortalTarget = document.body,
  options = defaultOptions,
  styles = {},
  ...props
}) => (
  <Select
    classNamePrefix="react-select"
    closeMenuOnScroll={closeMenuOnScroll}
    menuPortalTarget={portal && menuPortalTarget}
    styles={{
      indicatorSeparator: () => ({ opacity: 0 }),
      menuPortal: base => ({ ...base, zIndex: 9999 }),
      control: base => ({
        ...base,
        // ...styles.control,
        // boxShadow: 'none',
        // You can also use state.isFocused to conditionally style based on the focus state
      }),
      option: (provided, state) => {
        return {
          ...provided,
          color: state.isSelected ? colors.lightGreen : '#000',
          backgroundColor: state.isSelected
            ? 'rgba(100, 100, 100, 0.2)'
            : 'rgba(100, 100, 100, 0.05)',
        };
      },
      ...styles,
    }}
    value={value}
    onChange={handleChange}
    options={options}
    {...props}
  />
);

ReactSelect.propTypes = {
  value: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  closeMenuOnScroll: PropTypes.bool,
  menuPortalTarget: PropTypes.node,
  styles: PropTypes.any,
  portal: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ReactSelect;
