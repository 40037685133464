import { css } from 'styled-components';

export const slickDots = css`
  .slick-dots {
    bottom: auto;
    top: 0;
    font-size: 1rem;

    li {
      width: 16px;
      height: 20px;
      &.slick-active {
        width: 20px; /* provides some breathing room to right side of selected dot */
      }
    }

    button {
      width: 8px;
      height: 8px;
      cursor: pointer;
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border: 3px solid lightblue;
        opacity: 0.8;
        border-radius: 50%;
        font-size: 1rem;
      }
    }

    li.slick-active {
      button {
        width: 15px;
        height: 15px;
        &::before {
          width: 15px;
          height: 15px;
          background-color: ${({ theme }) => theme.colors.lightGreen};
          border-color: ${({ theme }) => theme.colors.lightGreen};
        }
      }
    }
  }
`;
