/**
 *
 * Account
 *
 *
 */

/* eslint-disable no-use-before-define */
import React, { useEffect, useState, memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import Button from 'components/Elements/Button';
import CustomChallengeModal from 'components/modals/ChallengeModal';

import { hydrateRipspot, hydrateTempData } from 'actions/ripspots';
import { setUser } from 'actions/user';
import {
  // openChallengeModal,
  openChalllengeModal,
  // closeChallengeModal,
  closeChalllengeModal,
} from 'actions/modals';

import { CHANGE_PASSWORD_ROUTE, CHANGE_EMAIL_ROUTE } from 'utils/routes';
import {
  toastSuccess,
  toastUpdateSuccess,
  toastInfo,
  toastWarn,
  // toastUpdateError,
} from 'utils/toastify';
// import { populateRipspotBioMarkup } from 'utils/helperFunctions/ripspots';

import { deleteRipspot, updateRipspot } from 'utils/api/ripspots';

import RipspotList from './RipspotList';
import { useGetRipspotsLogic } from './utils';
// import DangerZone from './DangerZone';

/**
 * User's Account
 *
 * @param {*} { dispatch, user }
 * @returns
 */
export const Account = ({ dispatch, user, modal }) => {
  const [isReady, setIsReady] = useState(false);
  // const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);

  // converts rawDraftJS data to HTML string
  const { ripspots } = useGetRipspotsLogic({ user });

  /**
   * Click to view a specific ripspot handler
   *
   *
   * @param {*} ripspot - ripspot user wants to VIEW
   */
  const onViewRipspotClick = ripspot => evt => {
    //
    // 1. hydrate ripspot (puts data in state for pins and edit info)
    dispatch(hydrateRipspot(ripspot));
    // 2. navigate to map
    // set bounding view box around all pins
    navigate('/search', {
      state: {
        shouldBoundPins: true,
        viewRipspot: true,
      },
    });
  };

  /**
   * Edit ripspot click handler
   *
   * @param {*} editingRipspot - ripspot user wants to EDIT
   */
  const onEditRipspotClick = editingRipspot => evt => {
    //
    // 1. hydrate ripspot (puts data in state for pins and edit info)
    dispatch(hydrateRipspot(editingRipspot));
    dispatch(hydrateTempData(editingRipspot));
    // 2. navigate to map
    // sets bounding view box around all pins
    // open create/edit modal on top of map (lets map know we are in edit modality)
    navigate('/search', {
      state: {
        isEditMode: true,
        isInExistingPinEditMode: true,
      },
    });
  };

  /**
   *  Confirmation handler for deleting a Ripspot
   *
   * @param {*} currentRipspot
   */
  const confirmnationHandler = async currentRipspot => {
    //
    const toastId = toastInfo('Deleteing Ripspot...');

    // eslint-disable-next-line no-console
    console.log('DELETING.....', { currentRipspot });
    // eslint-disable-next-line no-console
    console.log({ currentRipspot });

    //
    let deleteResponse;
    try {
      deleteResponse = await deleteRipspot(currentRipspot.id);
    } catch (deleteError) {
      // eslint-disable-next-line no-console
      console.error(deleteError);

      if (deleteResponse) {
        toastUpdateSuccess(toastId, 'Ripspot deleted.');
        // find ripspot on user and delete (filter it out)
        const newUser = { ...user };
        const newRipspots = newUser.ripspots.filter(
          userRipspot => userRipspot.id !== currentRipspot.id,
        );
        newUser.ripspots = newRipspots;
        dispatch(setUser(newUser));
        dispatch(closeChalllengeModal());
      }
    }
  };

  // thisd needs encclosure so we have access (one technique) to wehich item
  // was clicked fro same handler
  const onDeleteRipspotClick = ripspot => () => {
    const challlengeModalProps = {
      metadata: { ripspot },
      onConfirm: () => {
        // eslint-disable-next-line no-console
        // console.log({ onConfirm });
        confirmnationHandler(ripspot);
      },
      prompt: `Are you sure you want to delete your Ripspot for ${ripspot.firstName}?`,
    };

    dispatch(openChalllengeModal(challlengeModalProps));
    // dispatch(openChallengeModal(challlengeModalProps));
  };

  /**
   * Handles isActive toggling
   *
   * @param {*} ripspot
   */
  const onIsActiveClick = ripspot => async () => {
    const newUser = { ...user };
    const currentRipspots = [...newUser.ripspots];
    const prevIsActive = ripspot.isActive;
    const ripspotId = ripspot.id;
    const isActive = !prevIsActive;

    // format the new ripspot isActive field
    const newRipspots = currentRipspots.map(_ripspot => {
      if (_ripspot.id === ripspotId) {
        return {
          ..._ripspot,
          isActive,
        };
      }
      return _ripspot;
    });

    // update ripspot in db
    let ripspotResponse;
    try {
      ripspotResponse = await updateRipspot(ripspotId, { isActive });
    } catch (ripspotResponseError) {
      // eslint-disable-next-line no-console
      console.error(ripspotResponseError);
    }

    // alert user success / warn
    if (ripspotResponse) {
      isActive &&
        toastSuccess(`Ripspot now ${isActive ? 'active' : 'inactive'}!`);
      !isActive && toastWarn('Ripspot now inactive!');
    }
    // reset the new ripspots
    newUser.ripspots = newRipspots;
    // set user in redux for new data to propagate
    dispatch(setUser(newUser));
  };

  const handleCreateRipspotClick = evt => {
    navigate('/search', { state: { createNewRipspot: true } });
  };

  useEffect(() => {
    // for UI flash purposes
    setIsReady(true);
  }, []);

  const animatedProps = useSpring({
    opacity: isReady ? 1 : 0,
  });

  const props = {
    isOpen: false,
    onRequestClose: () => dispatch(closeChalllengeModal()),
    prompt: 'Are you sure you want to delete your Ripspot and lose ALL data?',
    confirmPrompt: 'Yes, delete Ripspot',
  };

  return (
    <animated.div style={animatedProps}>
      <CustomChallengeModal {...props} />
      <Container>
        <WrapperFlex>
          <div style={{ flex: 10 }}>
            <DashHeader>
              <Title>Account Details</Title>
              <FirstName>
                {user.firstName} {user.lastName}
              </FirstName>
              <UserEmail>{user.email}</UserEmail>
              <HeaderControls>
                <Button tertiary onClick={() => navigate(CHANGE_EMAIL_ROUTE)}>
                  Change Email
                </Button>
                <Button
                  tertiary
                  onClick={() => navigate(CHANGE_PASSWORD_ROUTE)}
                >
                  Change Password
                </Button>
              </HeaderControls>
            </DashHeader>

            <Controls>
              <Button primary onClick={handleCreateRipspotClick}>
                Create Ripspot
              </Button>
            </Controls>
          </div>

          {/* <DangerZone style={{ flex: 3 }} userId={user.id} /> */}
        </WrapperFlex>

        <Content>
          {ripspots.length > 0 && (
            <RipspotList
              ripspots={ripspots}
              onViewRipspotClick={onViewRipspotClick}
              onEditRipspotClick={onEditRipspotClick}
              onDeleteRipspotClick={onDeleteRipspotClick}
              onIsActiveClick={onIsActiveClick}
            />
          )}
        </Content>
      </Container>
    </animated.div>
  );
};

Account.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = ({ user, modals }) => ({
  user,
  modal: modals.openChalllengeModal,
});

const withConnect = connect(mapStateToProps);

export default compose(memo, withConnect)(Account);

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  color: ${({ theme }) => theme.colors.darkGrey};
  color: #222;
  padding-bottom: 100px;
  padding: 15px;


  max-width: 900px;
  margin: 0 auto;
  margin-top: 25px;
  
  h1,
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

const DashHeader = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 2.2rem;
  }
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.colors.darkGrey};
    margin: 15px 0;
  }
`;

const Title = styled.h2`
  ${({ theme }) => theme.media.min.mobile`
    text-align: center;
  `}
`;

const FirstName = styled.h3`
  font-size: 1.3rem;
`;

const UserEmail = styled.h3`
  font-size: 1.3rem;
`;

const Controls = styled.div`
  padding: 15px 0px;
`;

const HeaderControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 0.8rem;
  ${({ theme }) => theme.media.min.mobile`
    justify-content: flex-start;
    button {
      &:first-of-type {
        margin-right: 15px;
      }
    }
  `}
`;

const Content = styled.div`
  ${({ theme }) => theme.styles.grow}
`;

// const DangerZone = styled.div`
//   display: flex;
//   flex-direction: column;
//   /* justify-content: */
//   border: 3px solid rosybrown;
// `;

const WrapperFlex = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* flex: 50%; */
  width: 100%;
  /* border: 3px solid limegreen; */
`;

//   /* <DangerZone style={{ flex: 1 }}>
//             <h2>DANGER ZONE</h2>
//             <Button dangerous onClick={handleDeleteAccount}>
//               Delete Account
//             </Button>
//           </DangerZone> */
