/**
 *
 * MailIcon
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

export const Mail = ({ fill, width = '100%', height = '100%', style = {} }) => (
  <svg
    height={height}
    width={width}
    style={style}
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <g transform="translate(0,-952.36218)">
      <path
        d="m 10.000001,974.36215 39.999998,36.28125 40,-36.28125 -79.999998,0 z m -2,3.59375 0,49.5 25.437498,-26.4375 -25.437498,-23.0625 z m 83.999998,0 -25.4375,23.0625 25.4375,26.4375 0,-49.5 z m -55.625,25.75 -25.624998,26.6563 78.499998,0 -25.625,-26.6563 -12.2812,11.125 a 2.0001999,2.0001999 0 0 1 -2.6876,0 l -12.2812,-11.125 z"
        fill={fill}
        stroke="none"
        marker="none"
        visibility="visible"
        display="inline"
        overflow="visible"
      />
    </g>
  </svg>
);

Mail.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  fill: PropTypes.string,
};

export default Mail;
