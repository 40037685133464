/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * Implementing in /containers/App/index.js
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import _sortby from "lodash.sortby"
import { wrapApp, onAppRouteUpdate } from 'containers/App';

export const wrapRootElement = wrapApp;
export const onRouteUpdate = onAppRouteUpdate;

// Example usage
// async function fetchProducts() {
//   const response = await getProducts()
//   const { data, meta } = response

//   const sorted = _sortbyy(data, "id")

//   store.dispatch(
//     setProducts({
//       data: sorted,
//       meta,
//     })
//   )
// }
