/**
 *
 * RipspotWrapper
 *
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { navigate } from '@reach/router';

import RipspotMemorial from 'components/RipspotMemorial';

import { getRipspotById } from 'utils/api/ripspots';

const RipspotWrapper = ({ location }) => {
  const [ripspot, setRipspot] = useState(null);

  useEffect(() => {
    if (location.pathname.includes('/ripspot/')) {
      // grab id
      const path = location.pathname;
      const last = path.length - 1;
      const ripspotId = path.split('/')[last];
      // fetch data and set state
      console.log('about to fetch.....');
      (async () => {
        try {
          const res = await getRipspotById(ripspotId);
          console.log('\n\n');
          console.log({ getRipspotByIdResponse: res });
          console.log('\n\n');
          setRipspot(res);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log({ err });
          // TODO:
          navigate('/404');
        }
      })();
    }
  }, []);

  if (!ripspot) return null;

  console.log({ ripspot });

  return (
    <div>
      <RipspotMemorial ripspot={ripspot} />
    </div>
  );
};

RipspotWrapper.propTypes = {
  location: PropTypes.any,
};

export default RipspotWrapper;
