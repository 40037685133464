/**
 *
 * CUSTOM Challlenge Modal
 *
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Elements/Button';
import Modal from 'components/modals/index';

const CustomChallengeModal = props => {
  const {
    prompt = 'Are you sure?',
    confirmPrompt, // a shorter string for button text
    onConfirm,
    onDeny,
    isOpen,
    onRequestClose,
    positive = false,
    denyPrompt = '',
  } = props;
  return (
    <Modal
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeIconSize="md"
      title=""
    >
      <Container>
        <Wrapper>
          <Title>{prompt}</Title>
          <Controls>
            <Button
              style={{ marginRight: '25px' }}
              primary={!positive}
              onClick={evt => {
                onDeny && onDeny(evt);
                onRequestClose(evt);
              }}
            >
              {denyPrompt || 'No'}
            </Button>
            <Button
              dangerous={!positive}
              primary={positive}
              onClick={onConfirm}
            >
              {confirmPrompt ? `Yes, ${confirmPrompt}` : 'Yes'}
            </Button>
          </Controls>
        </Wrapper>
      </Container>
    </Modal>
  );
};

CustomChallengeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  prompt: PropTypes.string, // what you want modal to ask
  confirmPrompt: PropTypes.string, // confirm button text
  onConfirm: PropTypes.func,
  onDeny: PropTypes.func,
};

const mapStateToProps = ({ modals }) => {
  // console.log('\n\n');
  // console.log({ modals });
  // const test = modals.challlengeModal;
  return {
    // isOpen: modal && modal.isOpen.isRequired,
    // prompt: modal && modal.prompt,
    // onConfirm: modal && modal.onConfirm.isRequired,
  };
};
const withConnect = connect(mapStateToProps);

export default compose(memo, withConnect)(CustomChallengeModal);

const Container = styled.div`
  ${({ theme }) => theme.styles.middle}
`;
const Wrapper = styled.div`
  ${({ theme }) => theme.styles.boxShadows['0']};
  background-color: #fff;
  padding: 25px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 55px;

  button {
    min-width: 140px;
  }
`;

const Title = styled.h2`
  font-size: 1.4rem;
`;
