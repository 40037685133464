/**
 * Image HTTP method calls
 *
 *
 */

import { apiGET, apiPOST, apiPUT, apiDELETE } from '../index';

import { IMAGES } from '../urls';

// export const getMyRipspots = (query = {}) =>
//   new Promise((resolve, reject) => {
//     apiGET({ url: RIPSPOTS, query })
//       .then(res => {
//         console.log({ res });
//         resolve(res);
//       })
//       .catch(err => {
//         console.log({ err });
//         reject(err);
//       });
//   });

export const createImage = (data = {}) =>
  new Promise((resolve, reject) => {
    apiPOST({ url: IMAGES, data })
      .then(res => {
        console.log({ res });
        resolve(res);
      })
      .catch(err => {
        console.log({ err });
        reject(err);
      });
  });
