/**
 *
 * sFAQ data wuith id's via uuid for easier keys then entire string lenthgs
 *
 *
 * */

import uuid from 'uuid/v4';

export const faqData = [
  {
    question: 'Is a Ripspot a place where ashes have been scattered?',
    answer:
      'Creating a Ripspot is a wonderful way to mark the location where a loved one’s ashes have been scattered, but a Ripspot is not dependent on ashes or a burial. It can simply be a virtual demarcation of a meaningful place. If you plan to scatter a loved one’s ashes and use a Ripspot to mark the location, we recommend that you check with local municipalities and private property owners before doing so. Some restrict the scattering of ashes.',
    id: uuid(),
  },
  {
    question: 'Can a Ripspot be made private, or is it always public?',
    answer:
      'You have the ability to make the content of a Ripspot public or private. If private, only those given the password can view it.',
    id: uuid(),
  },
  {
    question: 'Are pets allowed?',
    answer:
      'Yes. We see dogs, cats, horses, even goldfish and turtles, as beloved family members. A Ripspot is a wonderful way to honor pets too.',
    id: uuid(),
  },
  {
    question: 'What is the fee for?',
    answer:
      'We believe your experience should be as simple and affordable as possible. We charge a fee because we do not sell advertising on our platform. The fee allows us to host content and continually improve our technology. A traditional burial in a cemetery can cost thousands of dollars, and while cremation may be a more affordable choice, there are costs associated with that as well. You can ask your local funeral director or cemetery for their price list.',
    id: uuid(),
  },
  {
    question: 'Who creates a Ripspot?',
    answer:
      'In most cases it is a surviving loved one who creates a Ripspot. They are responsible for the biography, photo gallery, and any videos the Ripspot links to. The creator of a Ripspot has administrative access to it, but that access can be shared with others.',
    id: uuid(),
  },

  // {
  //   question: 'Can people post comments and contribute to a Ripspot?',
  //   answer:
  //     'Yes. If you choose, you can allow people to post comments and share their thoughts, but as the administrator of a Ripspot, you can turn this feature off.',
  //   id: uuid(),
  // },
  {
    question: 'Can more than one person make a Ripspot?',
    answer:
      'Yes. When you create a Ripspot, you can give others administrative access, allowing them to upload photos and write copy.',
    id: uuid(),
  },
  {
    question: 'Can one Ripspot be for more than one person or pet?',
    answer:
      'Each Ripspot includes unlimited pins. Each pin designates a meaningful place to a loved one, but it is associated with just one biography and photo gallery. If you’d like to pin more than one person or pet in a location, you’ll need to create separate Ripspots.',
    id: uuid(),
  },
  {
    question:
      'Can I transfer my administrative role or ownership of a Ripspot to someone else?',
    answer: 'Yes. You only need a person’s email address to transfer.',
    id: uuid(),
  },
  {
    question: 'Can videos be added to a Ripspot?',
    answer:
      'Yes. We allow for hyperlinks to be included in a biography so you can share videos on YouTube, Vimeo, and other sites.',
    id: uuid(),
  },
  {
    question: 'Is a Ripspot permanent?',
    answer:
      'Yes, insofar as it is a virtual demarcation of a meaningful place, viewable online or via smartphone. Our mission is to contemporize how we celebrate a loved one who has passed, and we are committed to using technology to make the process more relevant, affordable, and eco-conscious. Our devices may evolve over time, but a Ripspot’s content is currently stored and served using cloud-based technology.',
    id: uuid(),
  },
  {
    question:
      'If I see an error on a Ripspot or find content offensive, what should I do?',
    answer:
      'If you find something inaccurate or offensive, please bring it to our attention by contacting us. Celebrating a loved one is a very sensitive topic, and we take it very seriously. While we respect individual choices, we reserve the right to contact the administrators of each Ripspot to correct errors and address offensive material, especially if it’s public. We also reserve the right to remove Ripspots if action is not taken. See our <a href="/terms-and-conditions">terms and conditions</a> for more information regarding this matter.',
    id: uuid(),
  },
];

export default faqData;
