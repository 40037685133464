/**
 *
 * RipspotCreation to S3 CONTAINER
 *
 *
 */

import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTransition, animated } from 'react-spring';
// import { navigate } from '@reach/router';

import {
  setTempRipspot,
  setIsInExistingPinEditMode,
  // setEditInProgress,
  submitToggleRipspotCreation,
  setEditInProgress,
  setRipspotLoading,
} from 'actions/ripspots';

import {
  // toastSuccess,
  toastError,
  toastInfo,
  toastUpdateError,
  // toastUpdate,
} from 'utils/toastify';

import { closeRipspotModal } from 'actions/modals';
import { setIsPaying } from 'actions/checkout';

import Spinner from 'components/Spinner';
import Button from 'components/Elements/Button';

import usePrevious from 'utils/hooks/usePrevious';
import useOnChangeWatcher from 'utils/hooks/useOnChangeWatcher';
import Theme from 'utils/theme';
import {
  PHOTO_GALLERY,
  DETAILS,
  BIO,
  PAYMENT,
  REVIEW,
  SHARE,
} from 'utils/constants';
// import { EXPLORE_ROUTE } from 'utils/routes';

import PhotoUploads from './PhotoUploads';
import Details from './Details';
import Bio from './Details/Bio';
import Payment from './Payment';
import Review from './Review';
import {
  createRipspotHandler,
  updateRipspotHandler,
} from './Review/reviewHandler';
import Share from './Share';
// import { photoGalleryData } from './utils';

// works!!!
import { steps, editSteps, stepNumByKey, editStepNumByKey } from './steps';

const { colors } = Theme;
const isDev = false; // process.env.NODE_ENV !== 'production';

// step 1: Bio
// step 2: Detail info
// step 3: Photo gallery uploads (S3)
// step 4: Order Confirmation / Review Order
// step 5: Payment View
// step 6: Share  // TODO: we need this step implemented

// clean way of maintaing state outside of render cycle
// to persist across form navigations
export const initData = {
  details: {
    name: isDev ? 'Michael Joseph Schmerbeck Jr.' : '',
    birthDate: {
      day: isDev ? 10 : null,
      month: isDev ? 10 : null,
      year: isDev ? 1991 : null,
    },
    deathDate: {
      day: null,
      month: null,
      year: null,
    },
    isArmedServices: false,
    branch: '',
  },
  bio: {},
  payment: {
    plan: isDev ? 'one-time' : 'annual', // annual || one-time
  },
  photoGallery: {}, // isDev ? { ...photoGalleryData, ...photoGalleryData } : {},
  review: { visibility: 'public' },
  share: {},
  pins: {},
};

let tempData = { ...initData };

const getTempData = () => tempData;
const setTempData = (field, data) => {
  tempData[field] = data;
};

//
// used now for storing changes to photoGallery
//
const initEditData = {
  photoGallery: {
    deleted: [], // [imageId]
    edited: {}, // {  [imageId]: updateData }
    // added: {} // TODO: might need to implement this
  },
};

let tempEditData = { ...initEditData };

const getTempEditData = () => tempEditData;
const setTempEditData = (field, data) => {
  tempEditData[field] = data;
};

const resetTempEditData = () => {
  // eslint-disable-next-line no-console
  console.log('resetting tempEditData...');
  tempEditData = { ...initEditData };
};

const editTempDataProps = {
  getTempEditData,
  setTempEditData,
  resetTempEditData,
};
//
//
//

//
// Reset ALL temp data
//
const resetTempData = () => {
  // eslint-disable-next-line no-console
  console.log('resetting tempData...');
  tempData = { ...initData };
  // now handle edit changes to photoGallery
  resetTempEditData();
};

const PAYMENT_STEP = 5;

/**
 * Root of Ripspot Creation Forms
 *
 * @param {*} { metadata }
 * @returns
 */
const RipspotCreation = ({
  user,
  ripspots,
  metadata,
  dispatch,
  tempPaymentInfo,
  initCurrentStep = 1,
  setParentCurrentStep,
  setParentPreviousStep,
  setParentStepsToUse,
  shouldClearTempData = false,
}) => {
  // ripspots shape from redux
  const { submitToggle } = ripspots;

  const isInNewRipspotMode =
    metadata.isInNewRipspotMode || ripspots.isInNewRipspotMode;

  useEffect(() => {
    if (isInNewRipspotMode) {
      resetTempData();
    }

    if (Object.keys(ripspots.tempData).length) {
      // eslint-disable-next-line no-console
      console.log('\n\n\n');
      // eslint-disable-next-line no-console
      console.log('setting tempData on mount!!!');
      // eslint-disable-next-line prefer-destructuring
      tempData = ripspots.tempData;
    }
    // eslint-disable-next-line no-console
    console.log({ tempData });
    // eslint-disable-next-line no-console
    console.log('\n\n\n');
  }, []);

  const [shareViewData, setShareViewData] = useState({});
  // const [submitToggle, setSubmitToggle] = useState(false);

  // ID used to create the share ripspot url in Share step after we get a successful response from api
  const [ripspotID, setRipspotID] = useState('');

  const [disableContinue, setDisableContinue] = useState(false); // prevents user from navigating to next step
  const [completePurchase, setCompletePurchase] = useState(!isInNewRipspotMode);
  // `initCurrentStep` allows us to have fine control over which step to start on, defaults to step-1 of course
  const [currentStep, setCurrentStep] = useState(initCurrentStep || 1); // current step in View

  const previousStep = usePrevious(currentStep);

  // allows easy manipulation of order of steps and removal/addition
  const isEditMode = !isInNewRipspotMode;
  const stepsToUse = isEditMode ? editSteps : steps;
  const currentStepKey = stepsToUse[currentStep] && stepsToUse[currentStep].key;

  // // eslint-disable-next-line no-console
  // console.log('\n\n\n');
  // // eslint-disable-next-line no-console
  // console.log('-----------------');
  // // eslint-disable-next-line no-console
  // console.log('RipspotCreation');
  // // eslint-disable-next-line no-console
  // console.log({ isInNewRipspotMode });
  // // eslint-disable-next-line no-console
  // console.log({ metadata });
  // // eslint-disable-next-line no-console
  // console.log({ TEMPDATA: getTempData() });
  // // eslint-disable-next-line no-console
  // console.log({ currentStep });

  // // eslint-disable-next-line no-console
  // console.log({ isEditMode });

  // // eslint-disable-next-line no-console
  // console.log({ metadata });
  // // eslint-disable-next-line no-console
  // console.log('-----------------');
  // // eslint-disable-next-line no-console
  // console.log('\n\n\n');

  const resetSteps = () => {
    setParentCurrentStep(1);
    setCurrentStep(1);
  };

  useEffect(() => {
    if (initCurrentStep) {
      // eslint-disable-next-line no-console
      console.log('setting initial step');
      setCurrentStep(initCurrentStep);
    }
  }, [initCurrentStep]);

  useEffect(() => {
    // ensures data is cleared after successful ripspot creation
    if (shouldClearTempData) {
      resetTempData();
      resetSteps(); // reset step to step 1 for next ripspot since comp instance stays within modal
    }
  }, [shouldClearTempData]);

  useEffect(() => {
    if (currentStep) {
      setParentCurrentStep(currentStep);
    }
    if (previousStep) {
      setParentPreviousStep(previousStep);
    }
    if (stepsToUse) {
      setParentStepsToUse(stepsToUse);
    }
  }, [stepsToUse, currentStep, previousStep]);

  useEffect(() => {
    // if user already gave payment info, allow navigation to next screen
    if (
      currentStepKey &&
      currentStepKey === PAYMENT &&
      (tempPaymentInfo === '' || tempPaymentInfo.tokenId)
    ) {
      setDisableContinue(false); // allow user to continue
    }

    if (currentStepKey && currentStepKey === REVIEW) {
      // save any data about current ripspot for the share view
      // anything needed for the ripspot url (name/id?)
      setShareViewData(tempData);
    }
  }, [currentStepKey]);

  // eslint-disable-next-line no-use-before-define
  const transitions = useTitleTransition({ currentStep, previousStep }); // defined below

  /**
   * Prev Click
   *
   */
  const handlePreviousClick = () => {
    if (isEditMode) {
      if (currentStep - 1 === editStepNumByKey.PAYMENT_STEP) {
        setCurrentStep(currentStep - 2);
      } else {
        setCurrentStep(currentStep - 1);
      }
    } else {
      setCurrentStep(currentStep - 1);
    }
    dispatch(setTempRipspot(tempData));
  };

  /**
   * Continue Click
   *
   */
  const handleContinueClick = () => {
    // eslint-disable-next-line no-console
    console.log(`handleContinueClick: current step....${currentStep}`);

    if (isEditMode) {
      if (currentStep + 1 === editStepNumByKey.PAYMENT_STEP) {
        //
        // shouldnt be able to get here.
        //
        // double check, no paymwent in edit mode just "update ripspot"
        // payment -- SKIP this one
        // setCurrentStep(currentStep + 2);
        toastInfo(`Payment step ${PAYMENT_STEP}!!!`);
      } else {
        const shouldUpload = currentStep + 1 === editStepNumByKey.SHARE_STEP;
        // eslint-disable-next-line no-console
        console.log({ editStepNumByKey });
        // eslint-disable-next-line no-console
        console.log({ shouldUpload });

        if (shouldUpload) {
          // eslint-disable-next-line no-console
          console.log('uploading!!!');
          dispatch(submitToggleRipspotCreation());
        } else {
          // eslint-disable-next-line no-console
          console.log(`going to next step....${currentStep + 1}`);
          setCurrentStep(currentStep + 1);
        }
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(`going to next step....${currentStep + 1}`);
      setCurrentStep(currentStep + 1);
    }

    // eslint-disable-next-line no-console
    console.log('setTempRipspot');
    // eslint-disable-next-line no-console
    console.log({ tempData });

    dispatch(setTempRipspot(tempData));
  };

  const handlePaymentSaveAndContinue = () => {
    if (tempPaymentInfo.tokenId) {
      // already has purchase token, continue to review
      setCurrentStep(currentStep + 1);
    } else {
      setCompletePurchase(true); // this sets a flag to submit Stripe form
      dispatch(setIsPaying(true));
    }
  };

  const tempDataProps = { getTempData, setTempData };

  const showContinueButton =
    currentStepKey !== REVIEW && currentStepKey !== PAYMENT;

  const disableContinueButton =
    currentStepKey !== PHOTO_GALLERY &&
    currentStepKey !== BIO &&
    disableContinue;

  const isPayment = currentStepKey === PAYMENT;
  const isReview = currentStepKey === REVIEW;
  const isShare = currentStepKey === SHARE;

  //
  //
  //
  useOnChangeWatcher(isPayment, () => {
    //
    // this ensures we reset payment trigger when navigating to this step
    //
    // TODO: double check though for data before - might want to go back a step and come back and pay with same info entered already etc.
    //
    if (isPayment) {
      setDisableContinue(true);
    }
  });

  //
  // order here matters, this overrides above for re-navigation
  //
  useOnChangeWatcher(isReview, () => {
    // this fixes bug where above resets when we leave the payment step and go backwards
    // has to come after isPayment
    if (isReview) {
      setDisableContinue(false);
    }
  });

  /**
   * handleCreateRipspot
   *
   * @param {*} evt
   */
  const handleCreateRipspot = async evt => {
    // eslint-disable-next-line no-console
    console.log('\n\n\n');
    // eslint-disable-next-line no-console
    console.log('handleCreateRipspot');
    // eslint-disable-next-line no-console
    console.log('\n\n\n');

    const toastId = toastInfo('Creating ripspot...');

    const _tempData = getTempData();

    const { latitude, longitude } = metadata.mapState.viewport;
    const ripspotData = {
      ..._tempData,
      latitude,
      longitude,
      protectedPassword: '', // TODO
    };

    try {
      dispatch(setRipspotLoading(true));
      // setLoading(true);
      const createResponse = await createRipspotHandler({
        isNewRipspot: isInNewRipspotMode,
        dispatch,
        resetTempData,
        ripspotData,
        user,
        ripspots,
        tempPaymentInfo,
        toastId,
      });
      dispatch(setRipspotLoading(false));
      // setLoading(false);
      // eslint-disable-next-line no-console
      console.log({ createResponse });
      if (createResponse) {
        setRipspotID(createResponse.id);
        console.log('\n\n');
        console.log(`setting step to Share: ${stepNumByKey.SHARE_STEP}`);
        console.log('\n\n');
        // go to Share step!
        setCurrentStep(6);
        // tell map we are done creating/editing
        dispatch(setEditInProgress(false));
      }
    } catch (error) {
      dispatch(setRipspotLoading(false));
      // setLoading(false);
      // eslint-disable-next-line no-console
      console.error(error);
      toastUpdateError(toastId, error.reason || 'Sorry, something went wrong');
    }
  };

  /**
   * handleUpdateRipspot
   *
   * @param {*} evt
   */
  const handleUpdateRipspot = async () => {
    // eslint-disable-next-line no-console
    console.log('\n\n\n');
    // eslint-disable-next-line no-console
    console.log('handleUpdateRipspot');
    // eslint-disable-next-line no-console
    console.log('\n\n\n');

    //
    //
    //
    const toastId = toastInfo('Updating ripspot...');

    const _tempData = getTempData();

    const { latitude, longitude } = metadata.mapState.viewport;
    const ripspotData = {
      ..._tempData,
      latitude,
      longitude,
      protectedPassword: '', // TODO
    };

    try {
      dispatch(setRipspotLoading(true));
      // setLoading(true);
      const updateResponse = await updateRipspotHandler({
        isNewRipspot: isInNewRipspotMode,
        dispatch,
        resetTempData,
        ripspotData,
        user,
        ripspots,
        tempPaymentInfo,
        toastId,
      });
      dispatch(setRipspotLoading(false));
      // setLoading(false);
      // eslint-disable-next-line no-console
      console.log({ updateResponse });
      if (updateResponse) {
        setRipspotID(updateResponse.id);
        console.log('\n\n');
        console.log(`setting step to Share: ${editStepNumByKey.SHARE_STEP}`);
        console.log('\n\n');
        // go to Share step!
        setCurrentStep(editStepNumByKey.SHARE_STEP);
        // tell map we are done creating/editing
        dispatch(setEditInProgress(false));
      }
    } catch (error) {
      dispatch(setRipspotLoading(false));
      // setLoading(false);
      // eslint-disable-next-line no-console
      console.error(error);
      toastUpdateError(toastId, error.reason || 'Sorry, something went wrong');
    }
  };

  //
  // This listens to submit action to fire correct handler function
  //
  useOnChangeWatcher(submitToggle, () => {
    // if (isInNewRipspotMode && data && submitToggle) {
    if (submitToggle && isInNewRipspotMode) {
      //
      // This creates a new ripspot!
      //
      handleCreateRipspot();
    } else if (submitToggle && isEditMode) {
      //
      // This updates an existing ripspot!
      //
      handleUpdateRipspot();
    }
  });

  return (
    <Container>
      <Spinner
        loading={ripspots.ripspotIsLoading}
        color={colors.Primary}
        cover
      />
      <Content>
        {transitions.map(({ item, props, key }) => (
          <AnimatedDiv key={key} style={props}>
            {(() => {
              switch (stepsToUse[item].key) {
                case PHOTO_GALLERY:
                  return (
                    <PhotoUploads {...tempDataProps} {...editTempDataProps} />
                  );
                case DETAILS:
                  return (
                    <Details
                      {...tempDataProps}
                      dispatch={dispatch}
                      setDisableContinue={setDisableContinue}
                    />
                  );
                case BIO:
                  return (
                    <Bio
                      {...tempDataProps}
                      setDisableContinue={setDisableContinue}
                    />
                  );

                case REVIEW:
                  return <Review {...tempDataProps} />;
                case PAYMENT:
                  return (
                    <Payment
                      {...tempDataProps}
                      setDisableContinue={setDisableContinue}
                      setCompletePurchase={setCompletePurchase}
                      completePurchase={completePurchase}
                      setCurrentStep={setCurrentStep}
                    />
                  );
                case SHARE:
                  return (
                    <Share
                      ripspotID={ripspotID}
                      tempDataProps={tempDataProps}
                      shareViewData={shareViewData}
                      viewMapClick={evt => {
                        dispatch(closeRipspotModal());
                      }}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </AnimatedDiv>
        ))}
      </Content>

      <Controls isReview={isReview}>
        {!isShare && (
          <Button disabled={currentStep === 1} onClick={handlePreviousClick}>
            {isReview ? 'Back To Edit' : 'Previous'}
          </Button>
        )}
        {showContinueButton && !isShare && (
          <Button
            light
            disabled={disableContinueButton}
            onClick={handleContinueClick}
          >
            Continue
          </Button>
        )}
        {isPayment && (
          <Button
            primary
            disabled={disableContinue} // || !tempPaymentInfo}
            onClick={handlePaymentSaveAndContinue}
          >
            Pay and create Ripspot
          </Button>
        )}
        {isReview && (
          <Button
            light
            disabled={disableContinue}
            onClick={handleContinueClick}
            // onClick={() => setSubmitToggle(v => !v)}
          >
            {isEditMode ? 'Update Ripspot' : 'Proceeed to checkout'}
          </Button>
        )}
      </Controls>
    </Container>
  );
};

RipspotCreation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  setParentCurrentStep: PropTypes.func.isRequired,
  setParentPreviousStep: PropTypes.func.isRequired,
  setParentStepsToUse: PropTypes.func.isRequired,
  //
  metadata: PropTypes.object,
  ripspots: PropTypes.any,
  tempPaymentInfo: PropTypes.any,
  shouldClearTempData: PropTypes.bool,
  initCurrentStep: PropTypes.number,
};

const mapStateToProps = ({ ripspots, checkout, user }) => ({
  user,
  ripspots,
  tempPaymentInfo: checkout.tempPaymentInfo,
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(RipspotCreation);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const AnimatedDiv = styled(animated.div)`
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding: 15px;
  margin: 0 auto;
`;

const Content = styled.div`
  ${({ theme }) => theme.styles.middle}
  position: relative;
  flex: 1;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Controls = styled.div`
  ${({ isReview }) =>
    isReview
      ? css`
          background-color: ${({ theme }) => theme.colors.Primary};
        `
      : css`
          background: linear-gradient(
            to top,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.1)
          );
        `}
  display: flex;
  justify-content: space-between;
  padding: 15px;
  z-index: 1;

  button {
    font-size: 0.75rem;
    flex: 1;
    ${({ theme }) => theme.media.min.mobile`
      font-size: 1rem;
    `}
    &:first-of-type {
      margin-right: 10px;
    }
  }
`;

/**
 * react-spring useTransition animation
 *
 * @param {*} { currentStep, previousStep }
 * @returns
 */
function useTitleTransition({ currentStep, previousStep }) {
  const transitions = useTransition(currentStep, key => key, {
    from: item => ({
      position: 'absolute',
      opacity: 0,
      transform: `translate3d(${item < previousStep ? '-' : ''}30px,0,0)`,
    }),
    enter: { opacity: 1, transform: 'translate3d(0px,0,0)' },
    leave: prevItem => ({
      opacity: 0,
      transform: `translate3d(${prevItem < currentStep ? '-' : ''}30px,0,0)`,
    }),
  });

  return transitions;
}
