/**
 *
 * payAndUploadNewRipspot()
 *
 *
 */

import { convertToRaw } from 'draft-js';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';

import {
  createRipspot,
  updateRipspot as updateRipspotHTTP,
} from 'utils/api/ripspots';
import {
  chargeCard,
  createSubscription,
  createPaymentIntent,
} from 'utils/api/stripe';

import {
  toastUpdate,
  toastUpdateSuccess,
  toastUpdateError,
} from 'utils/toastify';

import { formatPinsToArray } from 'utils/helperFunctions/ripspots';
import { SUB_PRICE, SINGLE_CHARGE_PRICE } from 'utils/constants';

import { uploadPhotoGallery } from './photoGallery';

/**
 * Charges cc via stripe and creates new ripspot in db, uploading photos as well afterwards
 *
 * @param {*} ripspots
 * @param {*} ripspotData
 * @param {*} tempPaymentInfo
 * @param {*} toastId
 */
export const payAndUploadNewRipspot = (
  ripspots,
  ripspotData,
  tempPaymentInfo,
  toastId,
  stripeCustomerId,
) =>
  new Promise(async (resolve, reject) => {
    const {
      details,
      bio,
      photoGallery,
      latitude,
      longitude,
      review,
      protectedPassword, // password if it is a private ripspot
    } = ripspotData;
    const { name, branch, isArmedServices, deathDate, birthDate } = details;
    const { visibility } = review;

    const rawContentState =
      bio.richState && convertToRaw(bio.richState.getCurrentContent());

    const { tokenId, amount } = tempPaymentInfo;
    // eslint-disable-next-line no-console
    console.log({ tempPaymentInfo });
    // eslint-disable-next-line no-console
    console.log({ ripspotData });
    // eslint-disable-next-line no-console
    console.log({ rawContentState });
    // eslint-disable-next-line no-console
    console.log({ tokenId, amount });
    // eslint-disable-next-line no-console
    console.log({ ripspots, ripspotData, tempPaymentInfo, toastId });

    const stripe = await loadStripe(
      'pk_test_hPfcEUHgUZITcgaDq9gjxN2j000Pl4HDqK',
    );
    // `source` is obtained with Stripe.js; see https://stripe.com/docs/payments/accept-a-payment-charges#web-create-token

    // eslint-disable-next-line no-console
    console.log({ stripe });

    // Determine if we are charging a payment or creating a subscription
    const isSubscription = amount === SUB_PRICE;

    let subRes;
    let chargeRes;
    // let paymentIntentResponse;

    if (isSubscription) {
      // eslint-disable-next-line no-console
      console.log('Subscription Payment');
      // * **************************************************
      // *
      // * 1A) create the subscription
      // *
      // * *************************************************
      try {
        subRes = await createSubscription({
          customerId: stripeCustomerId,
          paymentMethodId: tokenId,
          priceId: 'price_1HGvLlBbNsqtS0cAa23ZzjHP',
        });
        // eslint-disable-next-line no-console
        console.log({ subRes });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('One-time Payment');
      // * **************************************************
      // *
      // * 1B) charge the card
      // *
      // * *************************************************

      // TODO: send token to server

      try {
        const res = await chargeCard({ tokenId, amount });
        chargeRes = res;
        // eslint-disable-next-line no-console
        console.log({ chargeRes });
      } catch (chargeCardErr) {
        // eslint-disable-next-line no-console
        console.log({ chargeCardErr });
        if (chargeCardErr.message) {
          toastUpdateError(toastId, chargeCardErr.message);
        }
        // stop code execution
        return reject(chargeCardErr);
      }
    }

    // // eslint-disable-next-line no-console
    // console.log({ paymentIntentResponse });
    // eslint-disable-next-line no-console
    console.log({ subRes });

    // TODO: Need to poll the paymentIntentRepsonse using clientSecret

    const paid = (chargeRes && chargeRes.paid) || (subRes && subRes.paid);
    const status = (chargeRes && chargeRes.status) || (subRes && subRes.status);

    // eslint-disable-next-line no-console
    console.log({ paid, status });

    if (status === 'succeeded' || status === 'active') {
      // successful charge
      toastUpdate(toastId, 'Card charged successfully');
      const formattedPins = formatPinsToArray(ripspots.pins);
      await setTimeout(() => {}, 1500); // force delay 1.5s
      toastUpdate(toastId, 'Creating ripspot...');

      //
      // Form the ripspot data
      //
      const newRipspot = {
        name,
        deathDate,
        birthDate,
        biography: JSON.stringify(rawContentState),
        photoGallery: [], // upload images asynchronously -- down below
        pins: JSON.stringify(formattedPins),
        location: {
          name: '',
          latitude,
          longitude,
        },
        isPublic: visibility && visibility === 'public',
        isActive: true, // TODO: allow user to update this value
        payment: {
          paymentTier: amount === SINGLE_CHARGE_PRICE ? 'premium' : 'basic',
          paymentTerm: amount === SINGLE_CHARGE_PRICE ? 'prepaid' : 'annual',
        },
      };

      if (isArmedServices) {
        // set military branch if applicable -- defaults to false otherwise for new ripspots
        newRipspot.isArmedServices = true;
        newRipspot.branch = branch;
      }

      if (!newRipspot.isPublic) {
        // send the password and the api will hash and save it in db
        newRipspot.password = protectedPassword; // === isPrivate
      }

      // * **************************************************
      // *
      // * 2) Create actual ripspot
      // *
      // * *************************************************
      let ripspotRes;
      try {
        ripspotRes = await createRipspot(newRipspot); // TODO: in future,  this will always be UPDATE as rispot are always created immedatelly in V2 (in progress auto-save feature)
      } catch (createRipspotErr) {
        // eslint-disable-next-line no-console
        console.log({ createRipspotErr });
        toastUpdateError(
          toastId,
          'toString' in createRipspotErr
            ? createRipspotErr.toString()
            : `${createRipspotErr}`,
        );
        return reject(createRipspotErr);
      }

      // eslint-disable-next-line no-console
      console.log({ ripspotRes });

      // * **************************************************
      // *
      // * 3) Upload Gallery
      // *
      // * *************************************************
      if (photoGallery && Object.keys(photoGallery).length > 0) {
        try {
          toastUpdate(
            toastId,
            'Uploading photo gallery...This could take a minute...',
            { autoClose: 8000 },
          );
          const uploadPhotoGalleryRes = await uploadPhotoGallery(
            ripspotRes.id, // id from ripspot crteated above to attach to
            photoGallery,
            toastId,
          );
          // eslint-disable-next-line no-console
          console.log({ uploadPhotoGalleryRes });
          toastUpdateSuccess(toastId, 'Photo Gallery uploaded!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          return resolve({ ...ripspotRes, photoGallery });
        } catch (uploadPhotoGalleryErr) {
          toastUpdateError(
            toastId,
            'toString' in uploadPhotoGalleryErr
              ? uploadPhotoGalleryErr.toString()
              : `${uploadPhotoGalleryErr}`,
          );
          reject(uploadPhotoGalleryErr);
        }
      } else {
        //
        // No Photos!
        //
        // eslint-disable-next-line no-console
        console.log({ ripspotRes });
        return resolve(ripspotRes);
      }
    }
    // !-- -- -- -- -- -- -- -- -- -- -- -- --
    //
    // ! charge not successful
    //
    // !-- -- -- -- -- -- -- -- -- -- -- -- --
    // TODO: if charge not successful
    // eslint-disable-next-line no-console
    console.log('charge not successful');
    // // eslint-disable-next-line no-console
    // console.log('should be polling the status now...');
    // eslint-disable-next-line no-console
    console.log({ status });
  });

/**
 * Update a ripspot by its id
 *
 * @param {*} ripspotId
 * @param {*} data
 */
export const updateRipspot = (ripspotId, data, toastId) =>
  new Promise(async (resolve, reject) => {
    try {
      // * **************************************************
      // *
      // * 1) Update Ripspot -- Upload New Data
      // *
      // * *************************************************
      const { photoGallery, ...rest } = data;
      console.log('\n\n\n');
      console.log({ data });
      console.log({ rest });
      console.log('\n\n\n');

      const res = await updateRipspotHTTP(ripspotId, rest);
      console.log('\n\n\n');
      console.log({ res });
      console.log('\n\n\n');

      // * **************************************************
      // *
      // * 2) Upload Gallery
      // *
      // * *************************************************
      if (photoGallery && Object.keys(photoGallery).length > 0) {
        try {
          toastUpdate(
            toastId,
            'Uploading photo gallery...This could take a minute...',
            { autoClose: 8000 },
          );
          const uploadPhotoGalleryRes = await uploadPhotoGallery(
            res.id, // id from ripspot crteated above to attach to
            photoGallery,
            toastId,
          );
          // eslint-disable-next-line no-console
          console.log({ uploadPhotoGalleryRes });
          toastUpdateSuccess(toastId, 'Photo Gallery uploaded!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          return resolve({ ...res, photoGallery });
        } catch (uploadPhotoGalleryErr) {
          toastUpdateError(
            toastId,
            'toString' in uploadPhotoGalleryErr
              ? uploadPhotoGalleryErr.toString()
              : `${uploadPhotoGalleryErr}`,
          );
          reject(uploadPhotoGalleryErr);
        }
      } else {
        //
        // No Photos!
        //
        // eslint-disable-next-line no-console
        console.log({ res });
        return resolve(res);
      }
      return resolve(res);
    } catch (err) {
      reject(err);
    }
  });

// if (paymentIntentResponse) {
//   (async () => {
//     // eslint-disable-next-line no-console
//     console.log('About to call and check paymentIntent');
//     const { paymentIntent, error } = await stripe.confirmCardPayment(
//       paymentIntentResponse.client_secret,
//     );
//     // eslint-disable-next-line no-console
//     console.log({ paymentIntent, error });
//     if (error) {
//       // Handle error here
//       alert('Error!');
//     } else if (paymentIntent && paymentIntent.status === 'succeeded') {
//       // Handle successful payment here
//       alert('Success!');
//     }
//   })();
// }

// try {
//   const amountToUse =
//     amount === SUB_PRICE ? SUB_PRICE.toFixed(1) : amount.toFixed(1);
//   // eslint-disable-next-line no-console
//   console.log({ amount });
//   // eslint-disable-next-line no-console
//   console.log({ amountToUse });
//   paymentIntentResponse = await createPaymentIntent({
//     amount: amountToUse,
//   });
//   // eslint-disable-next-line no-console
//   console.log({ paymentIntentResponse });
// } catch (chargeCardErr) {
//   // eslint-disable-next-line no-console
//   console.log({ chargeCardErr });

// if (chargeCardErr.message) {
//   toastUpdateError(toastId, chargeCardErr.message);
// }
// // stop code execution
// return reject(chargeCardErr);
// }
