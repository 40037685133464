/**
 *
 * Ripspot card for dashboard
 *
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import ReactHtmlParser from 'react-html-parser';
// import { navigate } from '@reach/router';

import Button from 'components/Elements/Button';

import Theme from 'utils/theme';

import AdminRipspot from './AdminRipspot';

const { colors } = Theme;

const RipspotCard = ({
  ripspot,
  onViewRipspotClick,
  onEditRipspotClick,
  onDeleteRipspotClick,
  onIsActiveClick,
}) => {
  const [adminIsOpen, setAdminIsOpen] = useState(false);
  const {
    name,
    location,
    markup,
    id,
    isComplete,
    isActive,
    isPublic,
  } = ripspot;

  // // eslint-disable-next-line no-console
  // console.log({ isComplete, isActive, isPublic });

  const isActiveProps = {
    style: {
      width: '105px',
    },
  };

  if (!isActive) {
    isActiveProps.outline = true;
    isActiveProps.style = {
      ...isActiveProps.style,
      color: colors.lightGreen,
    };
  } else {
    // INactive
    // isActiveProps.tertiary = true;
    isActiveProps.style = {
      ...isActiveProps.style,
      backgroundColor: colors.midGreen,
      border: `1px solid ${colors.midGreen}`,
      color: '#fff',
    };
  }

  return (
    <Container>
      {/* <Content>&quot;{ReactHtmlParser(markup)}&quot;</Content> */}

      <Title>
        {name} - {location.name}
      </Title>
      <Controls>
        <Button primary onClick={onViewRipspotClick(ripspot)}>
          View
        </Button>
        {/* 
        //
        // TODO: Add this back when we want to fully integrate shared ownership via admin UI/API
        //
        <Button
          primary={!adminIsOpen}
          secondary={adminIsOpen}
          onClick={() => setAdminIsOpen(!adminIsOpen)}
        >
          Admin
        </Button> */}
        <Button primary onClick={onEditRipspotClick(ripspot)}>
          Edit
        </Button>
        <Button {...isActiveProps} onClick={onIsActiveClick(ripspot)}>
          {isActive ? 'Active' : 'Inactive'}
        </Button>
        <Button
          dangerous
          onClick={onDeleteRipspotClick && onDeleteRipspotClick(ripspot)}
        >
          Delete
        </Button>
      </Controls>
      <AdminRipspot isOpen={adminIsOpen} ripspotId={id} ripspot={ripspot} />
    </Container>
  );
};

RipspotCard.propTypes = {
  ripspot: PropTypes.any.isRequired,
  onViewRipspotClick: PropTypes.func.isRequired,
  onEditRipspotClick: PropTypes.func.isRequired,
  onDeleteRipspotClick: PropTypes.func.isRequired,
  onIsActiveClick: PropTypes.func.isRequired,
};

export default RipspotCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
`;

// const StyledButton = styled(Buttmn)`
//   ${{isActive => isActive && css`

//   `}}
//   ${{Inactive => Inactive && css``}}
//   `

const Controls = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.max.mobile`
    flex-wrap: wrap;
  `}

  button {
    font-size: 1.1rem;
    margin-right: 15px;
    ${({ theme }) => theme.media.max.mobile`
      margin-bottom: 15px;  
    `}
  }
`;
