/**
 *
 * FAQ
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import faqs from 'data/faq';
import FAQItem from './FAQItem';

class FAQPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let finalFaqs;
    if (this.props.limit) {
      finalFaqs = faqs.slice(0, this.props.limit);
    } else {
      finalFaqs = faqs;
    }

    return (
      <Container>
        <Title>FAQs</Title>
        <List>
          {finalFaqs.map(item => (
            <FAQItem key={item.id} item={item} />
          ))}
        </List>
      </Container>
    );
  }
}

FAQPage.propTypes = {
  limit: PropTypes.number,
};

export default FAQPage;

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  color: ${({ theme }) => theme.colors.darkGrey};
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 15px;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: center;
  color: #000;
`;

const List = styled.ul`
  list-style: none;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  &:hover {
    li:not(:hover) {
      /* Target all li-items that are NOT being hovered */
      color: ${({ theme }) => theme.colors.midGrey};
      svg {
        fill: ${({ theme }) => theme.colors.midGrey};
      }
    }
  }
`;
