/**
 *
 * PinsList Icons List
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import icons from 'components/lib/Mapbox/utils/icons';

import PinsListItem from './PinsListItem';

const PinsList = ({
  currentPin,
  setCurrentPin,
  branch,
  setIsArmedServices,
}) => {
  const filteredIcons = icons;
  return (
    <List>
      {filteredIcons.map(({ name, image, displayName }) => (
        <PinsListItem
          currentPin={currentPin}
          name={name}
          displayName={displayName}
          image={image}
          setIsArmedServices={setIsArmedServices}
          setCurrentPin={setCurrentPin}
        />
      ))}
    </List>
  );
};

PinsList.propTypes = {
  currentPin: PropTypes.string.isRequired,
  setCurrentPin: PropTypes.func.isRequired,
  setIsArmedServices: PropTypes.func.isRequired,
  branch: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default memo(PinsList);

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-top: 25px;
  padding-bottom: 25px;
`;
