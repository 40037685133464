/**
 *
 * DraftJs Rich Text Editor
 *
 *
 *
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  EditorState,
  RichUtils,
  // createEditorStateWithText,
  // convertFromRaw,
  // convertToRaw,
} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import styled, { css } from 'styled-components';

import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin';
// import { colorStyleMap } from 'draft-js-color-picker-plugin';

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  // CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  // BlockquoteButton,
  // CodeBlockButton,
} from 'draft-js-buttons';

// import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';

// import createSidebarPlugin from 'last-draft-js-sidebar-plugin';
// import 'last-draft-js-sidebar-plugin/lib/plugin.css';
// import createLinkPlugin from 'draft-js-link-plugin';
// import 'draft-js-link-plugin/lib/plugin.css';

// /* Embed plugin */
// import createEmbedPlugin from 'draft-js-embed-plugin';
// import 'draft-js-embed-plugin/lib/plugin.css';

// linkify
// import createLinkifyPlugin from 'draft-js-linkify-plugin';

// Emoji plugin
// import createEmojiPlugin from 'draft-js-emoji-plugin';
// // / emoji plugin css plugin
// import 'draft-js-emoji-plugin/lib/plugin.css';

// hashtag
// import createHashtagPlugin from 'draft-js-hashtag-plugin';
// hashtag plugin css
// import 'draft-js-hashtag-plugin/lib/plugin.css';

// Draftjs main css
import 'draft-js/dist/Draft.css';

// // linkify css
// import 'draft-js-linkify-plugin/lib/plugin.css';

// import 'draft-js-inline-toolbar-plugin/lib/plugin.css';

// const embedPlugin = createEmbedPlugin();
// const emojiPlugin = createEmojiPlugin();
// const linkifyPlugin = createLinkifyPlugin({
//   target: '_blank',
//   component: props => {
//     const { contentState, ...rest } = props;
//     return <a {...rest} onClick={() => alert('link clicked')} />;
//   },
// });

const themeStyles = {
  input: 'input-here',
  inputInvalid: 'PUT YOUR INPUT INVALID CLASS HERE',
};

const linkPlugin = createLinkPlugin({
  theme: themeStyles,
  placeholder: 'http://…',
});

// const linkPlugin = createLinkPlugin();
// const linkifyPlugin = createLinkifyPlugin();

// const hashtagPlugin = createHashtagPlugin();
const toolbarPlugin = createToolbarPlugin();

// const inlineToolbarPlugin = createInlineToolbarPlugin();
// const { InlineToolbar } = inlineToolbarPlugin;
const { Toolbar } = toolbarPlugin;
const plugins = [toolbarPlugin, linkPlugin]; // inlineToolbarPlugin,  linkifyPlugin, hashtagPlugin

// function getBlockStyle(block) {
//   switch (block.getType()) {
//     case 'blockquote':
//       return 'RichEditor-blockquote';
//     default:
//       return null;
//   }
// }

// const getRAW = editorState => {
//   const raw = convertToRaw(editorState.getCurrentContent());
//   // console.log(JSON.stringify(raw));
//   return raw;
// };

/**
 * DraftJs Custom Wrapper
 *
 * @param {*} { onEditChange, initialEditState }
 * @returns
 */
const Draft = ({ onEditChange, initialEditState }) => {
  // either grab previous data or create a new one
  const initState =
    (initialEditState && initialEditState.richState) ||
    EditorState.createEmpty();

  const [editorState, setEditorState] = useState(initState);
  const editor = useRef();

  useEffect(() => {
    editor.current && editor.current.focus();
  }, []);

  useEffect(() => {
    if (initialEditState && initialEditState.richState) {
      setEditorState(initialEditState.richState);
    }
  }, [initialEditState]);

  const onChange = newEditorState => {
    setEditorState(newEditorState);
    onEditChange(newEditorState);
  };

  const handleKeyCommand = command => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command);
    if (newEditorState) {
      onChange(newEditorState);
      return 'handled';
    }
    return 'not-handled';
  };

  return (
    <Container className="editorContainer">
      <ToolbarWrapper>
        <Toolbar>
          {externalProps => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <Separator {...externalProps} />
              <HeadlineOneButton {...externalProps} />
              <HeadlineTwoButton {...externalProps} />
              <HeadlineThreeButton {...externalProps} />
              {/* <linkPlugin.LinkButton {...externalProps} /> */}
            </>
          )}
        </Toolbar>
      </ToolbarWrapper>

      <EditorWrapper>
        <Editor
          spellCheck
          ref={editor}
          placeholder="Write their story..."
          // blockStyleFn={getBlockStyle}
          // customStyleMap={colorStyleMap}
          // keyBindingFn={mapKeyToEditorCommand}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onChange}
          plugins={plugins}
        />
        {/* <InlineToolbar>
          {externalProps => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <linkPlugin.LinkButton {...externalProps} />
            </>
          )}
        </InlineToolbar> */}
      </EditorWrapper>
    </Container>
  );
};

Draft.propTypes = {
  onEditChange: PropTypes.func.isRequired,
  initialEditState: PropTypes.object,
};

export default Draft;

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  position: relative;
  width: 100%;
`;
/* overflow-y: hidden;  */

const EditorWrapper = styled.div`
  background: #fff;
  font-family: 'Georgia', serif;
  font-size: 14px;
  height: 100%;
  padding: 15px;
  border: 1px solid #222;
  overflow-y: auto;

  .RichEditor-editor {
    font-size: 16px;
    margin-top: 10px;
    cursor: text;
    border: 1px solid #222;
  }

  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }

  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }

  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }

  .RichEditor-editor .RichEditor-blockquote {
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
    border-left: 5px solid #eee;
  }

  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }

  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }
`;

const ToolbarWrapper = styled.div`
  padding: 10px 15px;
  width: 100%;
  border: 1px solid #222;
  border-bottom: none;
  button {
    cursor: pointer;
  }
  > div {
    display: flex;
    width: 100%;
    > div {
      width: 100%;
      display: flex;
    }
  }
`;

// const ToolbarInnerWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   width: 100%;
// `;
