/**
 *
 * MapStateIndicator
 *
 *  */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const MapStateIndicator = ({ editInProgress }) => {
  const text = editInProgress ? 'Edit In Progress' : 'Explore';

  if (!editInProgress) return null;
  return (
    <IndicatorContainer editInProgress={editInProgress}>
      {/* <Title>{text}</Title> */}
      <span>{text}</span>
    </IndicatorContainer>
  );
};

MapStateIndicator.propTypes = {
  editInProgress: PropTypes.bool.isRequired,
};

export default MapStateIndicator;

const IndicatorContainer = styled.div`
  ${({ editInProgress }) => {
    if (editInProgress) {
      return css`
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
        background-color: ${({ theme }) => theme.colors.lightGreen};
        color: white;
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        font-weight: 500;
        margin-right: 25px;
        padding: 7px;
        border: 3px solid white;
        border-radius: 5px;
        border-color: ${({ theme }) => theme.colors.lightGreen};
      `;
    }
  }};
`;

const Title = styled.h2`
  font-size: 1rem;
  font-family: 'Libre Baskerville', serif;
  font-weight: lighter;
`;

// switch (type) {
//   case 'editInProgress':
//     return css`
//       border-color: ${({ theme }) => theme.colors.LightGreen};
//     `;
//   default:
// }
