export const LOGIN_ROUTE = '/login';
export const SIGNUP_ROUTE = '/register';
export const ACCOUNT_ROUTE = '/account';
export const EXPLORE_ROUTE = '/search';
export const FAQ_ROUTE = '/faq';
export const TERMS_ROUTE = '/terms-and-conditions';

export const CHANGE_EMAIL_ROUTE = '/Auth/changeEmail';
export const CHANGE_PASSWORD_ROUTE = '/Auth/changePassword';
export const FORGOT_PASSWORD_ROUTE = '/forgotPassword';
export const RESET_PASSWORD_ROUTE = '/passwordReset';
