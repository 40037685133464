/**
 * DropZone
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

function containsFiles(event) {
  if (event.dataTransfer.types) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < event.dataTransfer.types.length; i++) {
      if (event.dataTransfer.types[i] === 'Files') {
        return true;
      }
    }
  }
  if (event.target && event.target.files && event.target.files[0]) {
    return true;
  }
  return false;
}

const DropZone = ({
  accept = '.png,.jpg,.jpeg,.svg',
  children,
  onDrop,
  hoverWithFile,
  setHoverWithFile,
  withHiddenInput = false,
  onInputChange,
  inputId,
}) => {
  const handleOnDragOver = evt => {
    if (containsFiles(evt)) {
      setHoverWithFile(true);
    }
    evt.preventDefault();
  };
  const handleOnDragLeave = () => {
    setHoverWithFile(false);
  };

  return (
    <Container
      hoverWithFile={hoverWithFile}
      onDragLeave={handleOnDragLeave}
      onDragOver={handleOnDragOver}
      onDrop={evt => {
        // console.log('onDrop');
        setHoverWithFile(false);
        onDrop(evt);
      }}
    >
      <>
        {withHiddenInput && (
          <HiddenInput
            id={inputId}
            type="file"
            accept={accept}
            onChange={onInputChange}
          />
        )}
        {children}
      </>
    </Container>
  );
};

DropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  hoverWithFile: PropTypes.bool.isRequired,
  setHoverWithFile: PropTypes.func.isRequired,
  accept: PropTypes.string,
  withHiddenInput: PropTypes.bool,
  onInputChange: PropTypes.func,
  inputId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default DropZone;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 3px;
  transition: border-color 0.3s;
  cursor: pointer;
  
  /* ${({ hoverWithFile }) =>
    hoverWithFile &&
    css`
      border-color: ${({ theme }) => theme.colors.Grey};
    `} */
`;

const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
`;
