/**
 *
 * Ripspot Pins
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

const RipspotWhite = ({
  fill,
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="250"
    height="250"
    viewBox="0 0 250 250"
  >
    <g
      id="Group_695"
      data-name="Group 695"
      transform="translate(-70.263 109.736)"
    >
      <path
        id="Subtraction_1"
        data-name="Subtraction 1"
        d="M97.263,137.264h-170a39.748,39.748,0,0,1-15.57-3.143,39.866,39.866,0,0,1-12.714-8.572,39.867,39.867,0,0,1-8.572-12.715,39.75,39.75,0,0,1-3.143-15.57V-34.762C-95.846-10.284-73.37-.343-42.263,13.417c7.006,3.1,14.25,6.3,21.972,9.918,1.869,9.7,1.192,16.329-2.069,20.281-2.789,3.38-7.394,4.89-14.931,4.894-2.184,11.833-5.793,34.7-5.945,35.672h23.782c.062-.018,6.309-1.779,14.171-3.5,7.3-1.6,17.588-3.5,25.148-3.5.536,0,1.059.01,1.555.028,1.012-.057,2.09-.086,3.206-.086,9.1,0,20.071,1.918,27.671,3.526,8.223,1.741,14.4,3.509,14.457,3.526H84.589L78.644,48.509a20.9,20.9,0,0,1-9.373-3.194,22.525,22.525,0,0,1-6.611-6.552,25.82,25.82,0,0,1-3.936-9.9,21.677,21.677,0,0,1,.505-9.986C61.7,12.009,68.737,8.138,75.543,4.4c1.84-1.012,3.578-1.968,5.237-2.985,13.21-8.1,40.521-19,51.65-23.316h3.716a1.168,1.168,0,0,0,1.115-1.186l0,120.355a39.968,39.968,0,0,1-40,40ZM-16.11-32.216h0c-19.883-.538-32.54-.891-41.782-1.149-18.711-.522-25.711-.718-54.844-1.4V-72.736a39.749,39.749,0,0,1,3.143-15.57,39.866,39.866,0,0,1,8.572-12.714,39.866,39.866,0,0,1,12.714-8.572,39.749,39.749,0,0,1,15.57-3.143h170a39.75,39.75,0,0,1,15.57,3.143,39.868,39.868,0,0,1,12.715,8.572,39.866,39.866,0,0,1,8.572,12.714,39.748,39.748,0,0,1,3.143,15.57v38.081a1.171,1.171,0,0,0-1.117-1.186L-16.11-35.887v3.669Z"
        transform="translate(183 3)"
        fill="#00b561"
      />
    </g>
  </svg>
);

RipspotWhite.propTypes = SVGPropTypes;

export default RipspotWhite;
