/**
 *
 * Search
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

const Search = ({ fill, width = '100%', height = '100%', style = {} }) => (
  <svg height={height} width={width} style={style} viewBox="0 0 24 24">
    <g data-name="Zoom">
      <path d="M20.71,19.29,15.6,14.18a7,7,0,1,0-1.42,1.42l5.11,5.11a1,1,0,0,0,1.42,0A1,1,0,0,0,20.71,19.29ZM5,10a5,5,0,1,1,5,5A5,5,0,0,1,5,10Z" />
    </g>
  </svg>
);

Search.propTypes = SVGPropTypes;

export default Search;
