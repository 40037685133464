/**
 * This where how we fee the dates options into readct-select simpleDatePicker
 *
 *		0-9
 *		Monday-Friday
 *    1900-2020
 *  */

// export const exampleOptions = [
//   { value: 'army', label: 'Chocolate' },
//   { value: 'navy', label: 'Navy' },
//   { value: 'airForce', label: 'Air Force' },
//   { value: 'marines', label: 'Marines' },
// ];

// Days
const zeroBasedDaysArray = [...Array(31).keys()]; // check IE11 support for this
const zeroBased31DaysArray = [...Array(31).keys()]; // check IE11 support for this
const zeroBased30DaysArray = [...Array(30).keys()]; // check IE11 support for this
const zeroBased28DaysArray = [...Array(28).keys()]; // check IE11 support for this
// get 1 based days
const daysArray = zeroBasedDaysArray.map(i => i + 1);
// different opts for each month
export const days31SelectArray = zeroBased31DaysArray.map(i => ({
  value: i,
  label: i + 1,
}));
export const days30SelectArray = zeroBased30DaysArray.map(i => ({
  value: i,
  label: i + 1,
}));
export const days28SelectArray = zeroBased28DaysArray.map(i => ({
  value: i,
  label: i + 1,
}));

// Month
export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getMonthFromZeroNum = monthNum => {
  switch (monthNum) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    case 11:
      return 'Dec';
    default:
  }
};

const months31Days = ['Jan', 'Mar', 'May', 'Jul', 'Aug', 'Oct', 'Dec'];
const months30Days = ['Apr', 'Jun', 'Sep', 'Nov'];
const months28Days = ['Feb'];

export const getDaysForMonthSelectOptions = month => {
  switch (true) {
    case months31Days.includes(month):
      return days31SelectArray;
    case months30Days.includes(month):
      return days30SelectArray;
    case months28Days.includes(month):
      return days28SelectArray;
    default:
      return days31SelectArray;
  }
};

// const monthByNumber = _keyBy(months, item => item.)

// oldest date we want to allow -- What about presidents? historical figures? People are very, very, very weird lolol
const OLDEST_DATE_YEAR = 1900;
// Years
export const yearOptionsHandler = () => {
  const today = new Date(Date.now());
  const currentYear = today.getFullYear();

  let startYear = OLDEST_DATE_YEAR; // oldest date allowed
  let years = [];

  while (startYear < currentYear) {
    const newYr = startYear + 1;
    years.push({ label: String(newYr), value: String(newYr) });
    startYear += 1;
  }

  return years.reverse();
};

export const dayOptions = daysArray.map((month, index) => ({
  value: index,
  label: month,
}));

export const monthOptions = months.map((month, index) => ({
  value: index,
  label: month,
}));

export const yearOptions = yearOptionsHandler();
