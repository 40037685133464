import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Theme from 'utils/theme';

const { colors } = Theme;

const Button = ({
  onClick,
  type = 'button',
  primary,
  secondary,
  tertiary,
  outline,
  light,
  underline,
  dangerous,
  bgColor,
  ...props
}) => (
  <StyledButton
    primary={primary}
    secondary={secondary}
    tertiary={tertiary}
    outline={outline}
    dangerous={dangerous}
    light={light}
    underline={underline}
    type={type}
    onClick={onClick}
    bgColor={bgColor}
    {...props}
  >
    {props.children}
  </StyledButton>
);

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  underline: PropTypes.bool,
  dangerous: PropTypes.bool,
  outline: PropTypes.bool,
  light: PropTypes.bool,
  type: PropTypes.string,
  bgColor: PropTypes.string,
};

export default memo(Button);

const StyledButton = styled.button`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
  /* padding: 0.75rem 2.5rem; */
  padding: 10px 15px;
  border-radius: 5px;
  transition: box-shadow 0.3s, background-color 0.3s;
  font-size: 18px;
  cursor: pointer;
  ${({ primary }) =>
    primary &&
    css`
      background-color: ${colors.Primary};
      color: #fff;
      &:hover {
        background-color: ${colors.alphaMod(colors.Primary, 0.75)};
      }
      &:active {
        background-color: ${colors.alphaMod(colors.Primary, 0.95)};
      }
    `}
  ${({ secondary }) =>
    secondary &&
    css`
      background-color: ${colors.midGreen};
      color: #fff;
      &:hover {
        background-color: ${colors.alphaMod(colors.midGreen, 0.75)};
      }
      &:active {
        background-color: ${colors.alphaMod(colors.midGreen, 0.95)};
      }
    `}
    ${({ light }) =>
      light &&
      css`
        color: #fff;
        background-color: ${colors.lightGreen};
        &:hover {
          background-color: ${colors.alphaMod(colors.lightGreen, 0.75)};
        }
        &:active {
          background-color: ${colors.alphaMod(colors.lightGreen, 0.95)};
        }
      `}
    ${({ tertiary }) =>
      tertiary &&
      css`
        color: grey;
        background-color: ${colors.lightGrey};
        &:hover {
          background-color: ${colors.alphaMod(colors.lightGrey, 0.75)};
        }
        &:active {
          background-color: ${colors.alphaMod(colors.lightGrey, 0.95)};
        }
      `}

    ${({ dangerous }) =>
      dangerous &&
      css`
        color: #fff;
        background-color: ${colors.Red};
        &:hover {
          background-color: ${colors.alphaMod(colors.Red, 0.75)};
        }
        &:active {
          background-color: ${colors.alphaMod(colors.Red, 0.95)};
        }
      `}


  ${({ disabled, primary, light, bgColor }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${colors.alphaMod(
        primary
          ? colors.Primary
          : light
          ? colors.lightGreen
          : bgColor || colors.disabled,
        0.35,
      )};
    `}


    ${({ outline, disabled }) =>
      outline &&
      css`
        background-color: transparent;
        color: #fff;
        font-size: 1rem;
        padding: 0.75rem 1rem;
        border: 2px solid ${({ theme }) => theme.colors.lightGreen};
        transition: border-color 0.3s;
        &:hover {
          border-color: ${({ theme }) =>
            theme.colors.alphaMod(theme.colors.lightGreen, 0.75)};
        }

        ${disabled &&
          css`
            opacity: 0.75;
          `}
      `}

   
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  ${({ underline }) =>
    underline &&
    css`
      box-shadow: none;
      color: grey;
      background-color: transparent;
      border-bottom: 1px solid grey;
      padding-bottom: 2px;
      border-radius: 0;
      transition: color 0.3s, border-color 0.3s;
      &:hover {
        color: lightgrey;
        border-color: lightgrey;
        box-shadow: none;
      }
      &:active {
      }
    `}
`;
