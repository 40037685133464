/**
 * Ripspots HTTP method calls
 *
 *
 */

import { apiGET, apiPOST, apiPUT, apiDELETE } from '../index';

import { RIPSPOTS, PUBLIC_RIPSPOTS } from '../urls';

const showLog = false;

// export const getMyRipspots = (query = {}) =>
//   new Promise((resolve, reject) => {
//     apiGET({ url: RIPSPOTS, query })
//       .then(res => {
//         showLog && console.log({ res });
//         resolve(res);
//       })
//       .catch(err => {
//         showLog && console.log({ err });
//         reject(err);
//       });
//   });

export const getRipspots = (query = {}) =>
  new Promise((resolve, reject) => {
    apiGET({ url: RIPSPOTS, query })
      .then(res => {
        // eslint-disable-next-line no-console
        showLog && console.log({ res });
        // console.log({ AllRispotssFetchRES: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        showLog && console.log({ err });
        reject(err);
      });
  });

export const getRipspotById = ripspotId =>
  new Promise((resolve, reject) => {
    apiGET({ url: `${RIPSPOTS}/${ripspotId}?populate=true` })
      .then(res => {
        // eslint-disable-next-line no-console
        showLog && console.log({ res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        showLog && console.log({ err });
        reject(err);
      });
  });

export const getPublicRipspotById = ripspotId =>
  new Promise((resolve, reject) => {
    apiGET({ url: `${PUBLIC_RIPSPOTS}/${ripspotId}?populate=true` })
      .then(res => {
        // eslint-disable-next-line no-console
        showLog && console.log({ res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        showLog && console.log({ err });
        reject(err);
      });
  });

export const createRipspot = (data = {}) =>
  new Promise((resolve, reject) => {
    apiPOST({ url: RIPSPOTS, data })
      .then(res => {
        // eslint-disable-next-line no-console
        showLog && console.log({ res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        showLog && console.log({ err });
        reject(err);
      });
  });

export const updateRipspot = (ripspotId, data = {}) =>
  new Promise((resolve, reject) => {
    apiPUT({ url: `${RIPSPOTS}/${ripspotId}`, data })
      .then(res => {
        // eslint-disable-next-line no-console
        showLog && console.log({ res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        showLog && console.log({ err });
        reject(err);
      });
  });

export const addAdminToRipspot = (ripspotId, data = {}) =>
  new Promise((resolve, reject) => {
    apiPUT({ url: `${RIPSPOTS}/${ripspotId}/admins`, data })
      .then(res => {
        // eslint-disable-next-line no-console
        showLog && console.log({ res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        showLog && console.log({ err });
        reject(err);
      });
  });

export const deleteRipspot = ripspotId =>
  new Promise((resolve, reject) => {
    apiDELETE({ url: `${RIPSPOTS}/${ripspotId}` })
      .then(res => {
        // eslint-disable-next-line no-console
        showLog && console.log({ res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        showLog && console.log({ err });
        reject(err);
      });
  });
