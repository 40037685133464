/**
 *
 * ChangeEmail
 *
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import Link from 'components/Elements/Link';
import { logout } from 'actions/auth';

import { ACCOUNT_ROUTE, CHANGE_PASSWORD_ROUTE } from 'utils/routes';
import { updateMyEmail } from 'utils/api/users';
import { sendConfirmAccountLink } from 'utils/api/auth';
import { toastSuccess } from 'utils/toastify';

import ChangeEmailFormik from './ChangeEmailFormik';

const ChangeEmail = ({ dispatch }) => (
  <Container>
    <Title>Change Email</Title>
    <ChangeEmailFormik
      initialValues={{
        email: '',
        newEmail: '',
        newReEmail: '',
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          const data = {
            email: values.email,
            newEmail: values.newEmail,
          };
          const res = await updateMyEmail(data);
          if (res) {
            const emailRes = await sendConfirmAccountLink(values.newEmail);
            toastSuccess(
              'Successfully updated your email! Please check your new email for a confirmation link!',
            );
            dispatch(logout());
            navigate('/login', { state: { email: values.newEmail } });
          }
          setSubmitting(false);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log({ err });
          if (err.code && err.code === 422) {
            setFieldError(err.location, err.message);
          }
          setSubmitting(false);
        }
      }}
    />

    <Link style={{ marginBottom: '15px' }} primary to={ACCOUNT_ROUTE}>
      My Account
    </Link>
    <Link primary to={CHANGE_PASSWORD_ROUTE}>
      Change Password
    </Link>
  </Container>
);

ChangeEmail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ChangeEmail);

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  background-color: #f3f2f1;
  background-color: #f9f9f8;
  color: ${({ theme }) => theme.colors.Primary};
  max-width: 500px;
  margin: 100px auto 0;
  padding: 15px;
  border-radius: 3px;
`;

const Title = styled.h2``;
