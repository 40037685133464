import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Theme from 'utils/theme';
import { SUB_PRICE, SINGLE_CHARGE_PRICE } from 'utils/constants';

const { colors } = Theme;

const PriceBlock = ({ fontSizeOr, noMargin = false, className, isAnnual }) => (
  <PriceContainer noMargin={noMargin} className={className}>
    <div className={isAnnual ? 'active price-box' : 'price-box'}>
      <p className="bold">
        <sup>$</sup>
        {SUB_PRICE}
      </p>
      <p className="desc">
        billed
        <br /> annualy
      </p>
    </div>
    <span style={{ fontSize: fontSizeOr }}>or</span>
    <div className={!isAnnual ? 'active price-box' : 'price-box'}>
      <p className="bold">
        <sup>$</sup>
        {SINGLE_CHARGE_PRICE}
      </p>
      <p className="desc">
        one-time <br /> payment
      </p>
    </div>
  </PriceContainer>
);

PriceBlock.propTypes = {
  fontSizeOr: PropTypes.string,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
};

export default memo(PriceBlock);

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme, noMargin }) => theme.media.min.mobile`
    flex-direction: row;
    justify-content: space-between;
    > div:first-of-type {
      margin-left: ${noMargin ? 0 : 4.5}em;
    }
  `}

  > div {
    display: flex;
    align-items: flex-end;
  }

  p.bold {
    font-family: 'Libre Baskerville', serif;
    font-weight: 700;
    font-size: 6em;
    line-height: 1em;
    display: flex;
    sup {
      font-size: 0.6em;
      align-self: flex-start;
      line-height: 1em;
    }
  }

  p.desc {
    line-height: 1em;
    margin-bottom: 0.4em;
  }

  span {
    font-family: 'Libre Baskerville', serif;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-style: italic;
    font-size: 2em;
    margin: 1.5em 0;
    &:before,
    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;
      width: 10px;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.darkGrey};
    }
  }
`;
