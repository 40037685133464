/**
 * Users HTTP method calls
 *
 *
 */
import { apiGET, apiPOST, apiPUT, apiDELETE } from '../index';

import {
  USERS,
  RESET_USER_PASSWORD,
  FOROGT_PASSWORD,
  getValidateResetTokenURL,
} from '../urls';

/**
 * GET users
 *
 */
export const getUsers = (query = {}) =>
  new Promise((resolve, reject) => {
    // const options = {
    //   limit: 30,
    //   page: 0,
    // };
    apiGET({ url: USERS, query })
      .then(res => {
        // // eslint-disable-next-line no-console
        // console.log({ getUsersRes: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log({ err });
        reject(err);
      });
  });

export const getUserById = userId =>
  new Promise((resolve, reject) => {
    apiGET({ url: `${USERS}/${userId}?populate=true` })
      .then(res => {
        // // eslint-disable-next-line no-console
        // console.log({ getUserByIdRes: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log({ err });
        reject(err);
      });
  });

export const updateMe = data =>
  new Promise((resolve, reject) => {
    apiPUT({ url: `${USERS}/me`, data })
      .then(res => {
        // // eslint-disable-next-line no-console
        // console.log({ updateMeRes: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log({ err });
        reject(err);
      });
  });

export const updateMyEmail = data =>
  new Promise((resolve, reject) => {
    apiPUT({ url: `${USERS}/me/email`, data })
      .then(res => {
        // // eslint-disable-next-line no-console
        // console.log({ updateMyEmailRes: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log({ err });
        reject(err);
      });
  });

export const confirmUserById = userId =>
  new Promise((resolve, reject) => {
    apiPUT({
      url: `${USERS}/${userId}`,
      data: {
        isConfirmed: true,
      },
    })
      .then(res => {
        // // eslint-disable-next-line no-console
        // console.log({ confirmUserByIdRes: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log({ err });
        reject(err);
      });
  });

/**
 * CREATE user
 *
 * @param {object} data
 */
export const createUser = (data, recaptchaToken) =>
  new Promise((resolve, reject) => {
    apiPOST({ url: USERS, data, recaptchaToken, useAuth: false })
      .then(res => {
        // // eslint-disable-next-line no-console
        // console.log({ createUserRes: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log({ err });
        reject(err);
      });
  });

/**
 * Validate resetToken
 *
 * @param {object} data
 */
export const validateToken = tokenId =>
  new Promise((resolve, reject) => {
    const url = getValidateResetTokenURL(tokenId);
    apiGET({ url, useAuth: false })
      .then(res => {
        // // eslint-disable-next-line no-console
        // console.log({ validateTokenRes: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log({ err });
        reject(err);
      });
  });

/**
 *
 *
 * @param {object} data { email }
 * @param {object} data { password }
 * @param {object} data { tokenId }
 */
export const resetUserPassword = data =>
  new Promise((resolve, reject) => {
    apiPUT({ url: RESET_USER_PASSWORD, data, useAuth: false })
      .then(res => {
        // // eslint-disable-next-line no-console
        // console.log({ resetUserPasswordRes: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log({ err });
        reject(err);
      });
  });

/**
 *
 *
 * @param {object} data { email }
 */
export const forgotPassword = ({ email }) =>
  new Promise((resolve, reject) => {
    const data = { email };
    apiPOST({ url: FOROGT_PASSWORD, data, useAuth: false })
      .then(res => {
        // // eslint-disable-next-line no-console
        // console.log({ forgotPasswordRes: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log({ err });
        reject(err);
      });
  });

/**
 *
 * Deletes the current user's account..gets challeneged so data is not lost unintenntionally
 *
 */
export const deleteMe = () =>
  new Promise((resolve, reject) => {
    apiDELETE({ url: USERS })
      .then(res => {
        // // eslint-disable-next-line no-console
        // console.log({ deleteMeRes: res });
        resolve(res);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log({ err });
        reject(err);
      });
  });
