/**
 *
 * Account Page
 *
 *
 */

import React, { useEffect, memo } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Account from 'containers/Account';
import SEO from 'components/seo';
import theme from 'utils/theme';

const AccountPage = ({ user }) => {
  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  if (!user) {
    return null;
  }
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <SEO title="Account" />
        <Account />
      </Container>
    </ThemeProvider>
  );
};

AccountPage.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ user }) => ({ user });
const withConnect = connect(mapStateToProps);
export default compose(
  memo,
  withConnect,
)(AccountPage);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
