/**
 *
 * Handles Ripspot PhotoGallery uploads
 *
 *
 */

import { createImage } from 'utils/api/image';
import { updateRipspot } from 'utils/api/ripspots';
import { uploadImage } from 'utils/s3/upload';

import { toastUpdate } from 'utils/toastify';

/**
 * Handles uploading images to S3 and then updating ripspot with S3 image urls
 *
 * @param {string} ripspotId
 * @param {{}} photoGallery
 */
export const uploadPhotoGallery = (ripspotId, photoGallery, toastId) =>
  new Promise(async (resolve, reject) => {
    try {
      // upload images to S3
      const uploadResponses = await Promise.all(
        Object.keys(photoGallery).map(key => {
          const photoData = photoGallery[key];
          const { data, isProfile, photoText } = photoData;
          const { file } = data;
          const fileParts = file.name.split('.');
          const fileName = fileParts[0];
          const fileType = fileParts[1];
          return uploadImage({
            file,
            fileName,
            fileType,
            isProfile,
            photoText,
          });
        }),
      );

      toastUpdate(toastId, 'Creating images on our server...');
      // create Image objects backing each S3 object
      const creationResponses = await Promise.all(
        uploadResponses.map(uploadRes => {
          const { url, isProfile, photoText } = uploadRes;
          //
          // TODO: set profile image on User's object
          //
          return createImage({ url, description: photoText });
        }),
      );

      const photoGalleryIds = creationResponses.map(item => item.id);

      toastUpdate(toastId, 'Updating ripspot with images...');
      updateRipspot(ripspotId, { photoGallery: photoGalleryIds })
        .then(res => {
          // eslint-disable-next-line no-console
          console.log({ updateRipspot_Response: res });
          resolve(res);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          reject(err);
        });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      reject(err);
    }
  });
