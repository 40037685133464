/**
 *
 * Vimeo
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Vimeo = ({ vimeoUrl }) => (
  <EmbedContainer>
    <iframe
      src={vimeoUrl}
      title="vimeo Ripspot promo"
      width="640"
      height="360"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
    />
  </EmbedContainer>
);

Vimeo.propTypes = {
  vimeoUrl: PropTypes.string,
};

export default Vimeo;

const EmbedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  iframe {
    width: 120%;
    height: 120%;
    position: absolute;
    top: -10%;
    left: -10%;
  }
`;
