/* eslint-disable import/no-named-as-default-member */
/**
 *
 * PhotoGallery to S3
 *
 * Used inside the Memorial Page/Modal
 *
 */

import React from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

// import SlickSliderOG from 'components/lib/Slick';
import SlickSlider from 'components/lib/Slick/v2';

import PhotoCard from './PhotoCard';

const PhotoGallery = ({
  photoGallery,
  isReview = false,
  isPreview = false,
  ...props
}) => {
  const showSlider = photoGallery && photoGallery.length > 0;
  if (!showSlider) {
    return null;
  }

  const numSlides = (photoGallery && photoGallery.length) || 0;
  const isInfinite = numSlides > 3;

  return (
    <SlickSlider
      {...props}
      //
      // these settings ovveride default ones
      //
      settings={{
        arrows: true,
        centerMode: true, //
        centerPadding: 0, // this is needed for 700 - 800px so it doesnt show more than 3 slides
        infinite: isInfinite, // fixes bug when we have 1-2 slides and centerMode true in infinite mode
        slidesToScroll: 1, // needed for centerMode
        focusOnSelect: true,
      }}
      slides={photoGallery}
      renderSlide={(slide, index) => (
        <PhotoCard
          key={index}
          image={slide}
          index={index}
          isReview={isReview}
          isPreview={isPreview}
        />
      )}
    />
  );
};

PhotoGallery.propTypes = {
  photoGallery: PropTypes.any,
  isReview: PropTypes.bool,
  isPreview: PropTypes.bool,
};

export default PhotoGallery;
