/* eslint-disable prefer-destructuring */
/**
 *
 * shouldShowRandomPins
 *
 *
 */
import { getRipspots } from 'utils/api/ripspots';

// TODO: this is the correct met6hod. But we had one ine redux...move thbis to Redux and replace this one, just grab data needed from store
// ------------------------------------------------
// should show show 100 default ripspot pins
// ------------------------------------------------
export const shouldShowRandomPins = ({
  setPublicRipspots,
  dispatch,
  setPublicPins,
  limit = 100,
  query = {},
}) =>
  new Promise(async (resolve, reject) => {
    let RipspotList;
    try {
      // console.log('about to fetch api call for 100 ripspots');
      RipspotList = await getRipspots({ limit, ...query });
      // eslint-disable-next-line no-console
      console.log({ RipspotList });
      // we need to save these so that we can view the memorial
      setPublicRipspots(RipspotList);
      // dispatch(setPublicRipspots(RipspotList))
    } catch (useFetchRipspotsError) {
      // eslint-disable-next-line no-console
      console.log({ useFetchRipspotsError });
      return reject(useFetchRipspotsError);
    }

    // // eslint-disable-next-line no-console
    // console.log({ RipspotList });

    try {
      const publicRandomPins =
        RipspotList &&
        RipspotList.length > 0 &&
        RipspotList.reduce((acc, ripspot) => {
          // // eslint-disable-next-line no-console
          // console.log({ acc, ripspot });

          if (!ripspot.isPublic || !ripspot.isActive) return acc;

          // // eslint-disable-next-line no-console
          // console.log({ ActivePinsList: ripspot.pins });
          const defaultRipspotPin =
            ripspot.pins && ripspot.pins.length > 0 && ripspot.pins[0];

          if (!defaultRipspotPin) return acc;

          // console.log('\n\n');
          // console.log({ BEFOREdefaultRipspotPin: { ...defaultRipspotPin } });
          // console.log({ RIPSPOT: ripspot });

          defaultRipspotPin.ripspot = {
            name: ripspot.name,
            birthDate: ripspot.birthDate,
            deathDate: ripspot.deathDate,
            id: ripspot.id,
          };
          // console.log({ defaultRipspotPin });
          // console.log('\n\n');
          return {
            ...acc,
            [defaultRipspotPin.id]: defaultRipspotPin,
          };
        }, {});

      // eslint-disable-next-line no-console
      // console.log({ publicRandomPins });

      if (publicRandomPins) {
        // eslint-disable-next-line no-console
        // console.log('\n\n about to dispatch publicRandomPins \n\n');
        dispatch(setPublicPins(publicRandomPins));
      }

      // return formatted data
      resolve(publicRandomPins);
      // setPublicRipspotPins(publicRandomPins);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      // eslint-disable-next-line no-console
      console.log({ err });
      // eslint-disable-next-line no-console
      return reject(err);
    }
  });

export default shouldShowRandomPins;
