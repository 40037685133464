/* eslint-disable prefer-destructuring */
/**
 *
 * Ripspot Memorial Photo Card
 *
 * Uses Medium.com style zoom library to achieve full screen takeover effect
 *
 */

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';

import 'react-medium-image-zoom/dist/styles.css';

const PhotoCard = ({ isReview, isPreview, image, ...rest }) => {
  let url = 'https://source.unsplash.com/random';
  let description = '';

  if (isPreview) {
    url = (image && image.data && image.data.imageSrc) || '';
    description = image.photoText || '';
  } else if (!isReview) {
    url = image.url;
  } else {
    url = (image && image.data && image.data.imageSrc) || '';
    description = image.photoText || '';
  }

  const [isZoomed, setIsZoomed] = useState(false);

  const handleImgLoad = useCallback(() => {
    // // eslint-disable-next-line no-console
    // console.log('handleImgLoad');
    // // eslint-disable-next-line no-console
    // console.log('setIsZoomed(true);');
    // setIsZoomed(true);
  }, []);

  const handleZoomChange = useCallback(shouldZoom => {
    // // eslint-disable-next-line no-console
    // console.log('handleZoomChange');
    // // eslint-disable-next-line no-console
    // console.log({ shouldZoom });
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <Container>
      <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        <img
          alt="Ripspot gallery slide"
          onLoad={handleImgLoad}
          src={url}
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
        />
      </ControlledZoom>
      {description && (
        <Description>
          <Label>{description}</Label>
        </Description>
      )}
    </Container>
  );
};

PhotoCard.propTypes = {
  image: PropTypes.any.isRequired,
  url: PropTypes.string,
  description: PropTypes.string,
  isReview: PropTypes.bool,
  isPreview: PropTypes.bool,
};

export default PhotoCard;

const Container = styled.div`
  ${({ theme }) => theme.styles.boxShadows['1']}
  width: 150px;
  height: 150px;
  margin: 0 auto;
`;

// const ImageBackground = styled.div`
//   width: 100%;
//   height: 100%;
//   background-image: ${({ url }) => `url('${url}')`};
//   background-size: cover;
//   background-repeat: no-repeat;
// `;

const Description = styled.div`
  padding: 15px 0px;
`;

const Label = styled.label`
  font-family: 'Libre Baskerville', serif;
  font-size: 1.3rem;
`;
