/**
 *
 * Select Pin Modal
 *
 *
 */

import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import Theme from 'utils/theme';

// import Close from 'components/icons/Close';
import Button from 'components/Elements/Button';
// import Checkbox from 'components/Elements/Checkbox';
import CheckboxV2 from 'components/Elements/Checkbox/v2';

import ReactSelect from 'components/lib/ReactSelect';
import Modal from 'components/modals/index';

import PinsList from './PinsList';

// const { colors } = Theme;

const options = [
  { value: 'army', label: 'Army' },
  { value: 'navy', label: 'Navy' },
  { value: 'airForce', label: 'Air Force' },
  { value: 'spaceForce', label: 'Space Force' },
  { value: 'marines', label: 'Marines' },
  { value: 'coastGuard', label: 'Coast Guard' },
];

const SelectPinModal = ({
  isOpen,
  onRequestClose,
  onConfirm,
  currentPinName,
}) => {
  const [isArmedServices, setIsArmedServices] = useState(false);
  const [branch, setBranch] = useState('');
  const [currentPin, setCurrentPin] = useState(currentPinName);

  const handleConfirmClick = () => {
    // eslint-disable-next-line no-console
    // console.log({ currentPin });
    onConfirm(currentPin);
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    // console.log(`setting local modal pin: ${currentPinName}`);
    setCurrentPin(currentPinName);
  }, [currentPinName]);

  return (
    <Modal
      noOverlay
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      alignItems="flex-start"
      spacing={40}
      closeIconSize="md"
      title="Select a pin style."
    >
      <Container>
        {/* <Title>Please select a pin style.</Title> */}
        <Content>
          <p>
            You can customize a pin with a religious icon, emblem, or choose a
            pin for someone who served in the military. If you choose a veteran
            style pin, you will be asked to designate their branch of service.
            If you have a suggestion for a pin not shown here, feel free to
            contact us.
          </p>
          <PinsList
            currentPin={currentPin}
            setCurrentPin={setCurrentPin}
            setIsArmedServices={setIsArmedServices}
            branch={branch}
          />
          {/* <CheckboxWrapper>
            <CheckboxV2
              checkFill="#33333399"
              initialValue={isArmedServices}
              value={isArmedServices}
              onChange={checked => {
                setIsArmedServices(checked);
                if (!checked) {
                  setBranch('');
                }
              }}
              renderLabel={() => <LabelText>U.S. Veteran</LabelText>}
            />
          </CheckboxWrapper> */}
          <Controls>
            <AnimatedWrapper isOpen={isArmedServices}>
              <Label>Branch:</Label>
              <ReactSelect
                portal
                placeholder="Select"
                value={branch}
                menuPlacement="auto"
                options={options}
                handleChange={opt => setBranch(opt)}
              />
            </AnimatedWrapper>
            <StyledButton
              light
              disabled={!currentPin}
              onClick={handleConfirmClick}
            >
              Confirm
            </StyledButton>
          </Controls>
        </Content>
      </Container>
    </Modal>
  );
};

SelectPinModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  currentPinName: PropTypes.string,
};

const mapStateToProps = ({ display }) => ({ display });
const withConnect = connect(mapStateToProps);

export default compose(memo, withConnect)(SelectPinModal);

const Container = styled.div`
  height: 100%;
  padding: 15px;
  overflow: hidden;
  @media screen and (min-width: 600px) {
    width: 600px;
  }
`;

const Content = styled.div`
  padding-top: 25px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
`;

const CheckboxWrapper = styled.div`
  padding: 15px 0px;
`;

const Controls = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 85px;
`;

const AnimatedWrapper = styled.div`
  ${({ isOpen, theme }) => theme.styles.showhideStyles(isOpen, 0.5)}

  width: 100%;
  max-width: 160px;
  margin-right: 25px;
`;

const StyledButton = styled(Button)`
  /* background-color: ${({ theme }) => theme.colors.lightGreen}; */
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-left: 10px;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.darkGrey};
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  padding: 15px 0px;
`;

const LabelText = styled.span`
  color: ${({ theme }) => theme.colors.darkGrey};
`;
