/**
 *
 * BurgerMenu
 *
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import Theme from 'utils/theme';

const { colors } = Theme;

const BurgerMenu = memo(({ menuIsOpen, onClick, type, style }) => (
  <Container onClick={onClick} style={style} type={type || 'button'}>
    <Menu menuIsOpen={menuIsOpen} />
  </Container>
));

BurgerMenu.propTypes = {
  menuIsOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.object,
};
const mapStateToProps = ({ display }) => ({
  menuIsOpen: display.menuIsOpen,
});
export default connect(mapStateToProps)(BurgerMenu);

const burgerOpen = keyframes`
   0% {
      background-color: ${colors.alphaMod(colors.Secondary, 1)};
  }
  50% {
      background-color: ${colors.alphaMod(colors.Secondary, 1)};
  }
  51% {
       background-color: ${colors.alphaMod(colors.Secondary, 0)};
  }
  100% {
       background-color: ${colors.alphaMod(colors.Secondary, 0)};
  }
`;

const burgerTopOpen = keyframes`
  0% {
      top: -10px;
      transform: none;
  }
  50% {
      top: 0;
      transform: none;
  }
  90% {
      top: 0;
      transform: rotate3d(0, 0, 1, 55deg);
  }
  100% {
      top: 0;
      transform: rotate3d(0, 0, 1, 45deg);
  }
`;

const burgerBotOpen = keyframes`
   0% {
      bottom: -10px;
      transform: none;
  }
  50% {
      bottom: 0;
      transform: none;
  }
  90% {
      bottom: 0;
      transform: rotate3d(0, 0, 1, -55deg);
  }
  100% {
      bottom: 0;
      transform: rotate3d(0, 0, 1, -45deg);
  }
`;

const burgerClosed = keyframes`
 0% {
       background-color: ${colors.alphaMod(colors.Secondary, 0)};
  }
  50% {
       background-color: ${colors.alphaMod(colors.Secondary, 0)};
  }
  75% {
      background-color: ${colors.alphaMod(colors.Secondary, 1)};
  }
  100% {
      background-color: ${colors.alphaMod(colors.Secondary, 1)};
  }
`;

const burgerTopClosed = keyframes`
  0% {
      top: 0;
      transform: rotate3d(0,0,1,-45deg);
  }
  50% {
      top: 0;
      transform: none;
  }
  90% {
      top: -12px;
      transform: none;
  }
  100% {
      top: -10px;
      transform: none;
  }
`;

const burgerBotClosed = keyframes`
  0% {
      bottom: 0;
      transform: rotate3d(0,0,1,45deg);
  }
  50% {
      bottom: 0;
      transform: none;
  }
  90% {
      bottom: -12px;
      transform: none;
  }
  100% {
      bottom: -10px;
      transform: none;
  }
`;

const Container = styled.button`
  background-color: transparent;
  position: relative;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

const Menu = styled.div`
  background-color: ${colors.Secondary};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 3px;
  transition: background-color 0.15s;

  &::before,
  &::after {
    content: '';
    background-color: ${colors.Secondary};
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    transition: background-color 0.15s, transform 0.3s;
  }

  &::before {
    top: 12px;
  }
  &::after {
    bottom: 12px;
  }

  ${({ menuIsOpen }) =>
    menuIsOpen
      ? css`
          animation: ${burgerOpen} 0.3s forwards;
          &::before {
            animation: ${burgerTopOpen} 0.3s forwards;
          }
          &::after {
            animation: ${burgerBotOpen} 0.3s forwards;
          }
        `
      : css`
          animation: ${burgerClosed} 0.3s forwards;
          &:before {
            animation: ${burgerTopClosed} 0.3s forwards;
          }
          &:after {
            animation: ${burgerBotClosed} 0.3s forwards;
          }
        `};
`;
