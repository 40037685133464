/**
 *
 * Registration
 *
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import faker from 'faker';

import { sendConfirmAccountLink } from 'utils/api/auth';
import { createUser } from 'utils/api/users';
// import { ACCOUNT_ROUTE } from 'utils/routes';
import { toastSuccess, toastError } from 'utils/toastify';
import RegistrationFormik from './RegistrationFormik';

const isDev = process.env.NODE_ENV !== 'production';

const errorHandler = ({ err, setFieldError }) => {
  if (err.code) {
    if (err.code === 422) {
      setFieldError(err.location, err.message);
    } else if (err.code === 401) {
      setFieldError('server', err.message);
    }
  }
};

// const rando = faker.helpers.randomize();
const num = 2;
let rando = num + 1;
const devEmail = `mikeschmerbeck+${rando}@gmail.com`;

const initialValues = {
  firstName: isDev ? faker.name.firstName() : '',
  lastName: isDev ? faker.name.lastName() : '',
  email: isDev ? devEmail : '', // faker.internet.email() : '',
  password: isDev ? 'Passwordpass9292!' : '',
  confirmPass: isDev ? 'Passwordpass9292!' : '',
  terms: isDev ? true : '',
};

const Registration = ({
  dispatch,
  handleRedirect,
  closeModal,
  setTempData,
  getTempData,
  isModal = false,
}) => {
  return (
    <Container>
      {!isModal && <Title>Sign-up</Title>}
      <RegistrationFormik
        setTempData={setTempData}
        getTempData={getTempData}
        handleRedirect={handleRedirect}
        initialValues={initialValues}
        // eslint-disable-next-line
        onSubmit={async (
          values,
          { setSubmitting, setFieldError },
          recaptchaTokenV2,
        ) => {
          setTempData && setTempData('signup', initialValues);
          console.log({ recaptchaTokenV2 });
          //
          // fetch user
          //
          let userRes;
          try {
            userRes = await createUser({ ...values }, recaptchaTokenV2); // V2 is from second captcha grabbed on page, first on mount fopr testing
            // eslint-disable-next-line no-console
            console.log({ userRes });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log({ err });
            toastError(
              err.msg || 'Sorry, something went wrong with our server.',
            );
            setSubmitting(false);
            errorHandler({ err, setFieldError });

            // make sure to stop code executuion
            return;
          }

          // eslint-disable-next-line no-console
          console.log({ userRes });

          /** ***************** REMOVE BEFORE STAGING *****************  */
          // toastSuccess(
          //   'Successfully registered! Please check your email for a confirmation link!',
          // );
          // navigate('/login', { state: { email: values.email } });
          // setSubmitting(false);
          // closeModal && closeModal();
          /** ***************** REMOVE BEFORE STAGING ^^^ *****************  */

          //
          //  after user creation success, send them a confirm link to that email
          //
          try {
            const emailRes = await sendConfirmAccountLink(values.email);
            // eslint-disable-next-line no-console
            console.log({ emailRes });
            if (emailRes === 'User already confirmed!') {
              setSubmitting(false);
              toastError('User already confirmed!');
              return;
            }

            toastSuccess(
              'Successfully registered! Please check your email for a confirmation link!',
            );
            navigate('/login', { state: { email: values.email } });
            setSubmitting(false);
            closeModal && closeModal();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log({ err });
            toastError(
              err.msg ||
                'Sorry, something went wrong with our server sending a comnfirmation email...',
            );
            navigate('/login', { state: { email: values.email } });
            setSubmitting(false);
            errorHandler({ err, setFieldError });
          }
        }}
      />
    </Container>
  );
};

Registration.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func,
  setTempData: PropTypes.func,
  getTempData: PropTypes.func,
  closeModal: PropTypes.func,
  isModal: PropTypes.bool,
};

const mapStateToProps = ({ user }) => ({ user });
const withConnect = connect(mapStateToProps, dispatch => ({ dispatch }));
export default compose(memo, withConnect)(Registration);

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  padding: 15px;
`;
