/**
 *
 * AdminRipspot -- Admin area for ripspot
 *
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { animated, useSpring } from 'react-spring';

// import useMeasure from 'utils/hooks/useMeasure';

import { addAdminToRipspot } from 'utils/api/ripspots';

import AdminFormik from './AdminFormik';
import AdminItem from './AdminItem';

const sharedUsers = [
  { email: 'modoe@gmail.com' },
  { email: 'aldoe@gmail.com' },
];

const AdminRipspot = ({ isOpen, ripspotId, ripspot }) => {
  const [isLoading, setIsLoading] = useState(false);

  // const [bind, { height }] = useMeasure();
  const height = 400;
  const props = useSpring({
    height: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'translate3d(0,0,0)' : 'translate3d(20px,0,0)',
  });

  /**
   *
   *
   * @param {*} values
   * @param {*} { setSubmitting }
   */
  const handleShareSubmit = async (
    values,
    { setSubmitting, setFieldError },
  ) => {
    // eslint-disable-next-line no-console
    console.log('handleShare');
    // eslint-disable-next-line no-console
    console.log({ ripspot });
    try {
      setIsLoading(true);
      const { email } = values;
      // eslint-disable-next-line no-console
      console.log({ email });
      const res = await addAdminToRipspot(ripspotId, { email });
      // eslint-disable-next-line no-console
      console.log({ addAdminToRipspotRes: res });

      // need to update ripspot in store with ne wowner
      setIsLoading(false);
      setSubmitting(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log({ err });
      setIsLoading(false);
      setSubmitting(false);
      setFieldError('email', err.message);
    }
  };

  const handleTransfer = email => {
    // eslint-disable-next-line no-console
    console.log('handleTransfer');
    // eslint-disable-next-line no-console
    console.log({ ripspot });
  };

  const handleAdminItemClick = adminItem => evt => {
    // eslint-disable-next-line no-console
    console.log('handleAdminItemClick');
    // eslint-disable-next-line no-console
    console.log({ adminItem });
  };

  const admins = ripspot && ripspot.ownership && ripspot.ownership.admins;

  return (
    <animated.div
      style={{ marginBottom: '20px', overflow: 'hidden', ...props }}
    >
      <Container isOpen={isOpen}>
        {/* TOP */}
        <FormSection>
          <Title>Admin</Title>
          <Description>
            Share administrator rights with another member. Just enter their
            email address.
          </Description>
          {/* // Share FORM */}
          <AdminFormik submitText="Share" onSubmit={handleShareSubmit} />
          <CurrentAdmin>
            {admins && admins.length > 0 ? (
              <>
                <label htmlFor={`${ripspotId}-shared-list`}>
                  Currently sharing with:
                </label>
                <List id={`${ripspotId}-shared-list`}>
                  {admins.map(adminUser => (
                    <AdminItem
                      key={adminUser._id || adminUser.email}
                      adminUser={adminUser}
                      onClick={handleAdminItemClick(adminUser)}
                    />
                  ))}
                </List>
              </>
            ) : (
              <label>Not currently sharing Ripspot.</label>
            )}
          </CurrentAdmin>
        </FormSection>
        {/* BOTTOM */}
        <FormSection>
          <Title>Transfer</Title>
          <Description>
            Transfer ownership of this account to another user. Simply enter
            their email address.
          </Description>
          {/* // Transfer FORM */}
          <AdminFormik
            isTransfer
            submitText="Transfer"
            onSubmit={(values, { setSubmitting }) => {
              // eslint-disable-next-line no-console
              console.log({ Transfer: values });
              handleTransfer(values.email);
              setSubmitting(false);
            }}
          />
        </FormSection>
      </Container>
    </animated.div>
  );
};

AdminRipspot.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  ripspotId: PropTypes.string.isRequired,
  ripspot: PropTypes.any.isRequired,
};

export default AdminRipspot;

const Container = styled.div`
  padding-bottom: 30px;
  visibility: hidden;
  transition: visibility 0.3s ease 0.3s;

  ${({ theme }) => theme.media.min.tablet`
    display: flex;
    justify-content: space-between;
  `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      transition: visibility 0.3s;
    `}
`;

const FormSection = styled.div`
  margin: 15px 0 20px;

  ${({ theme }) => theme.media.max.tablet`
    &:nth-of-type(2) {
      margin-top: 25px;
    }
  `}
  ${({ theme }) => theme.media.min.tablet`
    flex: 1;
    &:first-of-type {
      margin-right: 25px;
    }
  `}
  ${({ theme }) => theme.media.min.desktop`
    &:first-of-type {
      margin-right: 55px;
    }
  `}
`;

const Title = styled.h2`
  margin-bottom: 15px;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.midGrey};
  margin-bottom: 15px;
`;

const CurrentAdmin = styled.div`
  margin-top: 15px;
  font-size: 1rem;
  label {
    color: ${({ theme }) => theme.colors.midGrey};
    display: inline-block;
    margin-bottom: 10px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;

  li {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
`;
