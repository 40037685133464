/**
 *
 * ChangeEmailFormik
 *
 *
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import Spinner from 'components/Spinner';
import Button from 'components/Elements/Button';
import { FormWrapper, StyledField } from 'components/lib/Formik';

import validate from './validators';

const ChangeEmailFormik = ({ initialValues, onSubmit }) => {
  const handleChange = useCallback((field, setFieldValue) => evt => {
    const { value } = evt.target;
    setFieldValue(field, value);
  });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        // eslint-disable-next-line
        ...rest
      }) => {
        return (
          <Form style={{ position: 'relative' }}>
            <FormWrapper>
              <Spinner loading={isSubmitting} cover />
              <FormFields>
                <StyledField
                  // useAnimated
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  touched={touched}
                  errors={errors}
                  value={values.email}
                  onBlur={() => setFieldTouched('email', true)}
                  onChange={handleChange('email', setFieldValue)}
                />
                <StyledField
                  // useAnimated
                  type="email"
                  name="newEmail"
                  placeholder="New Email Address"
                  touched={touched}
                  errors={errors}
                  value={values.newEmail}
                  onBlur={() => setFieldTouched('newEmail', true)}
                  onChange={handleChange('newEmail', setFieldValue)}
                />
                <StyledField
                  // useAnimated
                  type="email"
                  name="newReEmail"
                  placeholder="Re-Enter New Email Address"
                  touched={touched}
                  errors={errors}
                  value={values.newReEmail}
                  onBlur={() => setFieldTouched('newReEmail', true)}
                  onChange={handleChange('newReEmail', setFieldValue)}
                />
              </FormFields>

              <Controls>
                <Button light type="submit" disabled={isSubmitting}>
                  Send Via Email
                </Button>
              </Controls>
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

ChangeEmailFormik.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default ChangeEmailFormik;

const FormFields = styled.div`
  > div {
    margin-bottom: 30px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  padding-bottom: 25px;

  ${({ theme }) => theme.media.max.mobile`
    button {
      padding: 15px;
      &:first-of-type {
        margin-right: 5px;
      }
    }
`}
`;
