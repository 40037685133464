/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 *
 *
 *		SimpleDatePicker
 *
 *  */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ReactSelect from 'components/lib/ReactSelect';

import {
  convertSelectorValuesToDate,
  getSelectorValuesFromDate,
} from 'utils/helperFunctions/react-select';

import {
  dayOptions,
  monthOptions,
  yearOptions,
  months,
  getDaysForMonthSelectOptions,
  days31SelectArray,
  getMonthFromZeroNum,
} from './options';

const uppercase = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

/**
 * Returns the select value for a given month based on a zero-index base, so subtracts 1 from the month label prop
 *
 * @param {number} month - 1-based root i.e. readable as 1-12 versus 0-11 in the db
 */
const formatZeroBasedMonthSelectValue = month => ({
  value: month, // this is already zero-based
  label: getMonthFromZeroNum(month), // returns 3-char string from 0-based month index
});

const datesOptsByKey = {
  month: monthOptions,
  day: dayOptions,
  year: yearOptions,
};

// console.log({ datesOptsByKey });

// const customDayStyles = {
// option: (provided, state) => ({
//   ...provided,
//   borderBottom: '1px dotted pink',
//   color: state.isSelected ? 'red' : 'blue',
//   padding: 20,
// }),
// menu: (provided, state) => ({
//   ...provided,
//   border: '1px solid orange',
// }),
// control: () => ({
//   // none of react-select's styles are passed to <Control />
//   minWidth: 80,
// }),
// valueContainer: provided => ({
//   ...provided,
//   minWidth: 80,
// }),
// };

// TODO: International orders could vary in future versions of ripspot e.g. America ==> mm/dd/yyy, Euro ==> yyyy/mm/dd
// SET ORDER HERE!!!
const dateTypes = ['month', 'day', 'year'];

const selectifyMonth = month => ({
  label: getMonthFromZeroNum(month),
  value: month,
});
const selectifyDay = day => ({ label: day + 1, value: day });
const selectifyYear = year => ({ label: year, value: year });

const SimpleDatePicker = ({
  updateDate,
  initMonth,
  initDay,
  initYear,
  onClearValue,
}) => {
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentDay, setCurrentDay] = useState('');
  const [currentYear, setCurrentYear] = useState('');

  const [dayOpts, setDayOpts] = useState(days31SelectArray);

  useEffect(() => {
    if (typeof initMonth === 'number') {
      //
      // Month
      //

      const firstInitMonthSelectValue = formatZeroBasedMonthSelectValue(
        initMonth,
      );
      // // eslint-disable-next-line no-console
      // console.log({ firstInitMonthSelectValue });
      // check if this is the value or labekl we save, I think label...so subtract to create the select "value"
      setCurrentMonth(firstInitMonthSelectValue);
    }
    if (typeof initDay === 'number') {
      //
      // Day
      //
      setCurrentDay({
        value: initDay - 1, // values are zero based in db
        label: initDay,
      });
    }
    if (initYear) {
      //
      // Year
      //
      setCurrentYear({
        value: initYear,
        label: initYear,
      });
    }
  }, [initMonth, initDay, initYear]);

  useEffect(() => {
    if (currentMonth && currentMonth.label) {
      const { label } = currentMonth;
      const daysOptionsToUse = getDaysForMonthSelectOptions(label);
      setDayOpts(daysOptionsToUse);
    }
  }, [currentMonth]);

  const currentDatesByKey = {
    month: currentMonth,
    day: currentDay,
    year: currentYear,
  };

  const currentDatesByKeyFns = {
    month: setCurrentMonth,
    day: setCurrentDay,
    year: setCurrentYear,
  };

  const handleChange = type => value => {
    if (!value) {
      // need to clear this value
      onClearValue(type);
      // this finds correct fn and updates local state
      currentDatesByKeyFns[type]('');
      return;
    }

    switch (type) {
      case 'month':
        setCurrentMonth(value); // in reactSelect format
        value && 'value' in value && updateDate(type, value.value);
        break;
      case 'day':
        setCurrentDay(value); // in reactSelect format
        value && 'value' in value && updateDate(type, value.value + 1); // add one to day to get real date val i.e. label
        break;
      case 'year':
        setCurrentYear(value); // in reactSelect format
        value && value.value && updateDate(type, value.value);
        break;
      default:
    }
  };

  return (
    <Container>
      <SelectorsWrapper>
        {dateTypes &&
          dateTypes.length > 0 &&
          dateTypes.map(dateType => {
            const isDay = dateType === 'day';
            const placeholder = uppercase(dateType);
            const value = currentDatesByKey[dateType];

            let options; // these are re-computed when month changes via day arrays' includes lookup
            if (!isDay) {
              options = datesOptsByKey[dateType];
            } else {
              options = dayOpts;
            }
            const id = `${dateType}-selector`;

            return (
              <Label htmlFor="day-selector">
                <ReactSelect
                  // menuIsOpen
                  isSearchable
                  isClearable
                  portal
                  menuPortalTarget={document.body}
                  id={id}
                  options={options}
                  value={value}
                  handleChange={handleChange(dateType)}
                  placeholder={placeholder}
                  // styles={customDayStyles}
                />
              </Label>
            );
          })}
      </SelectorsWrapper>
    </Container>
  );
};

SimpleDatePicker.propTypes = {
  updateDate: PropTypes.func,
  onClearValue: PropTypes.func,
  initDay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // '' equates to falsey
  initMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SimpleDatePicker;

const Container = styled.div`
  #day-selector {
    width: 100px;
    ${({ theme }) => theme.media.max.mobile`
      width: 100%;
      width: 120px;
      margin-bottom: 15px;
    `}
  }
  #month-selector {
    width: 120px;
    ${({ theme }) => theme.media.max.mobile`
      width: 100%;
      margin-bottom: 15px;
    `}
  }
  #year-selector {
    width: 120px;
    ${({ theme }) => theme.media.max.mobile`
      width: 100%;
      margin-bottom: 15px;
    `}
  }
`;

const SelectorsWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.media.max.mobile`
    display: flex;
    flex-direction: column;
  `}
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
`;

// const Text = styled.span`
//   margin-right: 10px;
// `;
