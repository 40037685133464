/**
 *
 * Change Password Page
 *
 *
 */

import React, { useEffect, memo } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import SEO from 'components/seo';
import ChangePassword from 'containers/Auth/ChangeEmailPass/ChangePassword';
import theme from 'utils/theme';

const ChangePasswordPage = ({ user, navigate }) => {
  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <SEO title="Change Password Page" />
        <ChangePassword />
      </Container>
    </ThemeProvider>
  );
};

ChangePasswordPage.propTypes = {
  user: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user, ripspots }) => ({ user, ripspots });
const withConnect = connect(mapStateToProps);
export default compose(
  memo,
  withConnect,
)(ChangePasswordPage);

const Container = styled.div`
  display: flex;
`;
