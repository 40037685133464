/**
 *
 * ModalHeader
 *
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CloseIcon from 'components/icons/Close';
import ButtonIcon from 'components/Elements/ButtonIcon';

// export const MODAL_HEADER_HEIGHT = 55;

export const getSize = size => {
  switch (size) {
    case 'sm':
      return 25;
    case 'md':
      return 25;
    case 'lg':
      return 40;
    default:
  }
};

/**
 * ModalHeader
 *
 * Header component for modal
 *
 * @param {IModalHeaderProps} { title }
 * @param {IModalHeaderProps} { onRequestClose }
 * @param {IModalHeaderProps} { renderTitle }
 * @param {IModalHeaderProps} { closeIconSize }
 *
 * @returns {node} ModalHeader
 */
export const ModalHeader = ({
  title,
  onRequestClose,
  renderTitle,
  closeIconSize,
}) => {
  const size = getSize(closeIconSize);
  return (
    <Header>
      {renderTitle ? renderTitle() : title && <Title>{title}</Title>}
      <StyledButtonIcon
        width="100%"
        height="100%"
        // width={size + 10}
        // height={size + 10}
        fill="rgba(100,100,100,0.55)"
        hoverFill="rgba(100,100,100,0.25)"
        Icon={CloseIcon}
        onClick={onRequestClose}
      />
    </Header>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  closeIconSize: PropTypes.string,
  renderTitle: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
};

export default memo(ModalHeader);

export const StyledButtonIcon = styled(ButtonIcon)`
  position: absolute;
  top: 5px;
  right: 6px;
  width: 50px;
  height: 50px;
  z-index: 1;
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.LightGrey};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  padding-left: 15px;
  padding-top: 45px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  z-index: 1;
  font-family: 'Libre Baskerville', serif;
`;

const Title = styled.h2`
  font-size: 1.8rem;
`;
