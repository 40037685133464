/* eslint-disable prefer-destructuring */
/**
 *
 * TopMapNav
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MapStateIndicator from './MapStateIndicator';
import MapStyleToggle from './MapStyleToggle';

const TopMapNav = ({ isSatellite, handleToggleMapStyle, editInProgress }) => {
  return (
    <Container>
      <MapStateIndicator editInProgress={editInProgress} />
      <MapStyleToggle
        isSatellite={isSatellite}
        handleToggleMapStyle={handleToggleMapStyle}
      />
    </Container>
  );
};

TopMapNav.propTypes = {
  isSatellite: PropTypes.bool.isRequired,
  editInProgress: PropTypes.bool.isRequired,
  handleToggleMapStyle: PropTypes.func.isRequired,
};

export default TopMapNav;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
`;
